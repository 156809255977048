import { faArchive, faFilter, faPlay, faPlus, faSpinner, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from 'reactstrap';
import { useDisplayListQuery, useGetTempMutation, useListMutation } from "../../../api/tracker/parameter";
import ModalParameter from "./ModalParameter";
import List from "./List";
import { Form } from "react-bootstrap";
import { useListByStatusQuery } from "../../../api/tracker/project";
import FormToJson from "../../../util/FormToJson";
import DesignerProjectModal from "./DesignerProjectModal";

function Index({ id_owner }) {

  useEffect(() => {
    list({ ID_Owner: id_owner });
  }, [id_owner]);

  //TAB START
  const [tab1Show, setTab1Show] = useState(true);
  const [tab2Show, setTab2Show] = useState(false);

  function tabHandleClick(e) {
    if (e.currentTarget.id === "tab_1") {
      setTab1Show(true);
      setTab2Show(false);
    }
    if (e.currentTarget.id === "tab_2") {
      setTab1Show(false);
      setTab2Show(true);
    }
  }
  //TAB END


  //МОДАЛЬНЫЕ ОКНА
  const [modalParameterShow, setModalParameterShow] = useState(false);
  const [modalDesignerShow, setModalDesignerShow] = useState(false);

  //ПЕРЕМЕННЫЕ
  const [modelParam, setModelParam] = useState({}); //Модель параметра для передачи в модальное окно ModalParameter
  const [logAddTask, setLogAddTask] = useState(null);
  const [loadingAddTask, setLoadingAddTask] = useState(null);



  //******************START ДОБАВЛЕНИЕ ПАРАМЕТРА**********************
  const [getTemp, { data: getTempData, status: getTempStatus, isLoading: getTempIsLoading }] = useGetTempMutation();

  //Получаем временную запись для добавления параметра
  function modalAddHandler() {
    setLoadingAddTask(true);
    getTemp({ ID_Owner: id_owner });
  }


  //Открываем модальное окно добавления нового параметра
  useEffect(() => {
    if (getTempStatus === 'fulfilled') {
      if (getTempData.Status == 1) {
        setModelParam(getTempData.Data);
        setModalParameterShow(true);
      }
      else {
        setLogAddTask(getTempData.Error);
      }
      setLoadingAddTask(false);
    }
    if (getTempStatus === 'rejected') {
      setLoadingAddTask(false);
      setLogAddTask('Неизвестная ошибка!');
    }
  }, [getTempStatus]);
  //******************START ДОБАВЛЕНИЕ ПАРАМЕТРА**********************
  /***************************START СПИСОК ПАРАМЕТРОВ**************************/

  const [list, { data: listData, status: listStatus, isLoading: listIsLoading }] = useListMutation();
  const [listState, listDispatch] = useReducer(listReducer, { data: [] });

  function listReducer(state, action) {

    switch (action.type) {
      case 'load': {        
        return { data: action.data }
      }
      case 'filter': {       
        //!!!! НЕ РЕАЛИЗОВАТЬ ИЗ-ЗА ТОГО ЧТО НЕ ВЫВЕСТИ ID_Progect и ID_Display в список !!!
        return null
      }
    }
  }
  useEffect(() => {
    if (listStatus === 'fulfilled') {
      listDispatch({ type: 'load', data: listData.Data });
    }
  }, [listStatus]);

  /****************************END СПИСОК ПАРАМЕТРОВ***************************/
  /*********************************START ФИЛЬТР***************************/
  const [filterShow, setFilterShow] = useState(false);
  const formFilterRef = useRef();

  const listProject = useListByStatusQuery({ ID_Status: 1 });
  const listDisplay = useDisplayListQuery({ ID_Owner: 1 });

  //Событие изменения фильтра
  function changeFilterHandler() {
    var jsonForm = FormToJson(formFilterRef.current);

    var id_project = jsonForm.id_project;
    var id_display = jsonForm.id_display;

    var form = { ID_Owner: id_owner };

    if (id_project !== '0') {
      form['ID_Project'] = id_project;
    }
    if (id_display !== '0') {
      form['ID_Display'] = id_display;
    }

    list(form);
  }
  /**********************************END ФИЛЬТР****************************/


  return (
    <>
      <ModalParameter show={modalParameterShow} onHide={() => setModalParameterShow(false)} model={modelParam} listUpdate={list} id_owner={id_owner} />
      <DesignerProjectModal show={modalDesignerShow} onHide={() => setModalDesignerShow(false)} id_owner={id_owner} params_all={listState.data} />

      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header-tab card-header-tab-animation card-header">
              <div className="card-header-title">
                <Button onClick={() => modalAddHandler()} color="secondary"><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавить параметр</Button>
                {logAddTask !== null && <div className="form_error ml-30">{logAddTask}</div>}
                {loadingAddTask === true && <FontAwesomeIcon className="ml-10" icon={faSpinner} size="2x" />}
                <Button onClick={() => setModalDesignerShow(true)} color="secondary" className="ml-15"><i className="mr-5"><FontAwesomeIcon icon={faWandMagicSparkles} /></i>Дизайнер</Button>
              </div>
              <ul className="nav">
                <li className="nav-item">
                  <a
                    id="tab_1"
                    onClick={tabHandleClick}
                    data-toggle="tab"
                    className={tab1Show ? "nav-link active show" : "nav-link show"}
                  >
                    <FontAwesomeIcon icon={faPlay} /> Активные
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="tab_2"
                    onClick={tabHandleClick}
                    data-toggle="tab"
                    className={tab2Show ? "nav-link active show" : "nav-link show"}
                  >
                    <FontAwesomeIcon icon={faArchive} /> Архив
                  </a>
                </li>
                <li className="nav-item">
                  <Button onClick={() => setFilterShow(!filterShow)} color="link" className={filterShow === true ? "ml-10 text-danger" : "ml-10"} ><i className="mr-5"><FontAwesomeIcon icon={faFilter} /></i></Button>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div className={filterShow === true ? "filter_block mb-30" : "d-none"}>
                  <form ref={formFilterRef}>
                    <div className="row">
                      <div className="col-md-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Проект</Form.Label>
                          <Form.Select name="id_project" onChange={() => changeFilterHandler()}>
                            <option value={0}>Все проекты</option>
                            {listProject.status === 'fulfilled' &&
                              (
                                listProject.data.Data.map((model) => (
                                  <option key={model.ID} value={model.ID}>
                                    {model.Name_Full}
                                  </option>
                                ))
                              )
                            }
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Область отображения</Form.Label>
                          <Form.Select name="id_display" onChange={() => changeFilterHandler()}>
                            <option value={0}>Все области</option>
                            {listDisplay.status === 'fulfilled' &&
                              (
                                listDisplay.data.Data.map((model) => (
                                  <option key={model.ID} value={model.ID}>
                                    {model.Name}
                                  </option>
                                ))
                              )
                            }
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </form >

                </div>
                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    {listStatus != 'fulfilled' ? <div className="loader_01"></div> : <List list={listState.data.filter(a => a.ID_Status == 1)} listUpdate={list} />}
                  </div>
                </div>
                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    {listStatus != 'fulfilled' ? <div className="loader_01"></div> : <List list={listState.data.filter(a => a.ID_Status == 0)} listUpdate={list} />}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
