import { faBan, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useExternalGetListQuery, useSelectMutation, useSelectedIdGetQuery } from '../../../api/main/company';
import React, { useEffect, useState } from 'react';
import ExternalCompanyModalRemove from './ExternalCompanyModalRemove';

function ExternalCompanyList() {

  //Модальные окна
  const [modalRemoveShow, setModalRemoveShow] = useState(false);
  const [modelRemove, setModelRemove] = useState([]);

  function modalRemoveShowEvent(model){
    setModalRemoveShow(true)
    setModelRemove(model)
   }

  //API
  const reqList = useExternalGetListQuery();
  const companySelected = useSelectedIdGetQuery();
  const [companySelect] = useSelectMutation();

  const [compnaySelectedId, setCompnaySelectedId] = useState(0);

  useEffect(() => {   
    if(companySelected.status === 'fulfilled'){             
        setCompnaySelectedId(companySelected.data.Data)
    }
  }, [companySelected.status]);

  function companyChange (model) {
    companySelect({id_company: model.ID})
  };



  return (
    <>  
      <ExternalCompanyModalRemove show={modalRemoveShow} onHide={() => setModalRemoveShow(false)} model={modelRemove}/>
   
      {reqList.isLoading === true ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} size="2x" />
        </div>
      ) : (
        <div>
          <ul className="list-group">
            {reqList.data.Data.map((model) => (
              <li className="list-group-item row_select" key={model.ID}>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3"></div>
                    <div className="widget-content-left">
                      <div className="widget-heading">
                       {compnaySelectedId === model.ID &&
                            <i className="mr-15 text-success"><FontAwesomeIcon icon={faCheck}/></i>
                        } 
                        {model.Name}
                        </div>
                    </div>
                    <div className="widget-content-right">
                      <div role="group" className="btn-group-sm btn-group">
                      {compnaySelectedId !== model.ID &&
                            <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={() => companyChange(model)}>
                              <i className="mr-5"><FontAwesomeIcon icon={faCheck} /></i>
                              Выбрать
                            </button>
                        }                     
                        <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => modalRemoveShowEvent(model)}>
                          <i className="mr-5">
                            <FontAwesomeIcon icon={faBan} />
                          </i>
                          Удалить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default ExternalCompanyList;
