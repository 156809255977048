//Преобразует форму в объект Json. Для дальнейшей отправки в API
export default function FormToJson(target) {
  const jsonForm = {};

  for (let i = 0; i < target.length; i++) {
    const nodeName = target[i].nodeName;
    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA' || nodeName === 'SELECT') {

      //Преоброзуем строковые логические в логические
      var val = target[i].value;
      if (val === 'true' || val === 'on') {
        val = true;
      }
      if (val === 'false' || val === 'off') {
        val = false;
      }
      if (val === '') {
        val = null;
      }


      jsonForm[target[i].name] = val;
    }
  }

  return jsonForm;
}
