import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useListQuery } from '../../../api/tracker/project';
import { faPenToSquare, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ModalEdit from './ModalEdit';
import ModalRemove from './ModalRemove';
import ListChildren from './ListChildren';

function ListParent() {
    //Модальные окна
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalRemoveShow, setModalRemoveShow] = useState(false);
    const [modelUpdate, setModelUpdate] = useState([]);

    function modalEditHandler(model) {
        setModalEditShow(true)
        setModelUpdate(model)
    }
    function modalRemoveHandler(model) {
        setModalRemoveShow(true)
        setModelUpdate(model)
    }


    //Список проектов
    const listApi = useListQuery();
    const [list, setList] = useState([]);

    useEffect(() => {
        if (listApi.status === 'fulfilled') {
            setList(listApi.data.Data.filter(a => a.ID_Status == 1))
        }
    }, [listApi.status]);

    return (
        <>
            <ModalEdit show={modalEditShow} onHide={() => setModalEditShow(false)} model={modelUpdate} />
            <ModalRemove show={modalRemoveShow} onHide={() => setModalRemoveShow(false)} model={modelUpdate} />

            <ul className="todo-list-wrapper1 list-group1 list-group-flush1" style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                {listApi.isLoading === true ? (
                    <div>
                        <FontAwesomeIcon icon={faSpinner} size="2x" />
                    </div>
                ) : (
                    list.filter(a => a.Level === 0).map((model, index) => (
                        <div key={model.ID}>
                            <li className={model.Level === 0 ? 'list-group-item row_select bg_gray mt-30' : 'list-group-item row_select'} key={model.ID} style={{ marginLeft: (5 * model.Level) + '%' }}>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-2">
                                            <div className="widget-heading">{model.Name}</div>
                                            <div>
                                                <i>Задачи: {model.Use_Tasks ? <i className='text-success'>Да</i> : <i className='text-danger'>Нет</i>}</i>
                                                <i className='ml-10'>Регулярные задачи: {model.Use_Rtasks ? <i className='text-success'>Да</i> : <i className='text-danger'>Нет</i>}</i>
                                                <i className='ml-10'>Доски: {model.Use_Boards ? <i className='text-success'>Да</i> : <i className='text-danger'>Нет</i>}</i>
                                                <i className='ml-10'>Регулярные доски: {model.Use_Rboards ? <i className='text-success'>Да</i> : <i className='text-danger'>Нет</i>}</i>
                                            </div>
                                        </div>
                                        <div className="widget-content-center flex2 ">

                                        </div>
                                        <div className="widget-content-right">
                                            <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => modalEditHandler(model)} >
                                                <i><FontAwesomeIcon icon={faPenToSquare} /></i>
                                            </button>
                                            <button className="btn-square btn-hover-shine btn btn-secondary ml-10" onClick={() => modalRemoveHandler(model)}>
                                                <i><FontAwesomeIcon icon={faTrash} /></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <ListChildren id_parent={model.ID} list={list} />
                        </div>
                    ))
                )}
            </ul >
        </>
    )
}

export default ListParent;
