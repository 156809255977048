import '../../assets/base.css';
import '../Lk/Index.css';
import { Outlet } from 'react-router';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import ResizeDetector from 'react-resize-detector';

import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';
import CheckData from '../../util/CheckData';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      width: undefined,
    };
  }

  onResize = (width) => this.setState({ width });

  render() {

    const { width } = this.state;

    let { colorScheme, enableFixedHeader, enableFixedSidebar, enableFixedFooter, enableClosedSidebar, closedSmallerSidebar, enableMobileMenu, enablePageTabsAlt } = this.props;

    return (
      <div>
        <Fragment>
          <div className={cx('app-container app-theme-' + colorScheme, { 'fixed-header': enableFixedHeader }, { 'fixed-sidebar': enableFixedSidebar || width < 1250 }, { 'fixed-footer': enableFixedFooter }, { 'closed-sidebar': enableClosedSidebar || width < 1250 }, { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 }, { 'sidebar-mobile-open': enableMobileMenu })}>
            <Fragment>
              <AppHeader />
              <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                  <div className="app-main__inner">
                    <div>
                      <CheckData />
                      <Outlet>

                      </Outlet>
                    </div>
                  </div>
                  {/*<AppFooter />*/}
                </div>
              </div>
            </Fragment>
            <ResizeDetector handleWidth onResize={this.onResize} />
          </div>
        </Fragment>
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default connect(mapStateToProp)(Index);
