import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useGetTempMutation } from "../../../../api/tracker/taskR";
import ModalTaskR from "../../TaskR/Setting/ModalTaskR";


function ModalAddInfo(props) {
    const { show, onHide } = props;

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setLog(null);
            setLoading(false);
        }
        //Действия при закрытии модального окна
        if (show == false) {

        }
    }, [show]);
    //Модальные окна
    const [modalTaskShow, setModalTaskShow] = useState(false);

    const [getTemp, { data: getTempData, status: getTempStatus }] = useGetTempMutation();
    const [addModel, setAddModel] = useState({});



    //Выбор типа задачи
    function taskChangeHandler(id_type) {
        setLoading(true);
        getTemp({ ID_Type: id_type });        
    };

    //Обработка получения временной задачи
    useEffect(() => {
        if (getTempStatus === 'fulfilled') {
            if (getTempData.Status == 1) {
                setAddModel(getTempData.Data);
                setModalTaskShow(true);
                onHide();
            }
            else {
                setLog(getTempData.Error);
            }
            setLoading(false);
        }
        if (getTempStatus === 'rejected') {
            setLoading(false);
            setLog('Неизвестная ошибка!');
        }
    }, [getTempStatus]);





    return (
        <>
            <ModalTaskR show={modalTaskShow} onHide={() => setModalTaskShow(false)} edit={false} model={addModel} />

            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faLightbulb} /></i> Выберите тип задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid-menu grid-menu-3col mb-30">
                        <div className="no-gutters row">
                            <div className="col-sm-6">
                                <div className="p-1">
                                    <button onClick={() => taskChangeHandler(1)} className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                                        <i className="text-primary opacity-7 btn-icon-wrapper mb-2"> <FontAwesomeIcon icon={faUser} /></i >  Выполнят один
                                    </button>
                                </div>
                                <div>
                                    Задача считается выполненной если ее выполнит хотя бы одним из ответственных.
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="p-1">
                                    <button onClick={() => taskChangeHandler(2)} className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                                        <i className="text-primary opacity-7 btn-icon-wrapper mb-2"> <FontAwesomeIcon icon={faUsers} /></i >  Выполняет каждый
                                    </button>
                                </div>
                                <div>
                                    Задача считается выполненной если каждый ответственный выполнит задачу.
                                </div>
                            </div>
                        </div>

                        {loading === true && (<span className="loader_01"></span>)}

                        {log != null && <div className="form_error text-center">{log}</div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ModalAddInfo;
