import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function UserBlocked() {

    return (
        <>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="main-card mb-3 card">
                        <div className="card-header-tab card-header-tab-animation card-header">
                            <div className="card-header-title text-danger">
                                <FontAwesomeIcon icon={faBan} className="fs-2 mr-10" /> Пользователь заблокирован
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mb-15">
                                Для разблокировки учетной записи необходимо обратиться в техническую поддержку!
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </>
    )
}
export default UserBlocked;