import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ModalUser from "./ModalUser";
import List from "./List";
import ModalNoProject from "../ModalNoProject";

function Index({ project, id_task, only_observer }) {
    //only_observer - Только наблюдатель

    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalNoProjectShow, setModalNoProjectShow] = useState(false);

    function addHandler() {
        if (project === null || project === undefined) {
            setModalNoProjectShow(true);
        }
        else {
            setModalAddShow(true);
        }
    }

    return (
        <>
            <ModalNoProject show={modalNoProjectShow} onHide={() => setModalNoProjectShow(false)} />
            {project !== null &&
                <ModalUser show={modalAddShow} onHide={() => setModalAddShow(false)} id_task={id_task} id_project={project !== null ? project.value : null} edit={false} only_observer={only_observer} />
            }
            <div className="text-end mt-15">
                <div>
                    <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => addHandler()}><FontAwesomeIcon icon={faUserPen} /> Изменить...</button>
                </div>
            </div>
            {project !== null &&
                <List id_task={id_task} />
            }
        </>
    );
}
export default Index;
