import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import HoursInput from "../../../../components/ui/HoursInput";
import DaysInput from "../../../../components/ui/DaysInput";
import WeekInput from "../../../../components/ui/WeekInput";
import MonthInput from "../../../../components/ui/MonthInput";
import { Form } from "react-bootstrap";
import { useTimetableAddMutation, useTimetableUpdateMutation } from "../../../../api/tracker/taskR";


function ModalTaskRlTimetable(props) {
    const { show, onHide, id_taskR, model_timetable, listTimetable } = props;

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);
    const [idTaskR, setIdTaskR] = useState(null);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setLog(null);
            setLoading(false);
            setIdTaskR(id_taskR);

            //Если редактирование, то заполняем переменные
            if (model_timetable !== null) {
                setHour(model_timetable.Hour);
                setDay(model_timetable.Day);
                setDayWeek(model_timetable.Week_Day);
                setMonth(model_timetable.Month);
                setDayComplete(model_timetable.Day_Complite);
                setType(model_timetable.ID_Type);
                setComment(model_timetable.Comment);
                setIdTimetable(model_timetable.ID);
            }
            else {
                setHour(null);
                setDay(null);
                setDayWeek(null);
                setMonth(null);
                setDayComplete(null);
                setType(null);
                setComment(null);
                setIdTimetable(null);
            }
        }
        //Действия при закрытии модального окна
        if (show == false) {
            //setIdTimetable(null);
        }
    }, [show]);

    //1-Ежедневно; 2-Еженедельно; 3-Ежемесячно; 4-Ежегодно
    const [type, setType] = useState(0);
    function changeTypeHandler(id_type) {
        setType(id_type);
        if (id_type != type) {
            setLog(null);
        }
    }

    const [idTimetable, setIdTimetable] = useState(-1);
    const [hour, setHour] = useState(-1);
    const [day, setDay] = useState(-1);
    const [dayWeek, setDayWeek] = useState(-1);
    const [month, setMonth] = useState(-1);
    const [dayComplete, setDayComplete] = useState(0);
    const [comment, setComment] = useState(null);

    //События изменения полей
    function hourHandler(e) {
        console.log(e)
        //console.log(e.target.s)
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var minute = optionElement.getAttribute('minute');

        console.log(minute)

        setHour(e.target.value)
    }
    function dayHandler(e) {
        setDay(e.target.value)
    }
    function dayWeekHandler(e) {
        setDayWeek(e.target.value)
    }
    function monthHandler(e) {
        setMonth(e.target.value)
    }
    function dayCompleteHandler(e) {
        setDayComplete(e.target.value)
    }
    function commentHandler(e) {
        setComment(e.target.value)
    }

    //Добавление расписания
    const [add, { data: addData, status: addStatus, isLoading: addIsLoading }] = useTimetableAddMutation();
    const [update, { data: updateData, status: updateStatus, isLoading: updateIsLoading }] = useTimetableUpdateMutation();

    function addHandler() {
        setLoading(true);
        setLog(null);

        if (type === null) {
            setLog('Выберите тип расписания');
            setLoading(false);
        }
        else if (type === 1 & hour === null) {
            setLog('Выберите время');
            setLoading(false);
        }
        else if (type === 2 & dayWeek === null) {
            setLog('Выберите день недели');
            setLoading(false);
        }
        else if (type === 2 & hour === null) {
            setLog('Выберите время');
            setLoading(false);
        }
        else if (type === 3 & day === null) {
            setLog('Выберите число');
            setLoading(false);
        }
        else if (type === 3 & hour === null) {
            setLog('Выберите время');
            setLoading(false);
        }
        else if (type === 4 & month === null) {
            setLog('Выберите месяц');
            setLoading(false);
        }
        else if (type === 4 & day === null) {
            setLog('Выберите число');
            setLoading(false);
        }
        else if (type === 4 & hour === null) {
            setLog('Выберите время');
            setLoading(false);
        }
        else {
            if (model_timetable === null) {
                add({ ID_TaskR: idTaskR, ID_Type: type, Month: month, Week_Day: dayWeek, Day: day, Day_Complete: dayComplete, Hour: hour, Hour_Complete: null, Comment: comment, Archive: false })
            }
            else {
                update({ ID: model_timetable.ID, Month: month, Week_Day: dayWeek, Day: day, Day_Complete: dayComplete, Hour: hour, Hour_Complete: null, Comment: comment, Archive: false })
            }
        }
    }

    useEffect(() => {
        if (addStatus === 'fulfilled') {
            if (addData.Status == 1) {
                setLoading(false);
                listTimetable({ ID_TaskR: id_taskR })
                onHide();
            }
            else {
                setLog(addData.Error);
                setLoading(false);             
            }
        }
        if (addStatus === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [addStatus]);

    useEffect(() => {
        if (updateStatus === 'fulfilled') {
            if (updateData.Status == 1) {
                setLoading(false);
                listTimetable({ ID_TaskR: id_taskR })
                onHide();
            }
            else {
                setLog(updateData.Error);
                setLoading(false);
            }
        }
        if (updateStatus === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [updateStatus]);

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faCalendar} /></i> {model_timetable === null ? "Добавления расписания" : "Редактирование расписания"}
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-20">
                        <div className="col-md-12 text-center">
                            <button disabled={model_timetable === null ? false : true} onClick={() => changeTypeHandler(1)} className={type === 1 ? "mr-10 btn-square btn-transition btn btn-outline-secondary btn-lg active" : "mr-10 btn-square btn-transition btn btn-outline-secondary btn-lg"}>Ежедневно</button>
                            <button disabled={model_timetable === null ? false : true} onClick={() => changeTypeHandler(2)} className={type === 2 ? "mr-10 btn-square btn-transition btn btn-outline-secondary btn-lg active" : "mr-10 btn-square btn-transition btn btn-outline-secondary btn-lg"}>Еженедельно</button>
                            <button disabled={model_timetable === null ? false : true} onClick={() => changeTypeHandler(3)} className={type === 3 ? "mr-10 btn-square btn-transition btn btn-outline-secondary btn-lg active" : "mr-10 btn-square btn-transition btn btn-outline-secondary btn-lg"}>Ежемесячно</button>
                            <button disabled={model_timetable === null ? false : true} onClick={() => changeTypeHandler(4)} className={type === 4 ? "btn-square btn-transition btn btn-outline-secondary btn-lg active" : "btn-square btn-transition btn btn-outline-secondary btn-lg"}>Ежегодно</button>
                        </div>
                    </div>
                    <div className="mt-25">
                        {type === 0 && (
                            <div className="row">
                                <div className="col-md-12 text-center mb-15" style={{ fontSize: "20px" }}>
                                    Выберите тип расписания
                                </div>
                            </div>
                        )}
                        {type === 1 && (
                            <div className="row">
                                <div className="col-md-3">
                                    <HoursInput label={"Время:"} defaultValue={hour} change={(e) => hourHandler(e)} />
                                </div>
                            </div>
                        )}
                        {type === 2 && (
                            <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <WeekInput label={"День недели:"} defaultValue={dayWeek} change={(e) => dayWeekHandler(e)} />
                                    </div>
                                    <div className="col-md-3">
                                        <HoursInput label={"Время:"} defaultValue={hour} change={(e) => hourHandler(e)} />
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Кол-во дней для решения задачи*:</Form.Label>
                                            <Form.Control key={idTimetable} type="number" style={{ width: "70px" }} defaultValue={dayComplete} onChange={(e) => dayCompleteHandler(e)} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </>
                        )}
                        {type === 3 && (
                            <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <DaysInput label={"Число:"} defaultValue={day} change={(e) => dayHandler(e)} />
                                    </div>
                                    <div className="col-md-3">
                                        <HoursInput label={"Время:"} defaultValue={hour} change={(e) => hourHandler(e)} />
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Кол-во дней для решения задачи*:</Form.Label>
                                            <Form.Control key={idTimetable} type="number" style={{ width: "70px" }} defaultValue={dayComplete} onChange={(e) => dayCompleteHandler(e)} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </>
                        )}
                        {type === 4 && (
                            <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <MonthInput label={"Месяц:"} defaultValue={month} change={(e) => monthHandler(e)} />
                                    </div>
                                    <div className="col-md-3">
                                        <DaysInput label={"Число:"} defaultValue={day} change={(e) => dayHandler(e)} />
                                    </div>
                                    <div className="col-md-3">
                                        <HoursInput label={"Время:"} defaultValue={hour} change={(e) => hourHandler(e)} />
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-6">
                                        <Form.Group>
                                            <Form.Label>Кол-во дней для решения задачи*:</Form.Label>
                                            <Form.Control key={idTimetable} type="number" style={{ width: "70px" }} defaultValue={dayComplete} onChange={(e) => dayCompleteHandler(e)} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </>
                        )}
                        {type > 0 && (                            
                            <Form.Group className="mt-10">
                                <Form.Label>Комментарий:</Form.Label>
                                <Form.Control key={idTimetable} as="textarea" rows={3} defaultValue={comment} onChange={(e) => commentHandler(e)} />
                            </Form.Group>                          
                        )}
                    </div>
                    {type > 1 && (
                        <div className="mt-15">* Кол-во дней для решения задачи - по истечению указанного кол-ва дней, если не завершить задачу, то она поменяет статус на «просрочена». Такие задачи будут выделены красным цветом. Ежедневно будут приходить уведомления о просроченной задаче.</div>
                    )}
                    {log != null && (
                        <div className="form_error text-center">
                            {log}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {
                        model_timetable === null ? (
                            <button
                                className="btn btn-secondary"
                                onClick={() => addHandler()}
                                disabled={loading}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-5" />
                                {loading === true ? ('Загрузка...') : 'Добавить расписание'}

                            </button>
                        )
                            :
                            (
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => addHandler()}
                                    disabled={loading}
                                >
                                    <FontAwesomeIcon icon={faSave} className="mr-5" />
                                    {loading === true ? ('Загрузка...') : 'Сохранить изменения'}

                                </button>
                            )
                    }

                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalTaskRlTimetable;
