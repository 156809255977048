import { faCheck, faFile, faSpinner, faTrash, faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { useFileUploadMutation as useTaskFileUploadMutation } from "../../api/tracker/task";
import { useFileUploadMutation as useRtaskFileUploadMutation } from "../../api/tracker/taskR";

function FileUploadModal(props) {
    const { show, onHide, type, data, listApi } = props;
    //type: 1-задачи; 2-регулярные задачи

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setFile(null);
            fileDispatch({ type: 'clear' });
            setError(null);            
        }
    }, [show]);

    const [file, setFile] = useState(null);

    const [fileState, fileDispatch] = useReducer(fileReducer, { data: [] });
    function fileReducer(state, action) {
        switch (action.type) {
            //Загрузит данные
            case 'load': {
                return { data: action.data }
            }
            //Изменит статус всех файлов на "Загрузка"
            case 'changeStatusLoadAll': {
                const filesNew = state.data.map((item, i) => {
                    return { ...item, Status: 1 };
                });

                return { data: filesNew }
            }
            //Изменит статус файла
            case 'changeStatus': {
                const filesNew = state.data.map((item, i) => {
                    if (i === action.index) {
                        return { ...item, Status: action.status, Error: action.error };
                    }
                    else {
                        return item;
                    }
                });

                return { data: filesNew }
            }
            //Изменит имя файла
            case 'changeName': {
                const filesNew = state.data.map((item, i) => {
                    if (item.File.name === action.name) {
                        return { ...item, Name: action.new_name };
                    }
                    else {
                        return item;
                    }
                });

                return { data: filesNew }
            }
            //Удалит файл
            case 'remove': {
                return { data: state.data.filter(a => a.File.name !== action.name) }
            }
            //Очистит данные
            case 'clear': {
                return { data: [] }
            }
        }
    }



    //СОБЫТИЕ ВЫБОРА ФАЙЛОВ
    useEffect(() => {
        if (file !== null) {
            var fileMass = []
            for (let i = 0; i < file.length; i++) {
                //Название файла
                const ext = file[i].name.split('.').pop();
                const name = file[i].name.replace('.' + ext, '');

                //Размер файла
                const size_mb = file[i].size / 1048576;
                const size_kb = file[i].size / 1024;

                var size = size_kb.toFixed(2) + ' КБ';
                if (size_kb > 1024) {
                    size = size_mb.toFixed(2) + ' МБ';
                }

                var error = null;
                var status = 0;
                if (file[i].size > 29360128) {
                    error = 'Максимальный размер файла 28мб';
                    status = 3;
                }

                //Добавление файла в массив
                //Status: 0-Проект; 1-Загрузка; 2-Успешно загруже; 3-Ошибка размера; 4-Ошибка загрузки
                fileMass.push({ File: file[i], Name: name, Size: size, Status: status, Error: error });
            }
            fileDispatch({ type: 'load', data: fileMass });
        }
    }, [file]);


    /*****************************START ОТПРАВКА ФАЙЛОВ НА СЕРВЕР******************************/
    const [uploadTask, { }] = useTaskFileUploadMutation();
    const [uploadRTask, { }] = useRtaskFileUploadMutation();

    function uploadHandler() {
        setError(null);
        const count_error = fileState.data.filter(a => a.Status === 3).length;

        if (count_error > 0) {
            setError('Удалите из списка файлы с ошибками');
        }
        else {         
            setLoading(true);
            fileDispatch({ type: 'changeStatusLoadAll' })

            //Status: 0-Проект; 1-Загрузка; 2-Успешно загружен; 3-Ошибка загрузки;
            for (let i = 0; i < fileState.data.length; i++) {
                //Если привышен размер 28мб
                if (fileState.data[i].File.size > 29360128) {
                    fileDispatch({ type: 'changeStatus', status: 3, index: i })
                }
                else {
                    //Отправляем файл
                    if (type === 1) {
                        uploadTask({ File: fileState.data[i].File, ID_Task: data, Name: fileState.data[i].Name })
                            .then((e) => {
                                if (e.error !== undefined) {
                                    fileDispatch({ type: 'changeStatus', status: 4, error: 'Ошибка загрузки', index: i });
                                }
                                if (e.data !== undefined) {
                                    if (e.data.Status === 0) {
                                        fileDispatch({ type: 'changeStatus', status: 4, error: e.data.Error, index: i });
                                    }
                                    else {
                                        fileDispatch({ type: 'changeStatus', status: 2, index: i });
                                    }
                                }
                            }
                            )
                            .catch((e) => (
                                fileDispatch({ type: 'changeStatus', status: 4, error: 'Ошибка загрузки', index: i })
                        ))
                    }
                    else if (type === 2) {
                        uploadRTask({ File: fileState.data[i].File, ID_TaskR: data, Name: fileState.data[i].Name })
                            .then((e) => {
                                if (e.error !== undefined) {
                                    fileDispatch({ type: 'changeStatus', status: 4, error: 'Ошибка загрузки', index: i });
                                }
                                if (e.data !== undefined) {
                                    if (e.data.Status === 0) {
                                        fileDispatch({ type: 'changeStatus', status: 4, error: e.data.Error, index: i });
                                    }
                                    else {
                                        fileDispatch({ type: 'changeStatus', status: 2, index: i });
                                    }
                                }
                            }
                            )
                            .catch((e) => {
                                fileDispatch({ type: 'changeStatus', status: 4, error: 'Ошибка загрузки', index: i })
                            })
                    }
                    else {
                        fileDispatch({ type: 'changeStatus', status: 4, error: 'Ошибка загрузки', index: i })
                    }
                }
            }           
        }
    }

    useEffect(() => {
        const all = fileState.data.length;
        const all_finish = fileState.data.filter(a => a.Status === 2).length;       

        //Все файлы загружены
        if (all === all_finish & all > 0) {
            if (type === 1) {
                listApi({ ID_Task: data });
            }
            if (type === 2) {
                listApi({ ID_TaskR: data });
            }

            onHide();
            setLoading(false);  
        }



    }, [fileState.data]);
    /*****************************END ОТПРАВКА ФАЙЛОВ НА СЕРВЕР******************************/

    return (
        <Modal show={show} onHide={onHide} size="md">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <>
                        <FontAwesomeIcon icon={faUpload} /> Загрузка файлов
                    </>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <FileUploader handleChange={(file) => setFile(file)} name="file" multiple={true} label={'Выберите файлы'} />


                {fileState.data.length > 0 && (
                    <ul className="todo-list-wrapper list-group list-group-flush mt-10">
                        {fileState.data.map((model, index) => (
                            <li className="list-group-item row_select pl-5 pr-5 pt-7 pb-7" key={model.File.name}>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-10">
                                            <i><FontAwesomeIcon icon={faFile} fontSize={25} /></i>
                                        </div>
                                        <div className="widget-content-left" style={{ width: "100%" }}>
                                            <div className="widget-heading">
                                                <Form.Group>
                                                    <Form.Control name="Name" type="text" disabled={loading} defaultValue={model.Name} onChange={(e) => fileDispatch({ type: 'changeName', name: model.File.name, new_name: e.target.value })} />
                                                </Form.Group>
                                            </div>
                                            {model.Error !== null && (<div className="widget-subheading text-danger" style={{ opacity: "1" }}>{model.Error}</div>)}
                                        </div>
                                        <div className="widget-content-left ml-10" style={{ width: "100px" }}>
                                            <div className="widget-subheading">{model.Size}</div>
                                        </div>
                                        <div className="widget-content-right">
                                            {
                                                //Status: 0-Проект; 1-Загрузка; 2-Успешно загруже; 3-Ошибка загрузки;
                                            }

                                            {model.Status === 0 && (
                                                <button className="btn-square btn-hover-shine btn btn-danger ml-10" onClick={() => fileDispatch({ type: 'remove', name: model.File.name })}>
                                                    <i><FontAwesomeIcon icon={faTrash} /></i>
                                                </button>
                                            )}
                                            {model.Status === 3 && (
                                                <button className="btn-square btn-hover-shine btn btn-danger ml-10" onClick={() => fileDispatch({ type: 'remove', name: model.File.name })}>
                                                    <i><FontAwesomeIcon icon={faTrash} /></i>
                                                </button>
                                            )}
                                            {model.Status === 1 && (
                                                <FontAwesomeIcon icon={faSpinner} size="2x" />
                                            )}
                                            {model.Status === 2 && (
                                                <FontAwesomeIcon icon={faCheck} size="2x" className="text-success" />
                                            )}
                                            {model.Status === 4 && (
                                                <FontAwesomeIcon icon={faXmark} size="2x" className="text-danger" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}

                {error != null && (
                    <div className="form_error text-center">
                        {error}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
                    <FontAwesomeIcon icon={faXmark} className="mr-5" />
                    Закрыть
                </button>
                <button
                    className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                    type="submit"
                    disabled={loading}
                    onClick={() => uploadHandler()}
                >
                    {loading ? (
                        "Загрузка..."
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faUpload} className="mr-5" /> Загрузить файлы
                        </>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default FileUploadModal;