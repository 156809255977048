import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useCheckListCompleteMutation, useCheckListListQuery } from "../../../../api/tracker/task";
import { Form } from "react-bootstrap";


function ModalComplete({ show, onHide, onHideDetail, model }) {

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inputComment, setInputComment] = useState(null);

    useEffect(() => {
        //открытие
        if (show === true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setInputComment(null);

        }
    }, [show]);

    const [complete, { data: completeData, status: completeStatus }] = useCheckListCompleteMutation();
    const checkListApi = useCheckListListQuery({ ID_Task: model.ID_Task });

    function completeHandler() {
        setLoading(true);
        setLog(null);

        complete({ ID: model.ID, Comment: inputComment })
            .unwrap()
            .then((res) => {
                if (res.Status === 1) {
                    checkListApi.refetch();
                    onHide();
                    onHideDetail();
                    setLoading(false);
                }
                else {
                    setLog(res.Error);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLog('Неизвестная ошибка!');
                setLoading(false);
            })
    }

    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <i className="mr-10"><FontAwesomeIcon icon={faFlagCheckered} /></i>Завершение задачи из чек-листа
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mt-10 mb-10">
                        <b>«{model.Name}»</b>
                    </div>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Комментарий</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={(e) => setInputComment(e.target.value)} defaultValue={inputComment} placeholder="Не обязательно" />
                    </Form.Group>
                    {log != null && <div className="form_error text-center">{log}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        disabled={loading}
                        onClick={() => completeHandler()}
                    >
                        {loading ? (
                            "Завершение..."
                        ) : (
                            <>
                                    <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" /> Завершить
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalComplete;
