import { faEdit, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import UserModalEdit from './UserModalEdit';



function UserList({data}) {

   //Модальные окна
   const [modalUpdateShow, setModalUpdateShow] = useState(false);
   const [modelUpdate, setModelUpdate] = useState([]);

   function modalUpdateShowEvent(model){
    setModalUpdateShow(true)
    setModelUpdate(model)
   }


  return (
    <>    
      <UserModalEdit show={modalUpdateShow} onHide={() => setModalUpdateShow(false)} model={modelUpdate}/>
      {data.isFetching === true || data.status === 'rejected' ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} size="2x" />
        </div>
      ) : (
        <div className="table-responsive">
            <table className="table">
            <thead className='thead_blue'>
                <tr>
                    <th>Имя</th>    
                    <th>Роль</th>    
                    <th></th>  
                </tr>
            </thead>
            <tbody>
            {data.data.Data.map((model) => (
                <tr className='row_select' key={model.ID_User}>                
                    <td>{model.User_Name}</td>
                    <td>{model.Name_Role}</td>
                    <td className='text-end'>
                        <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => modalUpdateShowEvent(model)}>
                          <i className="mr-5">
                            <FontAwesomeIcon icon={faEdit} />
                          </i>
                          Изменить
                        </button>                      
                    </td>                  
                </tr>
            ))}
            </tbody>
            </table>        
        </div>
      )}
    </>
  );
}

export default UserList;
