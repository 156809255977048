import { Form } from "react-bootstrap";

function WeekInput({ defaultValue, change, label }) {

    return (
        <Form.Group key={defaultValue}>
            <Form.Label>{label}</Form.Label>
            <Form.Select key={Math.random()} defaultValue={defaultValue} onChange={change}>
                <option value={-1}>Не выбрано</option>
                <option value={2}>ПН</option>
                <option value={3}>ВТ</option>
                <option value={4}>СР</option>
                <option value={5}>ЧТ</option>
                <option value={6}>ПТ</option>
                <option value={7}>СБ</option>
                <option value={1}>ВС</option>
            </Form.Select>
        </Form.Group>
    )
}

export default WeekInput;