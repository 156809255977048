import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { ValidateForm } from "../../../util/ValidateForm";
import { useEffect, useState } from "react";
import FormToJson from "../../../util/FormToJson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRoleListQuery } from '../../../api/main/service';
import { CompanyHubAddRequest } from "../../../SignalR/CompanyHub";
import { useUserStatusListQuery, useUserUpdateMutation } from "../../../api/main/company";
import { useUserListMutation } from "../../../api/tracker/task";

function UserModalEdit(props) {
  const { show, onHide} = props;
  const { ID_Role, ID_Status, ID_User, User_Name, User_Description } = props.model;

  const [validErrors, setValidErrors] = useState([]);
  const [reqErrors, setReqErrors] = useState('');

  //API
  const [add, { isError: addIsError, isFetching: addIsFetching, isLoading: addIsLoading, data: addData, status: addStatus }] = useUserUpdateMutation();

  const userStatusList = useUserStatusListQuery();
  const roleList =  useRoleListQuery({ID_Service:2});

  //Обработка запроса API
  useEffect(() => {   
    if(addStatus === 'fulfilled'){   

      if(addData.Status == 1){
        onHide();
        //requestOutList.refetch();

        CompanyHubAddRequest({id_request:addData.Data})
      }
      else{
        setReqErrors(addData.Error)
      }
    }
  }, [addStatus]);

  //Отправка формы
  const submit = (e) => {
    e.preventDefault();
    setReqErrors('');
    
    var validForm = ValidateForm(e.target, setValidErrors);  

    if (validForm) { 
      add(FormToJson(e.target));
    }
  };


  return (
    <>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <>
              <FontAwesomeIcon icon={faEdit} /> Изменение пользователя
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="my-form" onSubmit={submit}>
            <input  name='id_user' defaultValue={ID_User} hidden></input>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Имя пользователя</Form.Label>
              <Form.Control name="user_name" type="text" valid='[{"id": "empty"}]' disabled={addIsLoading} defaultValue={User_Name}/>
              {validErrors.find((el) => el.name === "user_name") !== undefined && (
                <div className="form_error">
                  {validErrors.find((el) => el.name === "user_name").error}
                </div>
              )}
            </Form.Group>         
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Роль в компании</Form.Label>
              <Form.Select aria-label="Default select example" defaultValue={ID_Role} name="id_role">
                {roleList.status === 'fulfilled' &&
                  roleList.data.Data.map((model) => (
                    <option key={model.ID_Role} value={model.ID_Role}>
                      {model.Name_Role}
                    </option>
                  ))}
              </Form.Select>
              {validErrors.find((el) => el.name === 'description') !== undefined && validErrors.find((el) => el.name === 'description').error}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Описание</Form.Label>
              <Form.Control as="textarea" rows={3} name="user_description" disabled={addIsLoading} defaultValue={User_Description}/>            
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Статус</Form.Label>
              <Form.Select aria-label="Default select example" defaultValue={ID_Status} name="id_status">
                {userStatusList.status === 'fulfilled' &&
                  userStatusList.data.Data.map((model) => (
                    <option key={model.ID} value={model.ID}>
                      {model.Name}
                    </option>
                  ))}
              </Form.Select>
              {validErrors.find((el) => el.name === 'description') !== undefined && validErrors.find((el) => el.name === 'description').error}
            </Form.Group>
          </Form>
          {addIsError == true && (
            <div className="form_error">
              Неизвестная ошибка! Обратитесь в техническую поддержку
            </div>
          )}
          {reqErrors != '' && (
            <div className="form_error">
              {reqErrors}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
            <FontAwesomeIcon icon={faXmark} className="mr-5" />
            Закрыть
          </button>
          <button
            className="mr-5 btn-square btn-hover-shine btn btn-secondary"
            form="my-form"
            type="submit"
            disabled={addIsLoading}
          >
            {addIsLoading ? (
              "Загрузка..."
            ) : 
            (
              <>
                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить изменения
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default UserModalEdit;
