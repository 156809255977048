import React from 'react';

import ReactDOM from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import rootReducer from './reducers/root';
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//MAIN
import IndexMain from './Pages/Main/Index';
import AppMain from './Pages/Main/App';
import Login from './Pages/Main/Login';
import Login1 from './Pages/Main/Login1';

//LK
import IndexTask from './Pages/Lk/Task/Index';
import IndexTaskRsetting from './Pages/Lk/TaskR/Setting/Index';
import IndexTaskR from './Pages/Lk/TaskR/Tracker/Index';
import IndexBoard from './Pages/Lk/Board/Index';
import IndexProject from './Pages/Lk/Project/Index';
import IndexCompany from './Pages/Lk/Company/Index';
import IndexParameter from './Pages/Lk/Parameter/Index';
import IndexMessages from './Pages/Lk/Messages/Index';

import IndexLk from './Pages/Lk/Index';
import EmailConfirm from './Pages/Lk/EmailConfirm';
import IndexUser from './Pages/Lk/User/Index';
import IndexSetting from './Pages/Lk/Setting/Index';
import Test from './Pages/Test';

//API
import { companyApi } from './api/main/company';
import { serviceApi } from './api/main/service';
import { userApi } from './api/main/user';
import { projectApi } from './api/tracker/project';
import { taskApi } from './api/tracker/task';
import { taskRApi } from './api/tracker/taskR';
import { parameterApi } from './api/tracker/parameter';

//SignalR
import { CompanyHubConnect } from './SignalR/CompanyHub';
import UserBlocked from './Pages/Lk/UserBlocked';



//SignalR Connection
CompanyHubConnect();


//STORE
export const store = configureStore({
  reducer: {
    ...rootReducer,

    // Add the generated reducer as a specific top-level slice
    [companyApi.reducerPath]: companyApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [taskRApi.reducerPath]: taskRApi.reducer,
    [parameterApi.reducerPath]: parameterApi.reducer,



  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(companyApi.middleware)
    .concat(serviceApi.middleware)
    .concat(userApi.middleware)
    .concat(projectApi.middleware)
    .concat(taskApi.middleware)
    .concat(taskRApi.middleware)
    .concat(parameterApi.middleware)

});

setupListeners(store.dispatch);


const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = (Component) => {
  root.render(
    <>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<IndexMain />}>
              <Route index element={<div>sfs</div>} />
              <Route path="login" element={<Login />} />
              <Route path="login1" element={<Login1 />} />
              <Route path="*" element={<div>No Found</div>} />
            </Route>
            <Route path="/lk" element={<IndexLk />}>
              <Route index element={<div>Main</div>} />              
              <Route path="project" element={<IndexProject />} />
              <Route path="task" element={<IndexTask />} />
                <Route path="messages" element={<IndexMessages />} />
                <Route path="/lk/taskR/setting" element={<IndexTaskRsetting />} />
                <Route path="/lk/taskR/current" element={<IndexTaskR />} />
                <Route path="/lk/param/task" element={<IndexParameter id_owner={1} />} />
                <Route path="/lk/param/rtask" element={<IndexParameter id_owner={2} />} />
                <Route path="/lk/param/board" element={<IndexParameter id_owner={3} />} />
                <Route path="/lk/param/rboard" element={<IndexParameter id_owner={4} />} />
                <Route path="board" element={<IndexBoard />} />
              <Route path="setting" element={<IndexSetting />} />
              <Route path="support" element={<div>Support</div>} />
              <Route path="company" element={<IndexCompany />} />
              <Route path="user" element={<IndexUser />} />
              <Route path="email_confirm" element={<EmailConfirm />} />
              <Route path="user_blocked" element={<UserBlocked />} />
                <Route path="test" element={<Test />} />
              <Route path="*" element={<div>No Found</div>} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Component />
        </DndProvider>
      </Provider>
    </>
  );
};

renderApp(AppMain);
