import { faCheck, faEnvelope, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { useEmailConfirmMutation, useInfoGetQuery } from "../../api/main/user";
import { useEffect, useState } from "react";
import { ValidateForm } from "../../util/ValidateForm";
import FormToJson from "../../util/FormToJson";
import { Navigate } from 'react-router-dom';
import { func } from "prop-types";

function EmailConfirm() {
    const [validErrors, setValidErrors] = useState([]);
    const [reqErrors, setReqErrors] = useState('');

    const [emailConfirmSuccess, setEmailConfirmSuccess] = useState(false);
    const [userStatus, setUserStatus] = useState(1);

    const [emailConfirm, { isError: emailConfirmIsError, isLoading: emailConfirmLoading, data: emailConfirmData, status: emailConfirmStatus }] = useEmailConfirmMutation();
    const userInfo = useInfoGetQuery();

    //Отправка формы
    const submit = (e) => {      
        e.preventDefault();
        setReqErrors('');
        var validForm = ValidateForm(e.target, setValidErrors);

        if (validForm) {
            emailConfirm(FormToJson(e.target));
        }
    };

    //Обработка запроса API
    useEffect(() => {
        if (emailConfirmStatus === 'fulfilled') {        
            userInfo.refetch();

            if (emailConfirmData.Status === 1) {
                setEmailConfirmSuccess(true);
            }
            else {

                setReqErrors(emailConfirmData.Error)
            }
        }
    }, [emailConfirmStatus]);

    useEffect(() => {
        if (userInfo.status === 'fulfilled') {
            if (userInfo.data.Status === 1) {
                setUserStatus(userInfo.data.Data.ID_Status);
                setEmailConfirmSuccess(userInfo.data.Data.Email_Confirmed);
            }
        }
    }, [userInfo.status]);


    //Редирект в случае если пользователь заблокирован
    if (userStatus === -1) {
        return <Navigate to="/lk/user_blocked" replace />
    }
    //Редирект в случае если код успешный
    else if (emailConfirmSuccess === true) {
        return <Navigate to="/lk/" replace />
    }
    else {
        return (
            <>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="main-card mb-3 card">
                            <div className="card-header-tab card-header-tab-animation card-header">
                                <div className="card-header-title">
                                    <FontAwesomeIcon icon={faEnvelope} className="fs-2 mr-10" /> Подтверждение электронной почты
                                </div>
                            </div>
                            <div className="card-body">
                            <div className="mb-15">
                                Для началы работы в сервисе подтвердите электронную почту. Для этого введите код из письма, поступившего на электронную почту.
                                </div>
                            <Form id="my-form" onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Код из письма</Form.Label>
                                    <Form.Control name="Code" type="text" valid='[{"id": "empty"}]' disabled={emailConfirmLoading} />
                                    {validErrors.find((el) => el.name === "Code") !== undefined && (
                                        <div className="form_error">
                                            {validErrors.find((el) => el.name === "Code").error}
                                        </div>
                                    )}
                                </Form.Group>

                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button
                                            className="mr-5 btn-square btn-hover-shine btn btn-secondary "
                                            form="my-form"
                                            type="submit"
                                            disabled={emailConfirmLoading}
                                        >
                                            {emailConfirmLoading ? (
                                                "Загрузка..."
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faCheck} className="mr-5" /> Проверить код
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>

                            {emailConfirmIsError == true && (
                                <div className="form_error mt-10 text-center">
                                    Неизвестная ошибка! Обратитесь в техническую поддержку
                                </div>
                            )}
                            {reqErrors != '' && (
                                <div className="form_error mt-10 text-center">
                                    {reqErrors}
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </>
        )
    }


}
export default EmailConfirm;