import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faEdit, faPen, faList, faFloppyDisk, faHashtag, faPlus, faTrash, faWandMagicSparkles, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup, Nav, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { useAddMutation, useReferenceRemoveMutation, useUpdateMutation, useReferenceSetMassMutation, useReferencelistMutation, useProjectListMutation } from '../../../api/tracker/parameter';
import FormToJson from "../../../util/FormToJson";
import ModalParameterProject from "./ModalParameterProject";
import ModalParameterProjectRemove from "./ModalParameterProjectRemove";


function ModalParameter(props) {
    const { show, onHide, model, listUpdate, id_owner } = props;  

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null)
            setTypeData(model.ID_Type);
            listProjectApi({ ID_Parameter: model.ID })    
            //Если справочник
            if (model.ID_Type === 4) {
                listReference({ ID_Parameter: model.ID });
            }      
        }
    }, [show]);

    //ВЫБОР ТИПА ДАННЫХ
    const [typeData, setTypeData] = useState(model.ID_Type);

    function changeTypeData(e, id_type) {
        e.preventDefault();
        setTypeData(id_type);
    }

    //МОДАЛЬНЫЕ ОКНА


    /*--------------------------START ПАРАМЕТРЫ-------------------------------*/
    //ДОБАВЛЕНИЕ ЭЛЕМЕНТОВ В СПРАВОЧНИК
    const [reference, setReference] = useState([{ ID: null, ID_Parameter: model.ID, Value: "", Order: 0, Archive: false, Error: false }]);
    const [referenceSet, { data: referenceSetData, status: referenceSetStatus }] = useReferenceSetMassMutation();
    const [referenceRemove, { data: referenceRemoveData, status: referenceRemoveStatus }] = useReferenceRemoveMutation();
    const [referenceRemoveIndex, setReferenceRemoveIndex] = useState(null);
    const [listReference, { data: listReferenceData, status: listReferenceStatus }] = useReferencelistMutation();

    const handleAddFields = (e) => {
        e.preventDefault();
        setReference([...reference, { ID: null, ID_Parameter: model.ID, Value: "", Order: 0, Archive: false, Error: false }]);
    };
    const handleValueChange = (e, index) => {
        e.preventDefault();

        const values = [...reference];
        values[index].Value = e.target.value;
        values[index].ID_Parameter = model.ID;
        setReference(values);
    };
    const handleRemoveFields = (e, index) => {
        e.preventDefault();

        const ID = reference[index].ID;

        //Если элемент есть в БД
        if (ID !== null) {
            setReferenceRemoveIndex(index);
            referenceRemove({ ID: ID, ID_Parameter: model.ID })
        }
        else {
            const newInputFields = [...reference];
            newInputFields.splice(index, 1);
            setReference(newInputFields);
        }
    };

    //Обработка удаления элемента
    useEffect(() => {
        if (referenceRemoveStatus === 'fulfilled') {
            if (referenceRemoveData.Status === 1) {
                const newInputFields = [...reference];
                newInputFields.splice(referenceRemoveIndex, 1);
                setReference(newInputFields);
            }
            else {
                const values = [...reference];
                values[referenceRemoveIndex].Error = true;
                setReference(values);
            }
        }
        if (referenceRemoveStatus === 'rejected') {
            const values = [...reference];
            values[referenceRemoveIndex].Error = true;
            setReference(values);
        }
    }, [referenceRemoveStatus]);

    //Обработка загрузки элементов справочника
    useEffect(() => {
        if (listReferenceStatus === 'fulfilled') {
            if (listReferenceData.Status === 1) {
                var data = [];

                //Добавляем необходимые столбцы к объекты (Error)
                listReferenceData.Data.map((row, index) => {
                    data.push({ ID: row.ID, ID_Parameter: row.ID_Parameter, Value: row.Value, Order: 0, Archive: row.Archive, Error: false });
                }
                )
                setReference(data);
            }
        }
        if (listReferenceStatus === 'rejected') {
            //setIdErrorProject(idProject);            
        }
    }, [listReferenceStatus]);

    /*---------------------------END ПАРАМЕТРЫ---------------------------------*/
    /*---------------------------START ПРОЕКТЫ---------------------------------*/
    const [modalParameterProjectShow, setModalParameterProjectShow] = useState(false);
    const [modalParameterProjectRemoveShow, setModalParameterProjectRemoveShow] = useState(false);
    const [selectProject, setSelectProject] = useState([]);   //Выбранный проект

    //СПИСОК ПРОЕКТОВ
    const [listProjectApi, { data: listProjectApiData, status: listProjectApiStatus, isLoading: listProjectApiIsLoading }] = useProjectListMutation();
    const [listProject, setInputProject] = useState([]);

    useEffect(() => {
        if (listProjectApiStatus === 'fulfilled') {
            //console.log(listProjectApiData.Data);
            setInputProject(listProjectApiData.Data);
        }
    }, [listProjectApiStatus]);

    //РЕДАКТИРОВАНИЕ ПРОЕКТА
    const [editProject, setEditProject] = useState(false);
    function handlerProjectEdit(model) {
        setSelectProject(model);
        setEditProject(true);
        setModalParameterProjectShow(true);
    }

    //ДОБАВЛЕНИЕ ПРОЕКТА        
    function handlerProjectAdd() {
        setEditProject(false);
        setModalParameterProjectShow(true);
    }

    //УДАЛЕНИЕ ПРОЕКТА
    function handlerProjectRemove(model) {
        setSelectProject(model);
        setModalParameterProjectRemoveShow(true);
    }
    /*----------------------------END ПРОЕКТЫ-----------------------------------*/

    //СОХРАНЕНИЕ ИЗМЕНЕНИЙ
    const [add, { data: addData, status: addStatus }] = useAddMutation();
    const [update, { data: updateData, status: updateStatus }] = useUpdateMutation();

    const [loadingSave, setLoadingSave] = useState(false);
    const [error, setError] = useState(null);

    const submit = (e) => {

        e.preventDefault();

        setLoadingSave(true);
        setError(null);

        var jsonForm = FormToJson(e.target);
        jsonForm['ID_Parameter'] = model.ID;
        jsonForm['ID_Type'] = typeData;

        if (jsonForm.Name === null || jsonForm.Name === '') {
            setError('Введите название');
            setLoadingSave(false);
        }        
        else if (typeData === null) {
            setError('Выберите тип параметра');
            setLoadingSave(false);
        }
        else if (typeData === 4 & reference.filter(a => a.Value !== '').length === 0) {
            setError('Для параметра с типом "Справочник" добавьте хотя бы один элемент');
            setLoadingSave(false);
        }       
        else {
            if (model.ID_Status === -1) {
                add(jsonForm);                          
            }
            else {
                update(jsonForm);              
            }
        }
    };

    //ОБРАБОТКА ЗАПРОСОВ
    //Шаг 1 Добавление/Редактирование
    useEffect(() => {
        //Если добавление параметра
        if (model.ID_Status === -1) {
            if (addStatus === 'fulfilled') {
                if (addData.Status == 1) {
                    //Если справочник
                    if (typeData === 4) {
                        referenceSet(reference.filter(a => a.Value !== ''));
                    }
                    else {
                        setLoadingSave(false);
                        listUpdate({ ID_Owner: model.ID_Owner });
                        onHide();
                    }
                }
                else {
                    setLoadingSave(false);
                    setError(addData.Error);
                }
            }
            if (addStatus === 'rejected') {
                setLoadingSave(false);
                setError('Неизвестная ошибка!');
            }
        }
        //Если обновление параметра
        else {
            if (updateStatus === 'fulfilled') {
                if (updateData.Status == 1) {
                    //Если справочник
                    if (typeData === 4) {
                        referenceSet(reference.filter(a => a.Value !== ''));
                    }
                    else {
                        setLoadingSave(false);
                        listUpdate({ ID_Owner: model.ID_Owner });
                        onHide();
                    }
                }
                else {
                    setError(updateData.Error);
                    setLoadingSave(false); 
                }
            }
            if (updateStatus === 'rejected') {
                setError('Неизвестная ошибка!');
                setLoadingSave(false); 
            }
        }
    }, [addStatus, updateStatus]);
    //Шаг 2 Элементы справочника
    useEffect(() => {
        if (referenceSetStatus === 'fulfilled') {
            if (referenceSetData.Status == 1) {      
                setLoadingSave(false);
                listUpdate({ ID_Owner: model.ID_Owner });
                onHide();
            }
            else {
                setError(referenceSetData.Error);
                setLoadingSave(false);
            }
        }
        if (referenceSetStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoadingSave(false); 
        }
    }, [referenceSetStatus]);

    return (
        <>
            <Modal show={show} onHide={onHide} dialogClassName="modal-60">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            {model.ID_Status == -1 ?
                                (<div><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i> Добавление параметра с типом «{model.Name_Owner}»</div>)
                                :
                                (<div><i className="mr-5"><FontAwesomeIcon icon={faEdit} /></i> Изменение параметра #{model.ID} «{model.Name}»</div>)
                            }
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" >
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-card card">
                                        <div className="card-body">
                                            <div className="card-block-title">Основное</div>
                                            <Form id="my-form" onSubmit={submit}>
                                        <Form.Group className="mb-1">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <Form.Label>Название</Form.Label>
                                                    <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' defaultValue={model.Name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <Form.Label>Статус</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Form.Select defaultValue={model.ID_Status === -1 ? 1 : model.ID_Status} name="ID_Status">
                                                            <option value={0}>Архив</option>
                                                            <option value={1}>Активный</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Описание</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="description" defaultValue={model.Description} />
                                        </Form.Group>


                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Тип данных</Form.Label>
                                            {model.ID_Status === -1 ?
                                                (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <button onClick={(e) => changeTypeData(e, 3)} className={typeData === 3 ? "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg active" : "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg"}><i className="mr-5"><FontAwesomeIcon icon={faPen} /></i>Строка</button>
                                                            <button onClick={(e) => changeTypeData(e, 2)} className={typeData === 2 ? "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15 active" : "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15"}><i className="mr-5"><FontAwesomeIcon icon={faHashtag} /></i>Число</button>
                                                            <button onClick={(e) => changeTypeData(e, 5)} className={typeData === 5 ? "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15 active" : "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15"}><i className="mr-5"><FontAwesomeIcon icon={faCalendarDays} /></i>Дата</button>
                                                            <button onClick={(e) => changeTypeData(e, 1)} className={typeData === 1 ? "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15 active" : "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15 "}><i className="mr-5"><FontAwesomeIcon icon={faWandMagicSparkles} /></i>Логический</button>
                                                            <button onClick={(e) => changeTypeData(e, 4)} className={typeData === 4 ? "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15 active" : "mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg ml-15"}><i className="mr-5"><FontAwesomeIcon icon={faList} /></i>Справочник</button>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div>
                                                        {typeData === 1 && (<button className="mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg active" disabled><i className="mr-5"><FontAwesomeIcon icon={faWandMagicSparkles} /></i>Логический</button>)}
                                                        {typeData === 2 && (<button className="mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg active" disabled><i className="mr-5"><FontAwesomeIcon icon={faHashtag} /></i>Число</button>)}
                                                        {typeData === 3 && (<button className="mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg active" disabled><i className="mr-5"><FontAwesomeIcon icon={faPen} /></i>Строка</button>)}
                                                        {typeData === 4 && (<button className="mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg active" disabled><i className="mr-5"><FontAwesomeIcon icon={faList} /></i>Справочник</button>)}
                                                        {typeData === 5 && (<button className="mb-2 mr-2 btn-pill btn btn-outline-secondary btn-lg active" disabled><i className="mr-5"><FontAwesomeIcon icon={faCalendarDays} /></i>Дата</button>)}
                                                    </div>
                                                )
                                            }
                                            {typeData === 4 &&
                                                (
                                                    <div className="mt-15">
                                                        <Form.Label>Элементы справочника:</Form.Label>
                                                        <Tab.Container id="left-tabs-example" defaultActiveKey="active" >
                                                            <Nav className="mb-10 justify-content-end">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="active" className="border-0 btn-transition btn btn-outline-secondary btn-sm">Активные</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="archive" className="ml-10 border-0 btn-transition btn btn-outline-secondary btn-sm">Архивные</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                            <Tab.Content>
                                                            <Tab.Pane eventKey="active" className="mt-30 mb-30">
                                                                {reference.map((model, index) => (
                                                                    <div key={index} >
                                                                        <InputGroup className="mt-20">
                                                                            <Input onChange={(e) => handleValueChange(e, index)} className="mr-15" value={model.Value} />
                                                                            <button onClick={(e) => handleRemoveFields(e, index)} className="btn-icon btn-icon-only btn btn-danger"><i className=""><FontAwesomeIcon icon={faTrash} /></i></button>
                                                                        </InputGroup>
                                                                        <div>
                                                                            {model.Error === true && (<div className="form_error float-right">Ошибка удаления</div>)}
                                                                        </div>
                                                                    </div>
                                                                    ))}
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="archive">Second tab content</Tab.Pane>
                                                            </Tab.Content>
                                                    </Tab.Container>
                                                        <div className="row mt-15">
                                                            <div className="col-md-12 text-center">
                                                            <button onClick={(e) => handleAddFields(e)} className="mb-2 mr-2 btn-pill btn btn-dashed btn-outline-secondary"><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавить</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="main-card card">
                                <div className="card-body">
                                    <div className="card-block-title">Проекты</div>
                                    <div>
                                        <button onClick={(e) => handlerProjectAdd()} className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary"><FontAwesomeIcon icon={faPlus} /> Добавить...</button>
                                        <ModalParameterProject show={modalParameterProjectShow} onHide={() => setModalParameterProjectShow(false)} id_parameter={model.ID} edit={editProject} model_project={selectProject} list_pproject_update={listProjectApi} id_owner={id_owner} />
                                        <ModalParameterProjectRemove show={modalParameterProjectRemoveShow} onHide={() => setModalParameterProjectRemoveShow(false)} id_parameter={model.ID} model_project={selectProject} list_pproject_update={listProjectApi} />
                                    </div>
                                    <div className="mt-10">
                                        {
                                            listProjectApiIsLoading === true ? <div className="loader_01"></div>
                                                :
                                                <ul className="todo-list-wrapper list-group list-group-flush">
                                                    {listProject.filter(a => a.Checked == true).map((model, index) => (
                                                        <li className="list-group-item row_select" key={model.ID}>
                                                            <div className="todo-indicator bg-success"></div>
                                                            <div className="widget-content p-0">
                                                                <div className="widget-content-wrapper">
                                                                    <div className="widget-content-left flex2">
                                                                        <div>{model.Name}</div>
                                                                        {model.Display_Count === 0 && (<div className="text_error">Не отображается</div>)}
                                                                    </div>
                                                                    <div className="widget-content-right">
                                                                        <button className="btn-square btn-hover-shine btn btn-sm btn-secondary" onClick={e => handlerProjectEdit(model)}>
                                                                            <i><FontAwesomeIcon icon={faPenToSquare} /></i>
                                                                        </button>
                                                                        <button className="btn-square btn-hover-shine btn btn-sm btn-secondary ml-10" onClick={e => handlerProjectRemove(model)}>
                                                                            <i><FontAwesomeIcon icon={faTrash} /></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                        }
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}

                    <button
                        className="btn-square btn-hover-shine btn btn-secondary btn-width-100_"
                        form="my-form"
                        type="submit"
                        // onClick={() => handlerSave()}
                        disabled={loadingSave}
                    >
                        {loadingSave ? (
                            "Сохранение..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalParameter;
