import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faListCheck, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import ModalTask from "./ModalTask";
import { useLazyGetTempQuery } from "../../../api/tracker/task";

function ModalAddInfo(props) {
    const { show, onHide, listCompleteApi } = props;

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalTaskShow, setModalTaskShow] = useState(false);

    const [getTempApi, getTempApiResult] = useLazyGetTempQuery();

    //Выбор типа задачи
    function taskChangeHandler(arg) {
        setLoading(true);
        getTempApi({ ID_Type: arg })
            .unwrap()
            .then(res => {
                setModalTaskShow(true);
                setLoading(false);
                onHide();
            });
    };   

    return (
        <>
            {getTempApiResult.status === 'fulfilled' && <ModalTask key={3} show={modalTaskShow} onHideTask={() => setModalTaskShow(false)} edit={false} listCompleteApi={listCompleteApi} model={getTempApiResult.data.Data} completeCheck={false} />}

            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faLightbulb} /></i> Выберите тип задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid-menu grid-menu-3col mb-30">
                        <div className="no-gutters row">
                            <div className="col-sm-4">
                                <div className="p-1">
                                    <button onClick={() => taskChangeHandler(1)} className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                                        <i className="text-primary opacity-7 btn-icon-wrapper mb-2"> <FontAwesomeIcon icon={faUser} /></i >  Выполнят один
                                    </button>
                                </div>
                                <div>
                                    Задача считается выполненной если ее выполнит хотя бы одним из ответственных.
                                </div>
                            </div>
                            <div className="col-sm-4 test_border">
                                <div className="p-1">
                                    <button onClick={() => taskChangeHandler(2)} className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                                        <i className="text-primary opacity-7 btn-icon-wrapper mb-2"> <FontAwesomeIcon icon={faUsers} /></i >  Выполняет каждый
                                    </button>
                                </div>
                                <div>
                                    Задача считается выполненной если каждый ответственный выполнит задачу.
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="p-1">
                                    <button onClick={() => taskChangeHandler(3)} className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                                        <i className="text-primary opacity-7 btn-icon-wrapper mb-2"> <FontAwesomeIcon icon={faListCheck} /></i >  По чек листу
                                    </button>
                                </div>
                                <div>
                                    В данной задаче создается чек лист из дочерних задач. Для каждой дочерней задачи указывается ответственный. Задача считается выполненной в случае выполнения каждой дочерней задачи.
                                </div>
                            </div>                            
                        </div>

                        {loading === true && (<span className="loader_01"></span>)}

                        {log != null && <div className="form_error text-center">{log}</div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ModalAddInfo;
