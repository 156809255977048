import { Form } from "react-bootstrap";

function Select({ list, defaultValue, change }) {

    return (
        <Form.Group key={defaultValue}>
            <Form.Select key={Math.random()} defaultValue={defaultValue} onChange={change}>
                {list.length > 0 &&
                    list.map((model) => (
                        <option key={model.id} value={model.id}>
                            {model.name}
                        </option>
                    ))}
            </Form.Select>
        </Form.Group>
    )
}

export default Select;