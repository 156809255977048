import { Form } from "react-bootstrap";
import { useReferencelistMutation } from "../../api/tracker/parameter";
import { useEffect, useState } from "react";

function ParametersSelect(props) {
    const { model, handlerChange } = props;

    useEffect(() => {
        listReference({ ID_Parameter: model.ID_Parameter })
    }, [model])

    const [value, setValue] = useState(model.Value_Float);
    const [options, setOptions] = useState([]);
    const [listReference, { data: listReferenceData, status: listReferenceStatus }] = useReferencelistMutation();

    useEffect(() => {
        if (listReferenceStatus === 'fulfilled') {
            if (listReferenceData.Status === 1) {
                setOptions(listReferenceData.Data);
            }
        }
        if (listReferenceStatus === 'rejected') {

        }
    }, [listReferenceStatus]);


    function onChange(e) {
        setValue(e.target.value)
        handlerChange(e, model.ID_Parameter, model.ID_Type)
    }
    //key={Math.random()}
    return (
        <Form.Group>
            <Form.Label>{model.Name}</Form.Label>
            <Form.Select key={model.ID_Parameter} onChange={(e) => onChange(e)} value={value} /*onChange={}*/>
                <option key={0} value={0}>
                    Не выбрано
                </option>
                {options.length > 0 &&
                    options.map((model) => (
                        <option key={model.ID} value={model.ID}>
                            {model.Value}
                        </option>
                    ))}
            </Form.Select>
        </Form.Group>
    )
}
export default ParametersSelect;