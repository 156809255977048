import { faCheck, faPenToSquare, faPlus, faRectangleList, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAddCompanyMutation, useGetListCompanyQuery, useSelectMutation, useSelectedIdGetQuery, useUpdateCompanyMutation } from '../../../api/main/company';
import React, { useEffect, useState } from 'react';
import CompanyModalAdd from './CompanyModalAdd';
import CompanyModalRemove from './CompanyModalRemove';
import CompanyModalEdit from './CompanyModalEdit';

function CompanyList(ID_Status) {
  //Модальные окна
  const [modalAddShow, setModalAddShow] = useState(false);
  const [modalRemoveShow, setModalRemoveShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  //API
  const { data: companyData, isFetching: companyIsFetching, isLoading: companyIsLoading } = useGetListCompanyQuery(ID_Status);
  const [addCompany, { isError: addError, isFetching: addIsFetching, status: addStatus }] = useAddCompanyMutation();
  const [updateCompany, { isError: updateError, isFetching: updateIsFetching, status: updateStatus }] = useUpdateCompanyMutation();

  const [companySelect] = useSelectMutation();
  const companySelected = useSelectedIdGetQuery();

  const [selectedModel, setSelectedModel] = useState({});

  const [compnaySelectedId, setCompnaySelectedId] = useState(0);

  useEffect(() => {
    if (companySelected.status === 'fulfilled') {
      setCompnaySelectedId(companySelected.data.Data)
    }
  }, [companySelected.status]);


  function ModalRemove(model) {
    setSelectedModel(model);
    setModalRemoveShow(true);
  }
  function ModalEdit(model) {
    setSelectedModel(model);
    setModalEditShow(true);
  }
  function companyChange(model) {
    companySelect({ ID_Company: model.ID })
  };

  return (
    <>
      <button className="mr-5 mb-20 btn-square btn-hover-shine btn btn-secondary" onClick={() => setModalAddShow(true)}>
        <i className="mr-5">
          <FontAwesomeIcon icon={faPlus} />
        </i>
        Добавить компанию
      </button>

      <CompanyModalAdd show={modalAddShow} onHide={() => setModalAddShow(false)} addCompany={addCompany} addError={addError} addIsFetching={addIsFetching} addStatus={addStatus} />
      <CompanyModalRemove show={modalRemoveShow} onHide={() => setModalRemoveShow(false)} model={selectedModel} />
      <CompanyModalEdit show={modalEditShow} onHide={() => setModalEditShow(false)} model={selectedModel} updateCompany={updateCompany} updateError={updateError} updateStatus={updateStatus} />

      {companyIsLoading === true ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} size="2x" />
        </div>
      ) : (
        <div>
          <ul className="list-group">
            {companyData.Data.map((model) => (
              <li className="list-group-item row_select" key={model.ID}>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3"></div>
                    <div className="widget-content-left">
                      <div className="widget-heading">
                        {compnaySelectedId === model.ID &&
                          <i className="mr-15 text-success"><FontAwesomeIcon icon={faCheck} /></i>
                        }
                        {model.Name}
                      </div>
                    </div>
                    <div className="widget-content-right">
                      <div role="group" className="btn-group-sm btn-group">
                        {compnaySelectedId !== model.ID &&
                          <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={() => companyChange(model)}>
                            <i className="mr-5"><FontAwesomeIcon icon={faCheck} /></i>
                            Выбрать
                          </button>
                        }
                        <button className="mr-5 btn-square btn-hover-shine btn btn-secondary">
                          <i className="mr-5"><FontAwesomeIcon icon={faRectangleList} /></i>
                          Реквизиты
                        </button>
                        <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={() => ModalEdit(model)}>
                          <i className="mr-5"><FontAwesomeIcon icon={faPenToSquare} /></i>
                          Изменить
                        </button>
                        <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => ModalRemove(model)}>
                          <i className="mr-5"><FontAwesomeIcon icon={faTrash} /></i>
                          Удалить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default CompanyList;
