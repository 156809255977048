import { Outlet } from "react-router";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";

function Index() {
  return (
    <>
      <Header />
      <Outlet></Outlet>
      <Footer />
    </>
  );
}

export default Index;
