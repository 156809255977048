import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered, faMagnifyingGlass, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import StringToDateTime from "../../../../util/StringToDateTime";
import ModalComplete from "./ModalComplete";
import ModalCompleteCancel from "./ModalCompleteCancel";


function ModalDetail({ show, onHide, model }) {


    const [modalComplete, setModalComplete] = useState(false);
    const [modalCompleteCancel, setModalCompleteCancel] = useState(false);


    return (
        <>
            <ModalComplete show={modalComplete} onHide={() => setModalComplete(false)} onHideDetail={onHide} model={model} />
            <ModalCompleteCancel show={modalCompleteCancel} onHide={() => setModalCompleteCancel(false)} onHideDetail={onHide} model={model} />

            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <i className="mr-10"><FontAwesomeIcon icon={faMagnifyingGlass} /></i>Просмотр задачи чек-листа
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-5">
                        <b className="fs-6 text-uppercase opacity-75">{model.Name}</b>
                    </div>
                    {model.Description !== null && (
                        <div className="mb-3 mt-3">
                            <div>{model.Description}</div>
                        </div>
                    )}
                    <div className="mt-20">
                        <label className="form-label">Испонитель: </label>
                        <i className="ml-10">{model.Name_User}</i>
                    </div>
                    <div>
                        <label className="form-label">Создатель: </label>
                        <i className="ml-10">{model.Name_User_Add}</i>
                    </div>
                    <div>
                        <label className="form-label">Дата создания: </label>
                        <i className="ml-10">{StringToDateTime(model.Date_Add)}</i>
                    </div>
                    {model.Completed && (
                        <div>
                            <label className="form-label">Дата завершения: </label>
                            <i className="ml-10">{StringToDateTime(model.Completed_Date)}</i>
                        </div>
                    )}
                    {model.Completed_Comment !== null && (
                        <div className="alert alert-warning" role="alert">
                            {model.Completed_Comment}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {model.Completed ?
                        (
                            <button
                                className="btn btn-light"
                                type="button"
                                onClick={(e) => setModalCompleteCancel(true)}
                            >
                                <FontAwesomeIcon icon={faRotateLeft} className="mr-5" />
                                Отменить завершение задачи
                            </button>
                        )
                        :
                        (
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={(e) => setModalComplete(true)}>
                            <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" />
                            Завершить задачу
                            </button>
                        )
                    }
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalDetail;
