import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';



//https://habr.com/ru/articles/730916/
export const userApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api_main/main/user/' }),
  tagTypes: ['info'],
  reducerPath: 'UserApi',
  endpoints: (builder) => ({
    login: builder.query({
      query: (args) => {
        return {
          url: 'login',
          method: 'POST',
          params: args,
        };
      }
    }),
    infoGet: builder.query({
      query: (args) => {
        return {
          url: 'infoGet',
          method: 'GET',
          params: args,
        };
      },     

      providesTags: ['info'],
    }),


    nameUpdate: builder.mutation({
      query: (args) => {
        return {
          url: 'nameUpdate',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['info'],
    }),
    phoneUpdate: builder.mutation({
      query: (args) => {
        return {
          url: 'phoneUpdate',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['info'],
    }),

    emailConfirm: builder.mutation({
      query: (args) => {
        return {
          url: 'emailConfirm',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['info'],
    }),



  }),
});

export const { useLoginQuery, useInfoGetQuery, useNameUpdateMutation, usePhoneUpdateMutation, useEmailConfirmMutation } = userApi;