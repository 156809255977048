import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { decremented, incremented, load } from '../reducers/models/taskList'
import { useListDraftQuery } from '../api/tracker/task'

function Test() {

    //const count = useSelector((state) => state.taskList.value)
    /*const count1 = useSelector((state) => state.taskList)

    console.log(count1)
    //const count = 2
    const dispatch = useDispatch()

    const listDraftApi = useListDraftQuery();
    useEffect(() => {
        if (listDraftApi.status === 'fulfilled') {
            dispatch(load({ data: listDraftApi.data.Data }))
        }
    }, [listDraftApi.status]);*/


    return (
        <div>test</div>
        /* <div>
             <div>
                 <button
                     aria-label="Increment value"
                     onClick={() => dispatch(incremented())}
                 >
                     Increment
                 </button>
                 <span>{count}</span>
                 <button
                     aria-label="Decrement value"
                     onClick={() => dispatch(decremented())}
                 >
                     Decrement
                 </button>
             </div>
         </div>*/
    )
}

export default Test;