import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//https://habr.com/ru/articles/730916/
export const taskRApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api_main/tracker/taskR/' }),
    tagTypes: [],
    reducerPath: 'TaskRApi',
    endpoints: (builder) => ({
        add: builder.mutation({
            query: (args) => {
                return {
                    url: 'add',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        update: builder.mutation({
            query: (args) => {
                return {
                    url: 'update',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        remove: builder.mutation({
            query: (args) => {
                return {
                    url: 'remove',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        get: builder.mutation({
            query: (args) => {
                return {
                    url: 'get',
                    method: 'GET',
                    params: args,
                };
            },
            //  providesTags: ['defaultType'],       
        }),
        getTemp: builder.mutation({
            query: (args) => {
                return {
                    url: 'getTemp',
                    method: 'GET',
                    params: args,
                };
            },
            //  providesTags: ['defaultType'],       
        }),
        list: builder.query({
            query: (args) => {
                return {
                    url: 'list',
                    method: 'GET',
                    params: args
                };
            },
            //  providesTags: ['defaultType'],       
        }),
        /**************************START ПРОЕКТЫ***************************/
        //Список пользователей, которые будут удалены после изменения проекта в задаче
        projectSetPrepareUser: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectSetPrepareRemoveUserList',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        //Список параметров, которые будут удалены после изменения проекта в задаче
        projectSetPrepareParameter: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectSetPrepareRemoveParameterList',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        projectSet: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectSet',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        /*************************END ПРОЕКТЫ***************************/
        /******************START ПОЛЬЗОВАТЕЛИ ЗАДАЧИ********************/

        userList: builder.mutation({
            query: (args) => {
                return {
                    url: 'userList',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        userAvailableList: builder.query({
            query: (args) => {
                return {
                    url: 'userAvailableList',
                    method: 'GET',
                    params: args
                };
            },
            providesTags: ['userAvailable'],
        }),
        userSet: builder.mutation({
            query: (args) => {
                return {
                    url: 'userSet',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['userAvailable'],
        }),
        userSetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'userSetMass',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['userAvailable'],
        }),
        /******************END ПОЛЬЗОВАТЕЛИ ЗАДАЧИ*********************/
        /*********************START РАСПИСАНИЯ*************************/
        timetableList: builder.mutation({
            query: (args) => {
                return {
                    url: 'timetableList',
                    method: 'GET',
                    params: args
                };
            }
        }),
        timetableAdd: builder.mutation({
            query: (args) => {
                return {
                    url: 'timetableAdd',
                    method: 'POST',
                    body: args
                };
            }
        }),
        timetableUpdate: builder.mutation({
            query: (args) => {
                return {
                    url: 'timetableUpdate',
                    method: 'POST',
                    body: args
                };
            }
        }),
        timetableRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'timetableRemove',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /**********************END РАСПИСАНИЯ**************************/
        /***********************START ТРЕКЕР***************************/
        trackerGet: builder.mutation({
            query: (args) => {
                return {
                    url: 'trackerGet',
                    method: 'GET',
                    params: args
                };
            },
        }),
        trackerListActive: builder.query({
            query: (args) => {
                return {
                    url: 'trackerListActive',
                    method: 'GET',
                    params: args
                };
            },
        }),
        trackerListArchive: builder.query({
            query: (args) => {
                return {
                    url: 'trackerListArchive',
                    method: 'GET',
                    params: args
                };
            },
        }),
        trackerComplete: builder.mutation({
            query: (args) => {
                return {
                    url: 'trackerComplete',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /************************END ТРЕКЕР****************************/
        /**********************START ПАРАМЕТРЫ*************************/
        parameterList: builder.mutation({
            query: (args) => {
                return {
                    url: 'parameterList',
                    method: 'GET',
                    params: args
                };
            }
        }),
        parameterSetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'parameterSetMass',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /***********************END ПАРАМЕТРЫ**************************/
        /************************START ФАЙЛЫ***************************/
        fileUpload: builder.mutation({
            query: (args) => {

                var formData = new FormData();
                formData.append('File', args.File);

                return {
                    url: 'fileUpload',
                    method: 'POST',
                    body: formData,
                    params: { ID_Task: args.ID_TaskR, Name: args.Name },
                    formData: true
                };
            }
        }),
        fileList: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileList',
                    method: 'GET',
                    params: args
                };
            }
        }),
        fileDownload: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileDownload',
                    method: 'GET',
                    params: args,
                    //responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                    responseHandler: async (response) => {
                        console.log(response)


                    }

                };
            }
        }),
        fileRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileRemove',
                    method: 'POST',
                    body: args
                };
            }
        }),
        fileUpdate: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileUpdate',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /*************************END ФАЙЛЫ****************************/
    }),
});

export const { useAddMutation, useUpdateMutation, useRemoveMutation, useGetMutation, useGetTempMutation, useListQuery
    , useProjectSetMutation, useProjectSetPrepareParameterMutation, useProjectSetPrepareUserMutation
    , useUserListMutation, useUserAvailableListQuery, useUserSetMutation, useUserSetMassMutation
    , useTimetableAddMutation, useTimetableUpdateMutation, useTimetableRemoveMutation, useTimetableListMutation
    , useTrackerGetMutation, useTrackerListActiveQuery, useTrackerListArchiveQuery, useTrackerCompleteMutation
    , useParameterListMutation, useParameterSetMassMutation
    , useFileUploadMutation, useFileListMutation, useFileDownloadMutation, useFileRemoveMutation, useFileUpdateMutation
} = taskRApi;