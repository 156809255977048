import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//https://habr.com/ru/articles/730916/
export const taskApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api_main/tracker/task/' }),
    tagTypes: ['defaultType', 'userAvailable'],
    reducerPath: 'TaskApi',
    endpoints: (builder) => ({
        add: builder.mutation({
            query: (args) => {
                return {
                    url: 'add',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        update: builder.mutation({
            query: (args) => {
                return {
                    url: 'update',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        get: builder.query({
            query: (args) => {
                return {
                    url: 'get',
                    method: 'GET',
                    params: args,
                };
            },
            //  providesTags: ['defaultType'],       
        }),     
        getTemp: builder.query({
            query: (args) => {
                return {
                    url: 'getTemp',
                    method: 'GET',
                    params: args,
                };
            },
            //  providesTags: ['defaultType'],       
        }),

        listActive: builder.query({
            query: (args) => {
                return {
                    url: 'listActive',
                    method: 'GET',
                    params: args
                };
            },
        }),
        listCompleted: builder.query({
            query: (args) => {                
                return {
                    url: 'listCompleted',
                    method: 'GET',
                    params: args
                };
            },                
        }),
        /*listCompleted: builder.mutation({
            query: (args) => {
                return {
                    url: 'listCompleted',
                    method: 'GET',
                    body: args,
                };
            }
        }),*/

        listDraft: builder.query({
            query: (args) => {
                return {
                    url: 'listDraft',
                    method: 'GET',
                    params: args
                };
            },
        }),
        listFuture: builder.query({
            query: (args) => {
                return {
                    url: 'listFuture',
                    method: 'GET',
                    params: args
                };
            },
            //  providesTags: ['defaultType'],       
        }),

        priorityList: builder.query({
            query: (args) => {
                return {
                    url: 'priorityList',
                    method: 'GET'
                };
            },
            // providesTags: ['defaultType'],       
        }),
        defaultTypeSet: builder.mutation({
            query: (args) => {
                return {
                    url: 'defaultTypeSet',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['defaultType'],
        }),
        defaultTypeGet: builder.query({
            query: (args) => {
                return {
                    url: 'defaultTypeGet',
                    method: 'GET'
                };
            },
            providesTags: ['defaultType'],
        }),
        hide: builder.mutation({
            query: (args) => {
                return {
                    url: 'hide',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        complete: builder.mutation({
            query: (args) => {
                return {
                    url: 'complete',
                    method: 'POST',
                    body: args,
                };
            },
        }),
        completeCancel: builder.mutation({
            query: (args) => {
                return {
                    url: 'completeCancel',
                    method: 'POST',
                    body: args,
                };
            },
        }),
        completeCheck: builder.query({
            query: (args) => {
                return {
                    url: 'completeCheck',
                    method: 'GET',
                    params: args,
                };
            },
        }),
        /**************************START ПРОЕКТЫ***************************/
        //Список пользователей, которые будут удалены после изменения проекта в задаче
        projectSetPrepareUser: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectSetPrepareRemoveUserList',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        //Список параметров, которые будут удалены после изменения проекта в задаче
        projectSetPrepareParameter: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectSetPrepareRemoveParameterList',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        projectSet: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectSet',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        /*************************END ПРОЕКТЫ***************************/
        /******************START ПОЛЬЗОВАТЕЛИ ЗАДАЧИ********************/

        userList: builder.query({
            query: (args) => {
                return {
                    url: 'userList',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        userAvailableList: builder.query({
            query: (args) => {
                return {
                    url: 'userAvailableList',
                    method: 'GET',
                    params: args
                };
            },
            providesTags: ['userAvailable'],
        }),
        userSet: builder.mutation({
            query: (args) => {
                return {
                    url: 'userSet',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['userAvailable'],
        }),
        userSetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'userSetMass',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['userAvailable'],
        }),
        userComplete: builder.mutation({
            query: (args) => {
                return {
                    url: 'userComplete',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['defaultType'],
        }),
        /******************END ПОЛЬЗОВАТЕЛИ ЗАДАЧИ*********************/
        /**********************START ПАРАМЕТРЫ*************************/
        parameterList: builder.query({
            query: (args) => {
                return {
                    url: 'parameterList',
                    method: 'GET',
                    params: args
                };
            }
        }),
        parameterSetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'parameterSetMass',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /***********************END ПАРАМЕТРЫ**************************/
        /************************START ФАЙЛЫ***************************/
        fileUpload: builder.mutation({
            query: (args) => {

                var formData = new FormData();
                formData.append('File', args.File);

                return {
                    url: 'fileUpload',
                    method: 'POST',
                    body: formData,
                    params: { ID_Task: args.ID_Task, Name: args.Name },
                    formData: true
                };
            }
        }),
        fileList: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileList',
                    method: 'GET',
                    params: args
                };
            }
        }),
        fileDownload: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileDownload',
                    method: 'GET',
                    params: args,
                    //responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                    responseHandler: async (response) => {
                        console.log(response)


                    }

                };
            }
        }),
        fileRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileRemove',
                    method: 'POST',
                    body: args
                };
            }
        }),
        fileUpdate: builder.mutation({
            query: (args) => {
                return {
                    url: 'fileUpdate',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /*************************END ФАЙЛЫ****************************/
        /***********************START ЧЕК-ЛИСТ*************************/
        checklistAdd: builder.mutation({
            query: (args) => {
                return {
                    url: 'checklistAdd',
                    method: 'POST',
                    body: args
                };
            }
        }),
        checklistUpdate: builder.mutation({
            query: (args) => {
                return {
                    url: 'checklistUpdate',
                    method: 'POST',
                    body: args
                };
            }
        }),
        checklistRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'checklistRemove',
                    method: 'POST',
                    body: args
                };
            }
        }),
        checkListList: builder.query({
            query: (args) => {
                return {
                    url: 'checkListList',
                    method: 'GET',
                    params: args
                };
            }
        }),
        checkListGet: builder.query({
            query: (args) => {
                return {
                    url: 'checkListGet',
                    method: 'GET',
                    params: args
                };
            }
        }),
        checkListComplete: builder.mutation({
            query: (args) => {
                return {
                    url: 'checkListComplete',
                    method: 'POST',
                    body: args
                };
            }
        }),
        checkListCompleteCancel: builder.mutation({
            query: (args) => {
                return {
                    url: 'checkListCompleteCancel',
                    method: 'POST',
                    body: args
                };
            }
        }),
        /************************END ЧЕК-ЛИСТ**************************/
    }),
});

export const { useAddMutation, useUpdateMutation, useCompleteMutation, useCompleteCancelMutation, useLazyCompleteCheckQuery
    , useLazyGetQuery, useLazyGetTempQuery
    , usePriorityListQuery, useListActiveQuery, useLazyListCompletedQuery, useListDraftQuery, useListFutureQuery
    , useDefaultTypeSetMutation, useDefaultTypeGetQuery, useHideMutation
    , useLazyUserListQuery, useUserListQuery, useUserAvailableListQuery, useUserCompleteMutation, useUserSetMutation, useUserSetMassMutation
    , useProjectSetMutation, useProjectSetPrepareParameterMutation, useProjectSetPrepareUserMutation
    , useLazyParameterListQuery, useParameterSetMassMutation
    , useFileUploadMutation, useFileListMutation, useFileDownloadMutation, useFileRemoveMutation, useFileUpdateMutation
    , useChecklistAddMutation, useChecklistRemoveMutation, useChecklistUpdateMutation, useCheckListGetQuery, useCheckListListQuery, useLazyCheckListGetQuery, useCheckListCompleteMutation, useCheckListCompleteCancelMutation
} = taskApi;