import { faClock, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ModalTaskR from './ModalTaskR';
import ModalRemove from './ModalRemove';
import UserList from '../UserList';
import DateStartEnd from '../DateStartEnd';


function List({ list }) {


    const [selectedModel, setSelectedModel] = useState({});

    //Модальное окно редактирования
    const [modalTaskShow, setModalTaskShow] = useState(false);
    function modalAddHandler(model) {
        setSelectedModel(model);
        setModalTaskShow(true);
    }

    //Модальное окно удаления
    const [modalRemoveShow, setModalRemoveShow] = useState(false);
    function modalRemoveHandler(model) {
        setSelectedModel(model);
        setModalRemoveShow(true);
    }

    function CheckRemove(date_end) {
        if (date_end === null) {
            return 1;
        }
        else {
            const d1 = new Date(date_end);
            const d2 = new Date();

            if (d1 >= d2) {
                return 1;
            }
            else {
                return 0;
            }
        }
    }

    return (
        <>
            <ModalTaskR show={modalTaskShow} onHide={() => setModalTaskShow(false)} edit={true} model={selectedModel} />
            <ModalRemove show={modalRemoveShow} onHide={() => setModalRemoveShow(false)} model_item={selectedModel} />


                <div>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            {list.map((model) => (
                                    <tr className='row_select' key={model.ID}>
                                        <td><div className='task_list_name'>{'#' + model.ID + ' ' + model.Name}</div>
                                            {model.Name_Project}<br></br>
                                            <small><DateStartEnd date_start={model.Date_Start} date_end={model.Date_End} /></small>
                                        </td>                                        
                                        <td>{model.Name_Type}</td>
                                        <td>
                                            {
                                                <UserList list={model.Users} />
                                            }
                                        </td>
                                        <td>
                                            {model.Timetables.length === 0 ?
                                                    (<div className='form_error'>Нет расписания</div>)
                                                    :
                                                    (
                                                        model.Timetables.map((t) => (
                                                            <div key={t.ID}>
                                                                <div key={t.ID}><i className="mr-5"><FontAwesomeIcon icon={faClock} /></i>{t.Timetable_String}</div>
                                                                {t.Day_Complite !== null && (
                                                                    <div className="widget-subheading"><i>{t.Day_Complite} дн. для решения</i></div>
                                                                )}
                                                            </div>
                                                        ))
                                                )
                                            }
                                        </td>
                                        <td className='text-end'>
                                            <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => modalAddHandler(model)} >
                                                <i>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </i>
                                            </button>
                                            {CheckRemove(model.Date_End) === 1 && (
                                                <button className="btn-square btn-hover-shine btn btn-secondary ml-5" onClick={() => modalRemoveHandler(model)} >
                                                <i>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </i>
                                            </button>
                                            )}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >

        </>
    );
}

export default List;