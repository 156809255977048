import { useEffect, useState } from "react";


function FilterProject({ list, clickHandler }) {

    /*
    ФОРМАТ list:
            id - ИД проекта
            name - Название проекта
            count - Кол-во элементов в проекте
    */

    const [listData, setListData] = useState([]);

    //Событие изменения фильтра
    function clickHandler_(id) {
        UpdateList(id);
        clickHandler(id);
    }

    useEffect(() => {
        UpdateList(0)       
    }, [list]);



    function UpdateList(select) {
        const nextList = list.map((item, i) => {
            if (item.id === select) {
                return { ...item, selected: true };
            }
            else {
                return { ...item, selected: false };
            }
        });

        setListData(nextList);
    }

    return (
        <div>
            {
                listData.filter(a => a.count > 0).map((model) => (                  
                    <button
                        key={model.id}
                        onClick={() => clickHandler_(model.id)}
                        className={model.selected === true ? ("mr-15 mt-15 btn-pill btn btn-dashed btn-outline-info active") : ("mr-15 mt-15 btn-pill btn btn-dashed btn-outline-info")}>
                        {model.name} ({model.count})
                    </button>
                ))
            }
        </div>
    )
}

export default FilterProject;