import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import StringToInputDate from '../../../util/StringToInputDate';
import StringToDate from '../../../util/StringToDate';

////Отобразит Дату начала/завершения задачи
function DateStartEnd({ date_start, date_end }) {

    if (date_start !== null & date_end !== null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />{StringToInputDate(date_start)} - {StringToDate(date_end)}</>)
    }
    else if (date_start !== null & date_end === null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />С {StringToDate(date_start)}</>)
    }
    else if (date_start === null & date_end !== null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' /> До {StringToDate(date_end)}</>)
    }
    else {
        return null;
    }
}

export default DateStartEnd;


