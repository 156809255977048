import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useProjectDisplayRemoveMutation } from "../../../api/tracker/parameter";


function DesignerParamModalRemove(props) {
    const { show, onHide, selectedParam, listParam } = props;

    const [param, setParam] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
            setLoading(false)
        }
    }, [show]);


    useEffect(() => {
        if (selectedParam === null) {
            setParam([]);
        }
        else {
            setParam(selectedParam);
        }
    }, [selectedParam]);

    const [remove, { data: removeData, status: removeStatus }] = useProjectDisplayRemoveMutation();

    function removeHandler(id_display, id_parameter, id_project) {
        setLoading(true);
        remove({ ID_Display: id_display, ID_Parameter: id_parameter, ID_Project: id_project })
    }

    useEffect(() => {
        if (removeStatus === 'fulfilled') {
            setLoading(false);
            if (removeData.Status === 1) {
                listParam.refetch();
                onHide();
            }
            else {
                setError(removeData.Error)
            }
        }
        if (removeStatus === 'rejected') {
            setLoading(false);
            setError('Неизвестная ошибка')
        }
    }, [removeStatus]);



    return (
        <>
            {param.length > 0 && (
                <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                            <div><i className="mr-5"><FontAwesomeIcon icon={faTrash} /></i> Удаление параметра</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        Подтвердите удаление параметра <b>«{param[0].Name_Parameter}»</b> <br></br>  из области <b>«{param[0].Name_Display}»</b>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                            onClick={() => removeHandler(param[0].ID_Display, param[0].ID_Parameter, param[0].ID_Project)}
                        disabled={loading}
                    >
                        {loading ? (
                            "Загрузка..."
                        ) : (
                            <div><FontAwesomeIcon icon={faTrash} className="mr-5" /> Удалить</div>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
            )}
        </>
    );
}
export default DesignerParamModalRemove;
