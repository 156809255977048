import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { ValidateForm } from "../../../util/ValidateForm";
import { useEffect, useState } from "react";
import FormToJson from "../../../util/FormToJson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRoleListQuery } from '../../../api/main/service';
import { CompanyHubAddRequest } from "../../../SignalR/CompanyHub";
import { useUserRequestAddMutation, useUserRequestOutListQuery } from "../../../api/main/company";

function RequestModalAdd(props) {
  const { show, onHide } = props;

  const [validErrors, setValidErrors] = useState([]);
  const [reqErrors, setReqErrors] = useState('');

  //API
  const [add, { isError: addIsError, isFetching: addIsFetching, isLoading: addIsLoading, data: addData, status: addStatus }] = useUserRequestAddMutation();
  const { status: statusRoleList, data: roleList } = useRoleListQuery({ ID_Service: 2 });
  const requestOutList = useUserRequestOutListQuery();

  //Обработка запроса API
  useEffect(() => {
    if (addStatus === 'fulfilled') {

      if (addData.Status == 1) {
        onHide();
        requestOutList.refetch();
        CompanyHubAddRequest({ id_request: addData.Data })
      }
      else {
        setReqErrors(addData.Error)
      }
    }
  }, [addStatus]);

  //Отправка формы
  const submit = (e) => {
    e.preventDefault();
    setReqErrors('');

    var validForm = ValidateForm(e.target, setValidErrors);

    if (validForm) {
      add(FormToJson(e.target));
    }
  };


  return (
    <>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <>
              <FontAwesomeIcon icon={faPlus} /> Добавление пользователя
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="my-form" onSubmit={submit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Имя пользователя</Form.Label>
              <Form.Control name="user_name" type="text" valid='[{"id": "empty"}]' disabled={addIsLoading} />
              {validErrors.find((el) => el.name === "user_name") !== undefined && (
                <div className="form_error">
                  {validErrors.find((el) => el.name === "user_name").error}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Номер телефона или электронная почта</Form.Label>
              <Form.Control name="value_contact" type="text" valid='[{"id": "empty"}]' disabled={addIsLoading} />
              {validErrors.find((el) => el.name === "value_contact") !== undefined && (
                <div className="form_error">
                  {validErrors.find((el) => el.name === "value_contact").error}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Роль в компании</Form.Label>
              <Form.Select aria-label="Default select example" defaultValue={2} name="id_role">
                {statusRoleList === 'fulfilled' &&
                  roleList.Data.map((model) => (
                    <option key={model.ID_Role} value={model.ID_Role}>
                      {model.Name_Role}
                    </option>
                  ))}
              </Form.Select>
              {validErrors.find((el) => el.name === 'description') !== undefined && validErrors.find((el) => el.name === 'description').error}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Описание</Form.Label>
              <Form.Control as="textarea" rows={3} name="user_description" disabled={addIsLoading} />
            </Form.Group>
          </Form>
          {addIsError == true && (
            <div className="form_error">
              Неизвестная ошибка! Обратитесь в техническую поддержку
            </div>
          )}
          {reqErrors != '' && (
            <div className="form_error">
              {reqErrors}
            </div>
          )}

        </Modal.Body>
        <Modal.Footer>
          <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
            <FontAwesomeIcon icon={faXmark} className="mr-5" />
            Закрыть
          </button>
          <button
            className="mr-5 btn-square btn-hover-shine btn btn-secondary"
            form="my-form"
            type="submit"
            disabled={addIsLoading}
          >
            {addIsLoading ? (
              "Загрузка..."
            ) : (
              <>
                <FontAwesomeIcon icon={faPlus} className="mr-5" /> Добавить пользователя
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default RequestModalAdd;
