import { Form } from "react-bootstrap";

function MonthInput({ defaultValue, change, label }) {

    return (
        <Form.Group key={defaultValue}>
            <Form.Label>{label}</Form.Label>
            <Form.Select key={Math.random()} defaultValue={defaultValue} onChange={change}>
                <option value={-1}>Не выбрано</option>
                <option value={1}>Январь</option>
                <option value={2}>Февраль</option>
                <option value={3}>Март</option>
                <option value={4}>Апрель</option>
                <option value={5}>Май</option>
                <option value={6}>Июнь</option>
                <option value={7}>Июль</option>
                <option value={8}>Август</option>
                <option value={9}>Сентябрь</option>
                <option value={10}>Октябрь</option>
                <option value={11}>Ноябрь</option>
                <option value={12}>Декабрь</option>
            </Form.Select>
        </Form.Group>
    )
}

export default MonthInput;