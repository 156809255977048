import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import RequestModalRemove from './RequestModalRemove';

function RequestList({ data }) {  
    //Модальные окна
    const [modalRemoveShow, setModalRemoveShow] = useState(false);
    const [modelRemove, setModelRemove] = useState([]);
 
    function modalRemoveShowEvent(model){
     setModalRemoveShow(true)
     setModelRemove(model)
    }

  return (
    <>    
      <RequestModalRemove show={modalRemoveShow} onHide={() => setModalRemoveShow(false)} model={modelRemove}/>
      {data.isFetching === true || data.status === 'rejected' ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} size="2x" />
        </div>
      ) : (
        <div>        
          <div className="table-responsive">
            <table className="table">
            <thead className='thead_blue'>
                <tr>
                    <th>Имя</th>  
                    <th>Почта/Телефон</th>    
                    <th>Роль</th>  
                   
                    <th></th>  
                </tr>
            </thead>
            <tbody>
            {data.data.Data.map((model) => (
                <tr className='row_select' key={model.ID_User}>                
                    <td>{model.User_Name}<br></br>
                    <small>{new Date(model.Date_Add).toLocaleDateString()}</small>
                    
                    </td>
                    <td>{model.Value_Contact}</td>                    
                    <td>{model.Name_Role}</td>
                  
                    <td className='text-end'>
                        <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => modalRemoveShowEvent(model)}>
                          <i className="mr-5">
                            <FontAwesomeIcon icon={faTrash} />
                          </i>
                          Удалить
                        </button>                  
                    </td>                  
                </tr>
            ))}
            </tbody>
            </table>        
        </div>
        </div>
        
      )}
    </>
  );
}

export default RequestList;
