import { faCheck, faSpinner, faTrash, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useListQuery } from "../../../api/tracker/project";
import SelectSearch from "../../../components/ui/SelectSearch";
import DesignerTask from "./DesignerTask";


function DesignerProjectModal(props) {
    const { show, onHide, id_owner, params_all } = props;

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
        }
    }, [show]);

    //МОДАЛЬНЫЕ ОКНА
    const [modalTaskShow, setModalTaskShow] = useState(false);

    //ПЕРЕМЕННЫЕ
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingProject, setLoadingProject] = useState(true);
    const [selectProject, setSelectProject] = useState([]);

    const [error, setError] = useState(null);

    const listProjectApi = useListQuery();
    const [listProject, setListProject] = useState([]);

    useEffect(() => {
        if (listProjectApi.status === 'fulfilled') {
            setLoadingProject(false);

            const jsonForm = [];

            if (id_owner === 1) {
                for (let i = 0; i < listProjectApi.data.Data.filter(a => a.ID_Status === 1 & a.Use_Tasks === true).length; i++) {
                    jsonForm.push(
                        {
                            value: listProjectApi.data.Data[i].ID,
                            label: listProjectApi.data.Data[i].Name_Full,
                        })
                }
            }
            if (id_owner === 2) {
                for (let i = 0; i < listProjectApi.data.Data.filter(a => a.ID_Status === 1 & a.Use_Rtasks === true).length; i++) {
                    jsonForm.push(
                        {
                            value: listProjectApi.data.Data[i].ID,
                            label: listProjectApi.data.Data[i].Name_Full,
                        })
                }
            }
            if (id_owner === 3) {
                for (let i = 0; i < listProjectApi.data.Data.filter(a => a.ID_Status === 1 & a.Use_Boards === true).length; i++) {
                    jsonForm.push(
                        {
                            value: listProjectApi.data.Data[i].ID,
                            label: listProjectApi.data.Data[i].Name_Full,
                        })
                }
            }
            if (id_owner === 4) {
                for (let i = 0; i < listProjectApi.data.Data.filter(a => a.ID_Status === 1 & a.Use_Rboards === true).length; i++) {
                    jsonForm.push(
                        {
                            value: listProjectApi.data.Data[i].ID,
                            label: listProjectApi.data.Data[i].Name_Full,
                        })
                }
            }

            setListProject(jsonForm);
        }
    }, [listProjectApi.status]);

    function handlerChange() {
        setLoadingSave(true);

        if (selectProject.length === 0) {
            setError('Выберите проект!');
            setLoadingSave(false);
        }
        else {
            if (id_owner === 1) {
                setModalTaskShow(true)
            }

            setLoadingSave(false);
            onHide();
        }
    }


    return (
        <>
            <DesignerTask show={modalTaskShow} onHide={() => setModalTaskShow(false)} id_owner={id_owner} id_project={selectProject.value} params_all={params_all} />

            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div><i className="mr-5"><FontAwesomeIcon icon={faCheck} /></i> Выберите проект</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                        <Form.Label>Для продолжения выберите проект</Form.Label>
                        <SelectSearch name="ID_Project" placeholder='Выберите проект' className="z-index-max" disabled={loadingProject} options={listProject} defaultValue={selectProject} onChange={(e) => setSelectProject(e)} />
                        {loadingProject === true && (
                            <FontAwesomeIcon icon={faSpinner} size="2x" />
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                        onClick={() => handlerChange()}
                        disabled={loadingSave}
                    >
                        {loadingSave ? (
                            "Загрузка..."
                        ) : (
                            <div><FontAwesomeIcon icon={faWandMagicSparkles} className="mr-5" /> Дизайнер</div>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DesignerProjectModal;
