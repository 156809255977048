import { faEdit, faSave, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFileUpdateMutation as useTaskFileUpdateMutation } from "../../api/tracker/task";
import { useFileUpdateMutation as useTaskRFileUpdateMutation } from "../../api/tracker/taskR";

function FileListModalEdit(props) {
    const { show, onHide, type, model, listDispatch } = props;
    //Type: 1-Задача; 2-Регулярная задача

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setError(null);
            setLoading(false);
        }
    }, [show]);


    const [updateType1Api, { data: updateType1ApiData, status: updateType1ApiStatus }] = useTaskFileUpdateMutation();
    const [updateType2Api, { data: updateType2ApiData, status: updateType2ApiStatus }] = useTaskRFileUpdateMutation();

    useEffect(() => {
        if (updateType1ApiStatus === 'fulfilled') {
            if (updateType1ApiData.Status == 1) {
                listDispatch({ type: 'edit', id: model.ID, name: name });
                onHide();
            }
            else {
                setError(updateType1ApiData.Error);
                setLoading(false);
            }
        }
        if (updateType1ApiStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [updateType1ApiStatus]);


    useEffect(() => {
        if (updateType2ApiStatus === 'fulfilled') {
            if (updateType2ApiData.Status == 1) {
                listDispatch({ type: 'edit', id: model.ID, name: name });
                onHide();
            }
            else {
                setError(updateType2ApiData.Error);
                setLoading(false);
            }
        }
        if (updateType2ApiStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [updateType2ApiStatus]);



    function editHandler() {
        if (name !== null & name !== model.Name) {
            if (type === 1) {
                updateType1Api({ ID: model.ID, Name: name });
            }
            if (type === 2) {
                updateType2Api({ ID: model.ID, Name: name });
            }
        }
        else {
            onHide();
        }

        setLoading(false);
    }

    return (
        <Modal show={show} onHide={onHide} size="md">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <>
                        <FontAwesomeIcon icon={faEdit} /> Изменение файла
                    </>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {model !== null && (
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Название</Form.Label>
                        <Form.Control name="Name" type="text" disabled={loading} defaultValue={model.Name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                )}
                {error != null && (
                    <div className="form_error text-center">
                        {error}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                    type="submit"
                    disabled={loading}
                    onClick={() => editHandler()}>
                    {loading ?
                        (
                            "Загрузка..."
                        )
                        :
                        (
                            <>
                                <FontAwesomeIcon icon={faSave} className="mr-5" /> Сохранить изменения
                            </>
                        )
                    }
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default FileListModalEdit;