import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <Container fluid className="footer mt-5">
      <Row>
        <Col>
          <ul>
            <li>
              <NavLink to="/">Инструкция</NavLink>{" "}
            </li>
            <li>
              <NavLink to="/">API Для разработчиков</NavLink>
            </li>
            <li>
              <NavLink to="/">Сотрудничество</NavLink>
            </li>
            <li>
              <NavLink to="/">Тарифы</NavLink>
            </li>
            <li>
              <NavLink to="/">О компании</NavLink>
            </li>
          </ul>
        </Col>
        <Col>СДЕЛАНО В РОССИИ</Col>
      </Row>
    </Container>
  );
}

export default Footer;
