import { faArrowUpRightFromSquare, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";

function BlockPassword() {
    return (
        <>

        </>
    )
}
export default BlockPassword;