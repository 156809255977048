import { faAngleDown, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import {DropdownToggle, DropdownMenu, Nav, Button, NavItem, NavLink, UncontrolledTooltip, UncontrolledButtonDropdown} from 'reactstrap';
import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
import { useInfoGetQuery } from "../../../api/main/user";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

function UserInfo() {


    


    const userInfo = useInfoGetQuery();

    const [name, setName] = useState('');
    const [roleName, setRoleName] = useState('');

    useEffect(() => {   
        if(userInfo.status === 'fulfilled'){   
              setName(userInfo.data.Data.Name)
              setRoleName(userInfo.data.Data.Company_Name_Role)          
        }
      }, [userInfo.status]);


    return (
        <>
             <Fragment>
                <div className="header-btn-lg pe-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                        <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                                        <Nav vertical>
                                            <NavItem className="nav-item-header">
                                                Activity
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">
                                                    Chat
                                                    <div className="ms-auto badge bg-pill bg-info">8</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">Recover Password</NavLink>
                                            </NavItem>
                                            <NavItem className="nav-item-header">
                                                My Account
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">
                                                    Settings
                                                    <div className="ms-auto badge bg-success">New</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">
                                                    Messages
                                                    <div className="ms-auto badge bg-warning">512</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#">
                                                    Logs
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ms-3 header-user-info">
                                <div className="widget-heading">
                                    {name}
                                </div>
                                <div className="widget-subheading">
                                     {roleName}
                                </div>
                            </div>
                            <div className="widget-content-right header-user-info ms-3">
                                <Button className="btn-shadow p-1" size="sm" /*onClick={this.notify2}*/ color="info"
                                        id="Tooltip-1">
                                    <FontAwesomeIcon className="me-2 ms-2" icon={faCalendarAlt}/>
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={'Tooltip-1'}>
                                    Click for Toastify Notifications!
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )
}

export default UserInfo;