import { faCalendar, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer, useState } from 'react';
import ModalTask from './ModalTask';
import StringToDateTime from '../../../util/StringToDateTime';
import StringToDate from '../../../util/StringToDate';
import { useLazyCompleteCheckQuery, useLazyGetQuery } from '../../../api/tracker/task';

//Отобразит Дату начала/завершения задачи
function ShowDateStartEnd({ date_start, date_end }) {

    if (date_start !== null & date_end !== null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />{StringToDate(date_start)} - {StringToDate(date_end)}</>)
    }
    else if (date_start !== null & date_end === null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />С {StringToDate(date_start)}</>)
    }
    else if (date_start === null & date_end !== null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' /> До {StringToDate(date_end)}</>)
    }
    else {
        return null;
    }
}

function List({ list, isLoading, listCompleteApi }) {

    useEffect(() => {
        listDispatch({ type: 'load', data: list });
    }, [list]);

    const [listState, listDispatch] = useReducer(listReducer, { data: [] });
    function listReducer(state, action) {
        switch (action.type) {
            case 'load': {
                const newState = action.data.map(obj => {
                    return { ...obj, loading: false, error: null };
                })
                return { data: newState }
            }
            case 'loadingSet': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, loading: action.val };
                    }
                    else {
                        return { ...obj }
                    }
                })
                return { data: newState }
            }
            case 'errorSet': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, error: action.val };
                    }
                    else {
                        return { ...obj }
                    }
                })
                return { data: newState }
            }
        }
    }

    /******************************START МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЕ************************************/
    const [modalTask1Show, setModalTask1Show] = useState(false);

    const [getApi, getApiResult] = useLazyGetQuery();
    const [completeCheckApi, completeCheckApiResult] = useLazyCompleteCheckQuery();
    const [modelTask, setModelTask] = useState(null);
    const [completeCheck, setcCmpleteCheck] = useState(null);

    function modalEditHandler(id) {
        console.log('EDIT')
        listDispatch({ type: 'loadingSet', id: id, val: true });
        getApi({ ID: id })
            .unwrap()
            .then(res => {
                setModelTask(res.Data);
                completeCheckApi({ ID_Task: res.ID })
                    .unwrap()
                    .then(res1 => {
                        setcCmpleteCheck(res1);
                        listDispatch({ type: 'loadingSet', id: id, val: false });
                        setModalTask1Show(true);                        
                    });
            });       
    }
    /*******************************END МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЕ*************************************/


    return (
        <>
            {modelTask !== null && <ModalTask key={2} show={modalTask1Show} onHideTask={() => setModalTask1Show(false)} edit={true} listCompleteApi={listCompleteApi} model={modelTask} completeCheck={completeCheck} />}

            {isLoading === true ? (
                <div>
                    <FontAwesomeIcon icon={faSpinner} size="2x" />
                </div>
            ) : (
                <div>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                    {listState.data.map((model) => (
                                    <tr className='row_select' key={model.ID}>
                                        <td width={50}>
                                            {model.ID_Priority === 1 && (<span className="badge badge-primary badge-dot badge-dot-lg"> </span>)}
                                            {model.ID_Priority === 2 && (<span className="badge badge-secondary badge-dot badge-dot-lg"> </span>)}
                                            {model.ID_Priority === 3 && (<span className="badge badge-danger badge-dot badge-dot-lg"> </span>)}
                                        </td>
                                        <td><div className='task_list_name'>{'#' + model.ID + ' ' + model.Name}</div>
                                            {model.Name_Project}<br></br>
                                            <small>{StringToDateTime(model.Date_Add)}</small>
                                        </td>
                                        <td>
                                            <ShowDateStartEnd date_start={model.Date_Start} date_end={model.Date_End} />
                                        </td>
                                        <td></td>
                                        <td className='text-end'>
                                                <button
                                                    className="btn-square btn-hover-shine btn btn-secondary"
                                                    onClick={() => modalEditHandler(model.ID)}
                                                    disabled={model.loading}
                                                >
                                                    {model.loading === false ?
                                                        (<FontAwesomeIcon icon={faEdit} />)
                                                        :
                                                        (<>...</>)
                                                    }
                                            </button>
                                                {model.error !== null && (
                                                    <div className="form_error">{model.error}</div>
                                                )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    </div >
            )
            }
        </>
    );
}

export default List;