import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useCheckListListQuery, useChecklistRemoveMutation } from "../../../../api/tracker/task";


function ModalRemove({ show, onHide, model }) {

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);

    const checkListApi = useCheckListListQuery({ ID_Task: model.ID_Task });
    const [remove, { data: removeData, status: removeStatus }] = useChecklistRemoveMutation();

    function removeHandler() {
        setLoading(true);
        setLog(null);

        remove({ ID: model.ID })
            .unwrap()
            .then((res) => {
                if (res.Status === 1) {
                    checkListApi.refetch();
                    onHide();
                    setLoading(false);
                }
                else {
                    setLog(res.Error);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLog('Неизвестная ошибка!');
                setLoading(false);
            })
    }


    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <i className="mr-10"><FontAwesomeIcon icon={faTrash} /></i>Удаление задачи из чек-листа
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Подтвердите удаление задачи: <br /> <b>«{model.Name}»</b>
                    {log != null && <div className="form_error text-center">{log}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-danger"
                        disabled={loading}
                        onClick={() => removeHandler()}
                    >
                        {loading ? (
                            "Удаление..."
                        ) : (
                            <>
                                    <FontAwesomeIcon icon={faTrash} className="mr-5" /> Удалить
                            </>
                        )}
                    </button>


                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalRemove;
