import { useState } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faLocationPin, faUserGear, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons';

function Index() {

  const [menuShow, setMenuShow] = useState(false);

  return (
    <div >
      <div className="row" style={{ height: "86vh" }}>
        <div className='col-md-3' >
          <div className="main-card mb-3 card" style={{ height: "100%" }}>
            <div className="card-header">Header

              <div className="btn-actions-pane-right actions-icon-btn">
                <div className={menuShow === true ? "btn-group dropdown show" : "btn-group dropdown"}>
                  <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded={menuShow} className="btn-icon btn-icon-only btn btn-link" onClick={() => setMenuShow(!menuShow)}>
                    <i className="pe-7s-menu btn-icon-wrapper"></i>
                  </button>
                  <div tabIndex="-1" role="menu" aria-hidden="true" className={menuShow === true ? "dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu show menu_position_correct" : "dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu"} >
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faUserGroup} className='mr-10' />
                      <span>Личное сообщение</span>
                    </button>
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faUsers} className='mr-10' />
                      <span>Беседа</span>
                    </button>
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faUserGear} className='mr-10' />
                      <span>Техническая поддержка</span>
                    </button>
                    <div tabIndex="-1" className="dropdown-divider"></div>
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faAngleRight} className='mr-10' />
                      <span>Задача</span>
                    </button>
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faAngleRight} className='mr-10' />
                      <span>Регулярная задача</span>
                    </button>
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faAngleRight} className='mr-10' />
                      <span>Доска задача</span>
                    </button>
                    <button type="button" tabIndex="0" className="dropdown-item">
                      <FontAwesomeIcon icon={faAngleRight} className='mr-10' />
                      <span>Регулярная доска</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: "0px" }}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left mr-3">
                        1
                      </div>
                      <div className="widget-content-left">
                        <div className="widget-heading">Alina Mcloughlin</div>
                        <div className="widget-subheading">A short profile description</div>
                      </div>
                      <div className="widget-content-right">

                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="d-block text-right card-footer">
              <button className="mr-2 btn btn-link btn-sm">Cancel</button>
              <button className="btn btn-success btn-lg">Save</button>
            </div>
          </div>
        </div>
        <div className='col-md-9'>
          <div className="main-card mb-3 card" style={{ height: "100%" }}>
            <div className="card-header"></div>
            <div className="card-body" >
              123
            </div>
            <div className="d-block text-right card-footer">
              <button className="mr-2 btn btn-link btn-sm">Cancel</button>
              <button className="btn btn-success btn-lg">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
