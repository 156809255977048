import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { ValidateForm } from "../../../util/ValidateForm";
import { useEffect, useState } from "react";
import FormToJson from "../../../util/FormToJson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRoleListQuery } from '../../../api/main/service';
import { useAddMutation, useListQuery } from "../../../api/tracker/project";


function ModalAdd(props) {
    const { show, onHide } = props;

    const [validErrors, setValidErrors] = useState([]);
    const [reqErrors, setReqErrors] = useState('');

    //switchs
    const [checkboxTasks, setCheckboxTasks] = useState(true);
    const [checkboxRtasks, setCheckboxRtasks] = useState(true);
    const [checkboxBoards, setCheckboxBoards] = useState(true);
    const [checkboxRboards, setCheckboxRboards] = useState(true);

    const checkboxTasksHandler = () => {
        setCheckboxTasks(!checkboxTasks)
    }
    const checkboxRtasksHandler = () => {
        setCheckboxRtasks(!checkboxRtasks)
    }
    const checkboxBoardsHandler = () => {
        setCheckboxBoards(!checkboxBoards)
    }
    const checkboxRboardsHandler = () => {
        setCheckboxRboards(!checkboxRboards)
    }

    //API
    const [add, { isError: addIsError, isFetching: addIsFetching, isLoading: addIsLoading, data: addData, status: addStatus }] = useAddMutation();
    //const { status: statusRoleList, data: roleList } = useRoleListQuery({ ID_Service: 2 });
    const listProject = useListQuery();

    //Обработка запроса API
    useEffect(() => {
        if (addStatus === 'fulfilled') {

            if (addData.Status == 1) {
                const ID_Project = addData.Data;
                onHide();
            }
            else {
                setReqErrors(addData.Error)
            }
        }
    }, [addStatus]);

    //Отправка формы
    const submit = (e) => {
        e.preventDefault();
        setReqErrors('');

        var validForm = ValidateForm(e.target, setValidErrors);
        if (validForm) {
            const form = FormToJson(e.target);
            form['ID_Status'] = 1;
            form['Order'] = 0;
            form['Use_Tasks'] = checkboxTasks;
            form['Use_Rtasks'] = checkboxRtasks;
            form['Use_Boards'] = checkboxBoards;
            form['Use_Rboards'] = checkboxRboards;

            if (form.ID_Parent === '') {
                form['ID_Parent'] = null
            }
            add(form);
        }
    };


    return (
        <>
            <Modal show={show} onHide={onHide} size="md">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <FontAwesomeIcon icon={faPlus} /> Добавление проекта
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="my-form" onSubmit={submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Название</Form.Label>
                            <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' disabled={addIsLoading} />
                            {validErrors.find((el) => el.name === "Name") !== undefined && (
                                <div className="form_error">
                                    {validErrors.find((el) => el.name === "Name").error}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Описание</Form.Label>
                            <Form.Control as="textarea" rows={3} name="Description" disabled={addIsLoading} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Родительский проект</Form.Label>
                            <Form.Select aria-label="Default select example" defaultValue={null} name="ID_Parent">
                                <option value="">Не выбрано</option>
                                {listProject.status === 'fulfilled' &&
                                    listProject.data.Data.filter(a => a.ID_Status === 1).map((model) => (
                                        <option
                                            key={model.ID}
                                            value={model.ID}
                                        >
                                            {model.Name_Full}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Check
                            type="switch"
                            id="custom-switch1"
                            label="Использовать в задачах"
                            name="Use_Tasks"
                            defaultChecked={true}
                            value={checkboxTasks}
                            onChange={checkboxTasksHandler}
                        />
                        <Form.Check
                            type="switch"
                            id="custom-switch2"
                            label="Использовать в регулярных задачах"
                            name="Use_Rtasks"
                            defaultChecked={true}
                            value={checkboxRtasks}
                            onChange={checkboxRtasksHandler}
                        />
                        <Form.Check
                            type="switch"
                            id="custom-switch3"
                            label="Использовать в досках"
                            name="Use_Boards"
                            defaultChecked={true}
                            value={checkboxBoards}
                            onChange={checkboxBoardsHandler}
                        />
                        <Form.Check
                            type="switch"
                            id="custom-switch4"
                            label="Использовать в регулярных досках"
                            name="Use_Rboards"
                            defaultChecked={true}
                            value={checkboxRboards}
                            onChange={checkboxRboardsHandler}
                        />
                    </Form>

                    {addIsError == true && (
                        <div className="form_error text-center">
                            Неизвестная ошибка! Обратитесь в техническую поддержку
                        </div>
                    )}
                    {reqErrors != '' && (
                        <div className="form_error text-center">
                            {reqErrors}
                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        form="my-form"
                        type="submit"
                        disabled={addIsLoading}
                    >
                        {addIsLoading ? (
                            "Загрузка..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faPlus} className="mr-5" /> Добавить проект
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalAdd;
