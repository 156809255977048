import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { ValidateForm } from "../../../util/ValidateForm";
import { useEffect, useState } from "react";
import FormToJson from "../../../util/FormToJson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faEdit, faFloppyDisk, faHouse, faUsers, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLazyUserListQuery, useListQuery, useUpdateMutation, useUserListMutation, useUserSetMassMutation } from "../../../api/tracker/project";



function ModalEdit(props) {
    const { show, onHide } = props;
    const { ID, ID_Status, Name, Description, ID_Parent, Use_Tasks, Use_Rtasks, Use_Boards, Use_Rboards } = props.model;


    useEffect(() => {
        //открытие
        if (show === true) {
            userListApi({ ID_Project: ID });
            setCheckboxTasks(Use_Tasks);
            setCheckboxRtasks(Use_Rtasks);
            setCheckboxBoards(Use_Boards);
            setCheckboxRboards(Use_Rboards);
        }
        //Действия при закрытии модального окна
        if (show == false) {
            setUsers([]);
            setLoading(false);
            setReqErrors('')
        }
    }, [show]);


    //START TAB
    const [tab01Show, setTab01Show] = useState(true);
    const [tab02Show, setTab02Show] = useState(false);
    const [tab03Show, setTab03Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_01") {
            setTab01Show(true);
            setTab02Show(false);
            setTab03Show(false);
        }
        if (e.currentTarget.id === "tab_02") {
            setTab01Show(false);
            setTab02Show(true);
            setTab03Show(false);
        }
        if (e.currentTarget.id === "tab_03") {
            setTab01Show(false);
            setTab02Show(false);
            setTab03Show(true);
        }
    }
    //END TAB

    /***************************START ВКЛАДКА "ОСНОВНОЕ****************************/
    const [validErrors, setValidErrors] = useState([]);
    const [reqErrors, setReqErrors] = useState('');   

    const [checkboxTasks, setCheckboxTasks] = useState(Use_Tasks);
    const [checkboxRtasks, setCheckboxRtasks] = useState(Use_Rtasks);
    const [checkboxBoards, setCheckboxBoards] = useState(Use_Boards);
    const [checkboxRboards, setCheckboxRboards] = useState(Use_Rboards);

    const [loading, setLoading] = useState(false);


    const checkboxTasksHandler = (e) => {
        setCheckboxTasks(e.target.checked);
    }
    const checkboxRtasksHandler = (e) => {
        setCheckboxRtasks(e.target.checked);
    }
    const checkboxBoardsHandler = (e) => {
        setCheckboxBoards(e.target.checked);
    }
    const checkboxRboardsHandler = (e) => {
        setCheckboxRboards(e.target.checked);
    }

    //END Switch


    const listProject = useListQuery();
    //API Обновление проекта
    const [update, { isError: updateIsError, isFetching: updateIsFetching, isLoading: updateIsLoading, data: updateData, status: updateStatus }] = useUpdateMutation();
    const [userSetMass, { isError: userSetMassIsError, isFetching: userSetMassIsFetching, isLoading: userSetMassIsLoading, data: userSetMassData, status: userSetMassStatus }] = useUserSetMassMutation();

    //Отправка формы
    const submit = (e) => {
        e.preventDefault();
        setReqErrors('');
        setLoading(true);

        var validForm = ValidateForm(e.target, setValidErrors);

        if (validForm) {
            var jsonForm = FormToJson(e.target);
            jsonForm['Use_Tasks'] = checkboxTasks;
            jsonForm['Use_Rtasks'] = checkboxRtasks;
            jsonForm['Use_Boards'] = checkboxBoards;
            jsonForm['Use_Rboards'] = checkboxRboards;            

            update(jsonForm);
        }
        else {
            setLoading(false);
        }
    };

    //1 ШАГ. Обработка запроса API
    useEffect(() => {
        if (updateStatus === 'fulfilled') {

            if (updateData.Status == 1) {
                //onHide();

                const listUsers = users.map((user, i) => {
                    return { ID_Project: ID, ID_User: user.ID_User, Value: user.Added };
                });


                userSetMass(listUsers);
            }
            else {
                setReqErrors(updateData.Error);
                setLoading(false);
            }
        }
        if (updateStatus === 'rejected') {
            setLoading(false);
            setReqErrors('Неизвестная ошибка!');
        }
    }, [updateStatus]);
    //2 ШАГ. Сохранение пользователей
    useEffect(() => {
        if (userSetMassStatus === 'fulfilled') {

            if (userSetMassData.Status == 1) {
                onHide();
            }
            else {
                setReqErrors(userSetMassData.Error);
                setLoading(false);
            }
        }
        if (userSetMassStatus === 'rejected') {
            setLoading(false);
            setReqErrors('Неизвестная ошибка!');
        }
    }, [userSetMassStatus]);


    /****************************END ВКЛАДКА "ОСНОВНОЕ*****************************/
    /***************************START ВКЛАДКА "ПОЛЬЗОВАТЕЛИ****************************/
    //Список пользователей
    //const userListActive = useUserListQuery({ ID_Project: ID });
    const [userListApi, { isLoading: userListApiIsLoading, data: userListApiData, status: userListApiStatus }] = useLazyUserListQuery();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (userListApiStatus === 'fulfilled') {
            if (userListApiData.Status == 1) {                
                setUsers(userListApiData.Data);          

            }
            else {
                //setReqErrors(updateData.Error)
            }
        }
    }, [userListApiStatus]);


    //Изменение пользователя проекта
    //const [userSet, { isLoading: userIsLoading, data: userData, status: userStatus }] = useUserSetMutation();

    const checkboxUserHandler = (e, model) => {
        const nextUsers = users.map((user, i) => {
            if (user.ID_User === model.ID_User) {
                return { ...user, Added: e.target.checked };
            }
            else {
                return user;
            }
        });
        setUsers(nextUsers);
        //userSet({ ID_Project: ID, ID_User: model.ID_User, Value: e.target.checked });
    }

    /****************************END ВКЛАДКА "ПОЛЬЗОВАТЕЛИ*****************************/

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <FontAwesomeIcon icon={faEdit} /> Изменение компании
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a id="tab_01" data-toggle="tab" href="#tab-eg10-0" onClick={tabHandleClick} className={tab01Show ? "nav-link active" : "nav-link show"}> <i className="mr-5"><FontAwesomeIcon icon={faHouse} /></i>Основное</a></li>
                        <li className="nav-item"><a id="tab_02" data-toggle="tab" href="#tab-eg10-1" onClick={tabHandleClick} className={tab02Show ? "nav-link active" : "nav-link show"}><i className="mr-5"> <FontAwesomeIcon icon={faUsers} /></i> Пользователи</a></li>
                        <li className="nav-item"><a id="tab_03" data-toggle="tab" href="#tab-eg10-2" onClick={tabHandleClick} className={tab03Show ? "nav-link active" : "nav-link show"}><i className="mr-5"><FontAwesomeIcon icon={faDatabase} /></i>Параметры</a></li>
                    </ul>

                    <ul className="nav"></ul>
                    <div className="tab-content">
                        <div className={tab01Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                            <div className="">
                                <Form id="my-form" onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <input name='ID' defaultValue={ID} hidden></input>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Название</Form.Label>
                                                <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' disabled={updateIsLoading} defaultValue={Name} />
                                                {validErrors.find((el) => el.name === "Name") !== undefined && (
                                                    <div className="form_error">
                                                        {validErrors.find((el) => el.name === "Name").error}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Описание</Form.Label>
                                                <Form.Control as="textarea" rows={3} name="Description" disabled={updateIsLoading} defaultValue={Description} />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Родительский проект</Form.Label>
                                                <Form.Select aria-label="Default select example" defaultValue={ID_Parent} name="ID_Parent">
                                                    <option value="">Не выбрано</option>
                                                    {listProject.status === 'fulfilled' &&
                                                        listProject.data.Data.filter(a => a.ID_Status === 1).map((model) => (
                                                            <option
                                                                key={model.ID}
                                                                value={model.ID}
                                                            >
                                                                {model.Name_Full}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Статус</Form.Label>
                                                <Form.Select aria-label="Default select example" defaultValue={ID_Status} name="ID_Status">
                                                    <option value="1">Активный</option>
                                                    <option value="0">Архив</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mt-30">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch1"
                                                    label="Использовать в задачах"
                                                    defaultChecked={Use_Tasks}
                                                    value={checkboxTasks}
                                                    onChange={checkboxTasksHandler}
                                                    disabled={updateIsLoading}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch2"
                                                    label="Использовать в регулярных задачах"
                                                    defaultChecked={Use_Rtasks}
                                                    value={checkboxRtasks}
                                                    onChange={checkboxRtasksHandler}
                                                    disabled={updateIsLoading}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch3"
                                                    label="Использовать в досках"
                                                    defaultChecked={Use_Boards}
                                                    value={checkboxBoards}
                                                    onChange={checkboxBoardsHandler}
                                                    disabled={updateIsLoading}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch4"
                                                    label="Использовать в регулярных досках"
                                                    defaultChecked={Use_Rboards}
                                                    value={checkboxRboards}
                                                    onChange={checkboxRboardsHandler}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className={tab02Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                            <div className="">
                                {users.length === 0 ? (
                                    <div>
                                        <div className="loader_01"></div>
                                    </div>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className='thead_blue'>
                                                <tr>
                                                    <th>Имя</th>
                                                    <th>Роль</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {users.map((model) => (
                                                    <tr className='row_select' key={model.ID_User}>
                                                        <td>{model.User_Name}</td>
                                                        <td>{model.Name_Role}</td>
                                                        <td className='text-end'>
                                                                <Form.Check key={Math.random()}
                                                                type="switch"
                                                                value={true}
                                                                onChange={(e) => checkboxUserHandler(e, model)}
                                                                disabled={model.ID_Role === 0 || model.ID_Role === 1 ? true : false}
                                                                defaultChecked={model.ID_Role === 0 || model.ID_Role === 1 ? true : model.Added}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={tab03Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                            <div className="">
                                В разработке...
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {reqErrors != '' && (
                        <div className="form_error">
                            {reqErrors}
                        </div>
                    )}
                    <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        form="my-form"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (
                            "Загрузка..."
                        ) :
                            (
                                <>
                                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить изменения
                                </>
                            )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalEdit;
