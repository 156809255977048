function Login() {
  fetch('/api_auth/api/user/tokenData', {
    mode: 'cors',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 0) {
        return window.location.replace('https://user-office.ru/');
      }
      if (json.status === 1) {
        return window.location.replace('/lk');
      }
    });
}

export default Login;
