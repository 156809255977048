import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { Form } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";


function DesignerParamItem({ model, index, moveParam, paramProjectDisplayRemove }) {

    const id = model.ID_Parameter;
    const id_display = model.ID_Display;

    //Функция определения ширины параметра
    function Width(width) {
        if (width === 100) {
            return 'col-md-12 param_designer_item';
        }
        if (width === 75) {
            return 'col-md-9 param_designer_item';
        }
        if (width === 50) {
            return 'col-md-6 param_designer_item';
        }
        if (width === 25) {
            return 'col-md-3 param_designer_item';
        }
    }

    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: "display" + id_display,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(model, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = model.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveParam(dragIndex, hoverIndex, id_display)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            model.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: "display" + id_display,
        item: () => {
            return { model, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            // console.log(item);
        }
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))


    return (
        <div className={Width(model.Width)} ref={ref} data-handler-id={handlerId} style={{ opacity }}>
            <div className="param_designer_item_xmark" onClick={() => paramProjectDisplayRemove(id_display, id)}>
                <FontAwesomeIcon icon={faXmark} className="mr-5" />
            </div>
            {model.ID_Type === 1 && (
                <Form.Check type="switch" label={model.Name_Parameter} disabled />
            )}
            {model.ID_Type === 2 && (
                <Form.Group className="mb-3" key={model.ID}>
                    <Form.Label>{model.Name_Parameter}</Form.Label>
                    <Form.Control name="Name" type="number" disabled />
                </Form.Group>
            )}
            {model.ID_Type === 3 && (
                <Form.Group className="mb-3" key={model.ID}>
                    <Form.Label>{model.Name_Parameter}</Form.Label>
                    <Form.Control name="Name" type="text" disabled />
                </Form.Group>
            )}
            {model.ID_Type === 4 && (
                <Form.Group>
                    <Form.Label>{model.Name_Parameter}</Form.Label>
                    <Form.Select disabled>
                        <option key={0} value={0}>

                        </option>
                    </Form.Select>
                </Form.Group>
            )}
            {model.ID_Type === 5 && (
                <Form.Group className="mb-3" key={model.ID}>
                    <Form.Label>{model.Name_Parameter}</Form.Label>
                    <Form.Control name="Name" type="date" disabled />
                </Form.Group>
            )}
            <div>
                <button class={model.Width === 25 ? "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm active" : "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm"}>25%</button>
                <button class={model.Width === 50 ? "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm active" : "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm"}>50%</button>
                <button class={model.Width === 75 ? "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm active" : "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm"}>75%</button>
                <button class={model.Width === 100 ? "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm active" : "mb-2 mr-2 btn-square btn btn-outline-secondary btn-sm"}>100%</button>
            </div>
        </div>
    )
}
export default DesignerParamItem;
