import { Form } from "react-bootstrap";

function HoursInput({ defaultValue, change, label }) {

    return (
        <Form.Group key={defaultValue}>
            <Form.Label>{label}</Form.Label>
            <Form.Select key={Math.random()} defaultValue={defaultValue} onChange={change}>
                <option value={-1}>Не выбрано</option>
                <option value={0} minute={0}>00:00</option>
                <option value={0} minute={30}>00:30</option>
                <option value={1} minute={0}>01:00</option>
                <option value={1} minute={30}>01:30</option>
                <option value={2} minute={0}>02:00</option>
                <option value={2} minute={30}>02:30</option>
                <option value={3} minute={0}>03:00</option>
                <option value={3} minute={30}>03:30</option>
                <option value={4} minute={0}>04:00</option>
                <option value={4} minute={30}>04:30</option>
                <option value={5} minute={0}>05:00</option>
                <option value={5} minute={30}>05:30</option>
                <option value={6} minute={0}>06:00</option>
                <option value={6} minute={30}>06:30</option>
                <option value={7} minute={0}> 07:00</option>
                <option value={7} minute={30}> 07:30</option>
                <option value={8} minute={0}>08:00</option>
                <option value={8} minute={30}>08:30</option>
                <option value={9} minute={0}>09:00</option>
                <option value={9} minute={30}>09:30</option>
                <option value={10} minute={0}>10:00</option>
                <option value={10} minute={30}>10:30</option>
                <option value={11} minute={0}>11:00</option>
                <option value={11} minute={30}>11:30</option>
                <option value={12} minute={0}>12:00</option>
                <option value={12} minute={30}>12:30</option>
                <option value={13} minute={0}>13:00</option>
                <option value={13} minute={30}>13:30</option>
                <option value={14} minute={0}>14:00</option>
                <option value={14} minute={30}>14:30</option>
                <option value={15} minute={0}>15:00</option>
                <option value={15} minute={30}>15:30</option>
                <option value={16} minute={0}>16:00</option>
                <option value={16} minute={30}>16:30</option>
                <option value={17} minute={0}>17:00</option>
                <option value={17} minute={30}>17:30</option>
                <option value={18} minute={0}>18:00</option>
                <option value={18} minute={30}>18:30</option>
                <option value={19} minute={0}>19:00</option>
                <option value={19} minute={30}>19:30</option>
                <option value={20} minute={0}>20:00</option>
                <option value={20} minute={30}>20:30</option>
                <option value={21} minute={0}>21:00</option>
                <option value={21} minute={30}>21:30</option>
                <option value={22} minute={0}>22:00</option>
                <option value={22} minute={30}>22:30</option>
                <option value={23} minute={0}>23:00</option>
                <option value={23} minute={30}>23:30</option>
            </Form.Select>
        </Form.Group>
    )
}

export default HoursInput;