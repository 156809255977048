import { Form } from "react-bootstrap";
import ParametersSelect from "./ParametersSelect";
import StringToInputDate from "../../util/StringToInputDate";

function Parameters(props) {
    const { list, dispatch } = props;
    //console.log(list)
    /*
    ID	Name
    1	Логический
    2	Число
    3	Строка
    4	Справочник
    5	Дата
    */
    //Функция определения ширины параметра
    function Width(width) {
        if (width === 100) {
            return 'col-md-12';
        }
        if (width === 75) {
            return 'col-md-9';
        }
        if (width === 50) {
            return 'col-md-6';
        }
        if (width === 25) {
            return 'col-md-3';
        }
    }

    function handlerChange(e, id_parameter, id_type) {
        if (id_type === 1) {
            dispatch({ type: 'set', id_parameter: id_parameter, id_type: id_type, value_bool: e.target.checked })
        }
        if (id_type === 2) {
            dispatch({ type: 'set', id_parameter: id_parameter, id_type: id_type, value_float: e.target.value })
        }
        if (id_type === 3) {
            dispatch({ type: 'set', id_parameter: id_parameter, id_type: id_type, value_string: e.target.value })
        }
        if (id_type === 4) {
            dispatch({ type: 'set', id_parameter: id_parameter, id_type: id_type, value_float: e.target.value })
        }
        if (id_type === 5) {
            dispatch({ type: 'set', id_parameter: id_parameter, id_type: id_type, value_date: e.target.value })
        }
    }

    return (
        <div className="row">
            {
                list.map((model) => (
                    <div key={model.ID_Parameter} className={Width(model.Width)}>
                        {model.ID_Type === 1 && (
                            <Form.Check id={model.ID_Parameter + '_' + model.ID_Parameter_Display} key={Math.random()} type="switch" label={model.Name} defaultChecked={model.Value_Bool} onChange={(e) => handlerChange(e, model.ID_Parameter, model.ID_Type)} disabled={model.Read_Only_Display} />
                        )}
                        {model.ID_Type === 2 && (
                            <Form.Group className="mb-3" key={model.ID}>
                                <Form.Label>{model.Name}</Form.Label>
                                <Form.Control id={model.ID_Parameter + '_' + model.ID_Parameter_Display} key={Math.random()} type="number" defaultValue={model.Value_Float} onBlur={(e) => handlerChange(e, model.ID_Parameter, model.ID_Type)} disabled={model.Read_Only_Display} />
                            </Form.Group>
                        )}
                        {model.ID_Type === 3 && (
                            <Form.Group className="mb-3" key={model.ID}>
                                <Form.Label>{model.Name}</Form.Label>
                                <Form.Control id={model.ID_Parameter + '_' + model.ID_Parameter_Display} key={Math.random()} type="text" defaultValue={model.Value_String} onBlur={(e) => handlerChange(e, model.ID_Parameter, model.ID_Type)} disabled={model.Read_Only_Display} />
                            </Form.Group>
                        )}
                        {model.ID_Type === 4 && (
                            'list'
                            /*
                            <ParametersSelect id={model.ID_Parameter + '_' + model.ID_Parameter_Display} key={Math.random()} model={model} value={model.Value_Int} handlerChange={(e) => handlerChange(e, model.ID_Parameter, model.ID_Type)} disabled={model.Read_Only_Display} />
                       */ )}
                        {model.ID_Type === 5 && (
                            <Form.Group className="mb-3" key={model.ID}>
                                <Form.Label>{model.Name}</Form.Label>
                                <Form.Control id={model.ID_Parameter + '_' + model.ID_Parameter_Display} key={Math.random()} type="date" defaultValue={StringToInputDate(model.Value_Date)} onChange={(e) => handlerChange(e, model.ID_Parameter, model.ID_Type)} disabled={model.Read_Only_Display} />
                            </Form.Group>
                        )}
                    </div>
                )
                )
            }
        </div>

    )
}
export default Parameters;