import React, { Fragment, useState } from 'react';

import CompanyList from './CompanyList';
import ExternalCompanyList from './ExternalCompanyList';
import RequestCompanyList from './RequestCompanyList';
import { faArrowUpRightDots, faArrowUpRightFromSquare, faBolt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Index() {

  const [tabActiveShow, setTabActiveShow] = useState(true);
  const [tabArchiveShow, setTabArchiveShow] = useState(false);

  const [tabReqActiveShow, setTabReqActiveShow] = useState(true);
  const [tabReqCancelShow, setTabReqCancelShow] = useState(false);

  function tabCompanyHandleClick(e) {
    if (e.currentTarget.id === 'tab_active') {
      setTabActiveShow(true);
      setTabArchiveShow(false);
    } else {
      setTabActiveShow(false);
      setTabArchiveShow(true);
    }
  }

  function tabReqHandleClick(e) {
    if (e.currentTarget.id === 'tab_active') {
      setTabReqActiveShow(true);
      setTabReqCancelShow(false);
    } else {
      setTabReqActiveShow(false);
      setTabReqCancelShow(true);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="main-card mb-3 card">
            <div className="card-header-tab card-header-tab-animation card-header">
              <div className="card-header-title">
              <i className="fas fa-lg mr-5"><FontAwesomeIcon icon={faBuilding} /></i>Собственные компании
              </div>
              <ul className="nav">
                <li className="nav-item">
                  <a id="tab_active" onClick={tabCompanyHandleClick} data-toggle="tab" className={tabActiveShow ? 'nav-link active show' : 'nav-link show'}>
                    <i className="fas fa-star"></i> Активные
                  </a>
                </li>
                <li className="nav-item">
                  <a id="tab_archive" onClick={tabCompanyHandleClick} data-toggle="tab" className={tabArchiveShow ? 'nav-link active show' : 'nav-link show'}>
                    <i className="fas fa-archive"></i> Архивные
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div className={tabActiveShow ? 'tab-pane show active' : 'tab-pane show'} role="tabpanel">
                  <div className="">
                    <CompanyList ID_Status={1} />
                  </div>
                </div>
                <div className={tabArchiveShow ? 'tab-pane show active' : 'tab-pane show'} role="tabpanel">
                  <div className="">
                    <CompanyList ID_Status={0} />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="main-card mb-3 card">
            <div className="card-header-tab card-header-tab-animation card-header">
              <div className="card-header-title">
              <i className="fas fa-lg mr-5"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></i>Внешние компании
              </div>             
            </div>
            <div className="card-body">
              <div className="tab-content">
              <ExternalCompanyList ID_Status={1} />
              </div>
              <div className="row mb-3">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </div> 
        <div className="col-md-6">
          <div className="main-card mb-3 card">
            <div className="card-header-tab card-header-tab-animation card-header">
              <div className="card-header-title">
              <i className="fas fa-lg mr-5"><FontAwesomeIcon icon={faBolt} /></i>Заявки
              </div>
              <ul className="nav">
                <li className="nav-item">
                  <a id="tab_active" onClick={tabReqHandleClick} data-toggle="tab" className={tabReqActiveShow ? 'nav-link active show' : 'nav-link show'}>
                    <i className="fas fa-star"></i> Активные
                  </a>
                </li>
                <li className="nav-item">
                  <a id="tab_archive" onClick={tabReqHandleClick} data-toggle="tab" className={tabReqCancelShow ? 'nav-link active show' : 'nav-link show'}>
                    <i className="fas fa-archive"></i> Отклоненные
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div className={tabReqActiveShow ? 'tab-pane show active' : 'tab-pane show'} role="tabpanel">
                  <div className="">
                    <RequestCompanyList ID_Status={1} />
                  </div>
                </div>
                <div className={tabReqCancelShow ? 'tab-pane show active' : 'tab-pane show'} role="tabpanel">
                  <div className="">
                    <RequestCompanyList ID_Status={3} />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
