
function Index() {



    return (
        <>
            12345
        </>
    )
}
export default Index;