import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlockMain from "./BlockMain";
import BlockPassword from "./BlockPassword";

function Index() {
    return(
        <>
        <div className="row">
            <div className="col-md-6">
                <BlockMain/>
            </div> 
            <div className="col-md-6">
                <BlockPassword/>
            </div> 
        </div>
        </>
    )
}
export default Index;