import React, { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faCalendarDays,
    faClipboard,
    faDatabase,
    faDiagramProject,
    faEnvelope,
    faGear,
    faLightbulb,
    faListCheck,
    faMessage,
    faRepeat,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

import CountSetting from './CountSetting';
import { useTrackerListActiveQuery } from '../../api/tracker/taskR';

function VerticalNavWrapper() {

    const [menuParamOpen, setMenuParamOpen] = useState(false);
    const [menuTaskRopen, setMenuTaskRopen] = useState(false);



    useEffect(() => {
        const url_mass = window.location.pathname.split('/');

        if (url_mass[2] === 'taskR') {
            setMenuTaskRopen(true);
        }
        if (url_mass[2] === 'param') {
            setMenuParamOpen(true);
        }
    }, []);



    function handlerClickMenu() {
        setMenuParamOpen(false);
    }

    /*****************************START РЕГУЛЯРНЫЕ ЗАДАЧИ*****************************/
    const [menuCountViewer, setMenuCountViewer] = useState(0);
    const [menuCountExecutor, setMenuCountExecutor] = useState(0);

    const listActiveViewer = useTrackerListActiveQuery({ ID_Action_User: 1 });//Наблюдатель
    const listActiveExecutor = useTrackerListActiveQuery({ ID_Action_User: 2 });//Исполнитель

    useEffect(() => {
        if (listActiveExecutor.status === 'fulfilled') {
            if (listActiveExecutor.data.Status == 1) {
                setMenuCountExecutor(listActiveExecutor.data.Data.length);
            }
        }
    }, [listActiveExecutor.status]);
    useEffect(() => {
        if (listActiveViewer.status === 'fulfilled') {
            if (listActiveViewer.data.Status == 1) {
                setMenuCountViewer(listActiveViewer.data.Data.length);
            }
        }
    }, [listActiveViewer.status]);
    /******************************END РЕГУЛЯРНЫЕ ЗАДАЧИ******************************/

    return (
        <Fragment>
            <h5 className="app-sidebar__heading">Меню трекера</h5>
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="current" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faLightbulb} />
                            </i>
                            Рабочий стол
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="calendar" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faCalendarDays} />
                            </i>
                            Календарь
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="project" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faDiagramProject} />
                            </i>
                            Проекты
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="task" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faListCheck} />
                            </i>
                            Задачи
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <a className="metismenu-link has-active-child" href="#" onClick={() => setMenuTaskRopen(!menuTaskRopen)}>

                            <i className="metismenu-icon"><FontAwesomeIcon icon={faRepeat} /></i>
                            Переодич. задачи
                            {(menuCountExecutor + menuCountViewer) > 0 && <span className="badge badge-menu badge-dot"> </span>}

                            <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                        </a>
                        <ul className={menuTaskRopen === true ? "metismenu-container visible" : "metismenu-container"}>
                            <li className="metismenu-item">
                                <NavLink className="metismenu-link" to="/lk/taskR/current">
                                    Текущие задачи {(menuCountExecutor + menuCountViewer) > 0 && <span className="badge badge-pill badge-success">{(menuCountExecutor + menuCountViewer)}</span>}
                                </NavLink>
                            </li>
                            <li className="metismenu-item">
                                <NavLink className="metismenu-link" to="/lk/taskR/setting">
                                    Настройка
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="board" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faClipboard} />
                            </i>
                            Доски
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="messages" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faMessage} />
                            </i>
                            Сообщения
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <a className="metismenu-link has-active-child" href="#" onClick={() => setMenuParamOpen(!menuParamOpen)}>
                            <i className="metismenu-icon"><FontAwesomeIcon icon={faDatabase} /></i>
                            Параметры
                            <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                        </a>
                        <ul className={menuParamOpen === true ? "metismenu-container visible" : "metismenu-container"}>
                            <li className="metismenu-item">
                                <NavLink className="metismenu-link" to="/lk/param/task">
                                    Задачи
                                </NavLink>
                            </li>
                            <li className="metismenu-item">
                                <NavLink className="metismenu-link" to="/lk/param/rtask">
                                    Регулярные задачи
                                </NavLink>
                            </li>
                            <li className="metismenu-item">
                                <NavLink className="metismenu-link" to="/lk/param/board">
                                    Доски
                                </NavLink>
                            </li>
                            <li className="metismenu-item">
                                <NavLink className="metismenu-link" to="/lk/param/rboard">
                                    Регулярные доски
                                </NavLink>
                            </li>                            
                        </ul>
                    </li>
                    <li className="metismenu-item" onClick={() => handlerClickMenu()}>
                        <NavLink className="metismenu-link" to="user">
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faUsers} />
                            </i>
                            Пользователи
                        </NavLink>
                    </li>
                </ul>
            </div>
            <h5 className="app-sidebar__heading">Глобальное меню</h5>
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="company" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faBuilding} />
                            </i>
                            Компании
                            <CountSetting />
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="setting" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faGear} />
                            </i>
                            Настройки
                        </NavLink>
                    </li>
                    <li className="metismenu-item">
                        <NavLink className="metismenu-link" to="support" onClick={() => handlerClickMenu()}>
                            <i className="metismenu-icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </i>
                            Поддержка
                        </NavLink>
                    </li>
                </ul>
            </div>
        </Fragment>
    );
}

export default VerticalNavWrapper;