import { faBan, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserRequestAcceptMutation, useUserRequestCancelMutation, useUserRequestInListQuery } from '../../../api/main/company';
import React, { useEffect, useState } from 'react';
import { CompanyHubChangeStatusRequest } from '../../../SignalR/CompanyHub';

function RequestCompanyList(arg) {

  //API
  const reqList = useUserRequestInListQuery({ID_Status: arg.ID_Status});
  const [reqAccept, reqAcceptParam] = useUserRequestAcceptMutation();
  const [reqCancel, reqCancelParam] = useUserRequestCancelMutation();
  
  //Примет заявку
  function requestAccept(id_request){
    reqAccept({ID_Request:id_request});  
    CompanyHubChangeStatusRequest({ID_Request:id_request});
  }

  //Отклонит заявку
  function requestCancel(id_request){
    reqCancel({ID_Request:id_request});
    CompanyHubChangeStatusRequest({ID_Request:id_request});
  }

  //Обработка запроса API
  useEffect(() => {   
      if(reqAcceptParam.status === 'fulfilled'){  
        reqList.refetch();    
      }       
      if(reqCancelParam.status === 'fulfilled'){  
        reqList.refetch();    
       }
  }, [reqAcceptParam.status, reqCancelParam.status]);


  return (
    <>     
      {reqList.isLoading === true ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} size="2x" />
        </div>
      ) : (
        <div>
       <div>{}</div>
          <ul className="list-group">
            {reqList.data.Data.map((model) => (
              <li className="list-group-item row_select" key={model.ID}>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3"></div>
                    <div className="widget-content-left">
                      <div className="widget-heading">{model.Name_Company}</div>
                    </div>
                    <div className="widget-content-right">
                      <div role="group" className="btn-group-sm btn-group">
                        <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={(e) => requestAccept(model.ID)}>
                          <i className="mr-5">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Принять
                        </button>   
                        {arg.ID_Status === 1 &&
                          <button className="btn-square btn-hover-shine btn btn-secondary" onClick={(e) => requestCancel(model.ID)}>
                          <i className="mr-5">
                            <FontAwesomeIcon icon={faBan} />
                          </i>
                          Отклонить
                        </button>
                        }                    
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default RequestCompanyList;
