import { faBuilding, faFloppyDisk, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useInfoGetQuery, useNameUpdateMutation, usePhoneUpdateMutation } from "../../../api/main/user";
import { ValidateForm } from '../../../util/ValidateForm';
import FormToJson from '../../../util/FormToJson';


function BlockMain() {

    const [validErrors, setValidErrors] = useState([]);

    //Получение данных
    const userInfo = useInfoGetQuery();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [nameProc, setNameProc] = useState(0);//0-Не обработано; 1-Обработано успех; 2-Обработано ошибка
    const [phoneProc, setPhoneProc] = useState(0);//0-Не обработано; 1-Обработано успех; 2-Обработано ошибка
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userInfo.status === 'fulfilled') {
            setName(userInfo.data.Data.Name)
            setEmail(userInfo.data.Data.Email)
            setPhone(userInfo.data.Data.Phone_Number)
        }
    }, [userInfo.status]);

    //Сохранение данных
    const [nameUpdate, { isLoading: nameUpdateIsLoading, data: nameUpdateData, status: nameUpdateStatus }] = useNameUpdateMutation();
    const [phoneUpdate, { isLoading: phoneUpdateIsLoading, data: phoneUpdateData, status: phoneUpdateStatus }] = usePhoneUpdateMutation();

    //Обработка "Имя"
    useEffect(() => {
        if (nameUpdateStatus === 'fulfilled') {
            if (nameUpdateData.Status == 1) {
                setNameProc(1);

                const timer = setTimeout(() => {
                    setNameProc(0);
                }, 5000);
            }
            if (nameUpdateData.Status == 0) {
                setNameProc(2);
            }
        }
        if (nameUpdateStatus === 'rejected') {
            setNameProc(2);
        }


    }, [nameUpdateStatus]);

    //Обработка "Телефон"
    useEffect(() => {
        if (phoneUpdateStatus === 'fulfilled') {
            if (phoneUpdateData.Status == 1) {
                setPhoneProc(1);

                const timer = setTimeout(() => {
                    setPhoneProc(0);
                }, 5000);
            }
            if (phoneUpdateData.Status == 0) {
                setPhoneProc(2);
            }
        }
        if (phoneUpdateStatus === 'rejected') {
            setPhoneProc(2);
        }
    }, [phoneUpdateStatus]);

    //Изменит состояние загрузки
    useEffect(() => {
        if (nameUpdateIsLoading === false & phoneUpdateIsLoading === false) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [nameUpdateIsLoading, phoneUpdateIsLoading]);

    const submit = (e) => {
        e.preventDefault();

        var validForm = ValidateForm(e.target, setValidErrors);

        if (validForm) {
            const form = FormToJson(e.target);
            nameUpdate({ Name: form['Name'] });
            phoneUpdate({ Phone: form['Phone'] });
        }
    };
    return (
        <>
            <div className="main-card mb-3 card">
                <div className="card-header-tab card-header-tab-animation card-header">
                    <div className="card-header-title">
                        <i className="fas fa-lg mr-5"><FontAwesomeIcon icon={faBuilding} /></i>Основные настройки
                    </div>
                </div>
                <div className="card-body">
                    <div className="tab-content">
                        <Form id="my-form" onSubmit={submit}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Имя</Form.Label>
                                <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' defaultValue={name} disabled={loading} />
                                {validErrors.find((el) => el.name === 'Name') !== undefined && <div className="form_error">{validErrors.find((el) => el.name === 'Name').error}</div>}
                                {nameProc === 1 && <div className="form_success">Сохранено</div>}
                                {nameProc === 2 && <div className="form_error">Ошибка сохранения</div>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Электронная почта</Form.Label>
                                <Form.Control name="Email" type="text" valid='[{"id": "empty"}]' defaultValue={email} disabled />
                                {validErrors.find((el) => el.name === 'Email') !== undefined && <div className="form_error">{validErrors.find((el) => el.name === 'Email').error}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3" defaultValue={phone}>
                                <Form.Label>Номер телефона</Form.Label>
                                <Form.Control name="Phone" type="text" defaultValue={phone} disabled={loading} />
                                {validErrors.find((el) => el.name === 'Phone') !== undefined && <div className="form_error">{validErrors.find((el) => el.name === 'Phone').error}</div>}
                                {phoneProc === 1 && <div className="form_success">Сохранено</div>}
                                {phoneProc === 2 && <div className="form_error">Ошибка сохранения</div>}
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-12"></div>
                    </div>
                </div>
                <div className="card-footer">
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary flex-end"
                        form="my-form"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранение...
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить изменения
                            </>
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}
export default BlockMain;