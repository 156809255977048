import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useListQuery, useRemoveMutation } from "../../../../api/tracker/taskR";


function ModalRemove(props) {
    const { show, onHide, model_item } = props;

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);
    //const [idTaskR, setIdTaskR] = useState(null);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setLog(null);
            setLoading(false);
            //setIdTaskR(id_taskR);
        }
        //Действия при закрытии модального окна
        if (show == false) {

        }
    }, [show]);


    //Добавление расписания
    const [remove, { data: removeData, status: removeStatus, isLoading: removeIsLoading }] = useRemoveMutation();

    function removeHandler() {
        setLoading(true);
        setLog(null);
        remove({ ID_TaskR: model_item.ID });
    }

    useEffect(() => {
        if (removeStatus === 'fulfilled') {
            if (removeData.Status == 1) {
                setLoading(false);
                listActive.refetch()
                listArchive.refetch()
                listFuture.refetch()
                onHide();
            }
            else {
                setLog(removeData.Error);
                setLoading(false);
            }
        }
        if (removeStatus === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [removeStatus]);

    const listActive = useListQuery({ ID_Status: 1 });
    const listArchive = useListQuery({ ID_Status: 0 });
    const listFuture = useListQuery({ ID_Status: 2 });


    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faTrash} /></i> Удаление регулярной задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Подтвердите удаление задачи <b>«{model_item !== null && model_item.Name}»</b>

                    {log != null && (
                        <div className="form_error text-center">
                            {log}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => removeHandler()}
                        disabled={loading}
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-5" />
                        {loading === true ? ('Удаление...') : 'Удалить задачу'}

                    </button>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalRemove;
