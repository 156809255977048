import { faCheck, faEdit, faEllipsisH, faMagnifyingGlass, faRotateLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCheckListListQuery, useLazyCheckListGetQuery } from "../../../../api/tracker/task";
import { useState } from "react";
import ModalAdd from "./ModalAdd";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalComplete from "./ModalComplete";
import ModalDetail from "./ModalDetail";
import ModalRemove from "./ModalRemove";
import ModalCompleteCancel from "./ModalCompleteCancel";

function List({ id_task, id_project }) {

    const userListApi = useCheckListListQuery({ ID_Task: id_task });
    const [userGetApi, userGetApiResult] = useLazyCheckListGetQuery();

    const [modalEdit, setModalEdit] = useState(false);
    const [modalRemove, setModalRemove] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalComplete, setModalComplete] = useState(false);
    const [modalCompleteCancel, setModalCompleteCancel] = useState(false);

    const [model, setModel] = useState(null);

    function modalEditHandler(id) {
        userGetApi({ ID: id })
            .unwrap()
            .then((res) => {
                if (res.Status === 1) {
                    setModel(res.Data);
                    setModalEdit(true);
                }
            })
    }
    function modalRemoveHandler(e, model) {
        setModel(model);
        setModalRemove(true);
    }
    function modalDetailHandler(e, model) {
        e.preventDefault();

        userGetApi({ ID: model.ID })
            .unwrap()
            .then((res) => {
                if (res.Status === 1) {
                    setModel(res.Data);
                    setModalDetail(true);
                }
            })
    }
    function modalCompleteHandler(e, model) {
        setModel(model);
        setModalComplete(true);
    }
    function modalCompleteCancelHandler(e, model) {
        setModel(model);
        setModalCompleteCancel(true);
    }

    return (
        <>
            {model !== null && (
                <>
                    <ModalAdd show={modalEdit} onHide={() => setModalEdit(false)} id_task={id_task} id_project={id_project} edit={true} model={model} />
                    <ModalComplete show={modalComplete} onHide={() => setModalComplete(false)} model={model} />
                    <ModalCompleteCancel show={modalCompleteCancel} onHide={() => setModalCompleteCancel(false)} model={model} />
                    <ModalDetail show={modalDetail} onHide={() => setModalDetail(false)} model={model} />
                    <ModalRemove show={modalRemove} onHide={() => setModalRemove(false)} model={model} />
                </>
            )}

            {userListApi.status === 'fulfilled' && (
                <div className="mt-15 vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    {userListApi.data.Data.map((model) => (
                        <div key={model.ID} className={model.Completed ? ("vertical-timeline-item dot-success vertical-timeline-element row_select") : ("vertical-timeline-item dot-warning vertical-timeline-element row_select")}>
                            <div>
                                <span className="vertical-timeline-element-icon bounce-in"></span>
                                <div className="vertical-timeline-element-content bounce-in">
                                    <div className="widget-content p-0">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    <h4 className={model.Completed ? ("timeline-title text-decoration-line-through") : ("timeline-title")}><a href="" onClick={(e) => modalDetailHandler(e, model)}>{model.Name}</a></h4>
                                                </div>
                                                <div className="widget-subheading" style={{ marginTop: "-7px" }}><i>{model.Name_User}</i></div>
                                            </div>
                                            <div className="widget-content-right widget-content-actions" >
                                                <Dropdown as={ButtonGroup} >
                                                    <Dropdown.Toggle variant="success_" className="border-0 btn-transition btn btn-link dropdown-toggle-none-arrow" >
                                                        <i className="btn-icon-wrapper"> <FontAwesomeIcon icon={faEllipsisH} /></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {model.Completed !== true && (<Dropdown.Item href="#/action-1" eventKey={1} onClick={(e) => modalCompleteHandler(e, model)}><i className="btn-icon-wrapper mr-4"> <FontAwesomeIcon icon={faCheck} /></i>Завершить</Dropdown.Item>)}
                                                        <Dropdown.Item href="#/action-1" eventKey={2} onClick={(e) => modalDetailHandler(e, model)}><i className="btn-icon-wrapper mr-4"> <FontAwesomeIcon icon={faMagnifyingGlass} /></i>Посмотреть</Dropdown.Item>
                                                        {model.Completed !== true && (<Dropdown.Item href="#/action-2" eventKey={3} onClick={() => modalEditHandler(model.ID)}><i className="btn-icon-wrapper mr-4"> <FontAwesomeIcon icon={faEdit} /></i>Редактировать</Dropdown.Item>)}
                                                        {model.Completed !== true && (<Dropdown.Item href="#/action-3" eventKey={4} onClick={(e) => modalRemoveHandler(e, model)}><i className="btn-icon-wrapper mr-4"> <FontAwesomeIcon icon={faTrash} /></i>Удалить</Dropdown.Item>)}
                                                        {model.Completed === true && (<Dropdown.Item href="#/action-4" eventKey={5} onClick={(e) => modalCompleteCancelHandler(e, model)}><i className="btn-icon-wrapper mr-4"> <FontAwesomeIcon icon={faRotateLeft} /></i>Отменить выполнение</Dropdown.Item>)}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default List;