import { faArchive, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ModalAdd from "./ModalAdd";
//import List from "./List";
import ListParent from "./ListParent";
import ListArchive from "./ListArchive";

function Index() {

    //Модальные окна
    const [modalAddShow, setModalAddShow] = useState(false);

    //TAB
    const [tabActiveShow, setTabActiveShow] = useState(true);
    const [tabArchiveShow, setTabArchiveShow] = useState(false);

    function tabCompanyHandleClick(e) {
        if (e.currentTarget.id === "tab_active") {
            setTabActiveShow(true);
            setTabArchiveShow(false);
        }
        if (e.currentTarget.id === "tab_archive") {
            setTabActiveShow(false);
            setTabArchiveShow(true);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-card mb-3 card">
                        <div className="card-header-tab card-header-tab-animation card-header">
                            <div className="card-header-title">
                                <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => setModalAddShow(true)}>
                                    <i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>
                                    Добавить проект
                                </button>
                                <ModalAdd
                                    show={modalAddShow}
                                    onHide={() => setModalAddShow(false)}
                                />
                            </div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a
                                        id="tab_active"
                                        onClick={tabCompanyHandleClick}
                                        data-toggle="tab"
                                        className={tabActiveShow ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faPlay} /> Активные
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_archive"
                                        onClick={tabCompanyHandleClick}
                                        data-toggle="tab"
                                        className={tabArchiveShow ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faArchive} /> Архивные
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className={tabActiveShow ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="">
                                        <ListParent />
                                    </div>
                                </div>
                                <div className={tabArchiveShow ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="">
                                        <ListArchive />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;