import { useEffect, useState } from "react";
import { useUserRequestInListQuery } from "../../api/main/company";

function CountSetting() {

    //API
    const reqList = useUserRequestInListQuery({ID_Status: 1});
    const [count, setCount] = useState(0);

    //Обработка запроса API
    useEffect(() => {   
        if(reqList.status === 'fulfilled'){  
            setCount(reqList.data.Data.length)
        }        
    }, [reqList.status]);

    return (
        <>
            {count > 0 && <div className="badge badge-pill badge-success menu-badge">{count}</div>}        
        </>
    )
}

export default CountSetting;