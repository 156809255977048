import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useInfoGetQuery } from '../api/main/user';

//Проверка обязательных данных на заполненность. Выполняется в Index.jsx
function CheckData() {

    const userInfo = useInfoGetQuery();

    const [emailConfirmed, setEmailConfirmed] = useState(true);
    const [phoneConfirmed, setPhoneConfirmed] = useState(true);
    const [userStatus, setUserStatus] = useState(1);

    useEffect(() => {
        if (userInfo.status === 'fulfilled') {
            setEmailConfirmed(userInfo.data.Data.Email_Confirmed)
            setUserStatus(userInfo.data.Data.ID_Status)
        }
    }, [userInfo.status]);

    if (userStatus === -1) {
        return <Navigate to="/lk/user_blocked" replace />
    }
    if (emailConfirmed === false) {
        return <Navigate to="/lk/email_confirm" replace />
    }
}

export default CheckData;