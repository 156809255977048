import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//https://habr.com/ru/articles/730916/
export const serviceApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api_main/main/service/' }),
  tagTypes: ['service'],
  reducerPath: 'Service',
  endpoints: (builder) => ({
    roleList: builder.query({
      query: (args) => {
        return {
          url: 'roleList',
          method: 'GET',
          params: args,
        };
      }
    })   
  }),
});

export const { useRoleListQuery } = serviceApi;
