import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { useTrackerCompleteMutation, useTrackerListActiveQuery, useTrackerListArchiveQuery } from "../../../../api/tracker/taskR";
import { useEffect, useState } from "react";


function ModalCardConfirm(props) {
    const { show, onHide, onHideCard, model } = props;

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setLoading(false);
            setLog(null);            
        }
        //Действия при закрытии модального окна
        if (show == false) {

        }
    }, [show]);

    const listActiveViewer = useTrackerListActiveQuery({ ID_Action_User: 1 });//Наблюдатель
    const listActiveExecutor = useTrackerListActiveQuery({ ID_Action_User: 2 });//Исполнитель
    const listArchive = useTrackerListArchiveQuery({ Skip: 0, Take: 30 });//Архив

    const [loading, setLoading] = useState(false);
    const [log, setLog] = useState(null);

    const [formComment, setFormComment] = useState(null);
    const [formComfirmAll, setFormComfirmAll] = useState(null);

    const [complete, { data: completeData, status: completeStatus, isLoading: completeIsLoading }] = useTrackerCompleteMutation();
    useEffect(() => {
        if (completeStatus === 'fulfilled') {
            if (completeData.Status == 1) {
                setLoading(false);

                listActiveExecutor.refetch();
                listActiveViewer.refetch();
                listArchive.refetch();

                onHide();
                onHideCard();
            }
            else {
                setLog(completeData.Error);
                setLoading(false);
            }
        }
        if (completeStatus === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [completeStatus]);



    function formCommentHandler(e) {
        setFormComment(e.target.value);
    }
    function formComfirmAllHandler(e) {
        setFormComfirmAll(e.target.checked);
    }

    function completeHandler() {
        setLoading(true);
        complete({ ID: model.ID, Comment: formComment, Complete_All: formComfirmAll })
    }

    return (
        model !== null && (
            <>
                <Modal show={show} onHide={onHide} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title-main">
                            <>
                                Завершение задачи 
                            </>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt-5">
                            Подтвердите завершение задачи <b>«{model.Name_TaskR}»</b>
                        </div>
                        <Form.Group className="mt-15" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Комментарий</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Не обязательно" onChange={(e) => formCommentHandler(e)} />
                        </Form.Group>
                        <Form.Check className="mt-15" onChange={(e) => formComfirmAllHandler(e)}
                            id="comfirm_all_task"
                            label={'Выполнить все задачи «' + model.Name_TaskR + '»'}
                        />

                        {log != null && (
                            <div className="form_error text-center">
                                {log}
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex-grow-1">

                        </div>
                        <div>
                            <button onClick={() => completeHandler()} style={{ width: "100%" }} className="btn btn-secondary" type="button" disabled={loading}>
                                <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" />
                                {loading === true ?
                                    (
                                        'Загрузка...'
                                    )
                                    :
                                    (
                                        'Завершить задачу'
                                    )
                                }
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    );
}
export default ModalCardConfirm;
