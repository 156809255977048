import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { ValidateForm } from '../../../util/ValidateForm';
import { useEffect, useState } from 'react';
import FormToJson from '../../../util/FormToJson';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';

function CompanyModalAdd(props) {
  const { show, onHide, addCompany, addError, addIsFetching, addStatus } = props;

  const [validErrors, setValidErrors] = useState([]);

  useEffect(() => {
    if (show === true) {
      setValidErrors([]);
    }
  }, [show]);


  const submit = (e) => {
    e.preventDefault();

    var validForm = ValidateForm(e.target, setValidErrors);

    if (validForm) {
      addCompany(FormToJson(e.target));
    }
  };

  useEffect(() => {
    if (addStatus === 'fulfilled') {
      onHide();
    }
  }, [addStatus]);


  return (
    <>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <>
              <FontAwesomeIcon icon={faPlus} /> Добавление компании
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="my-form" onSubmit={submit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Название компании</Form.Label>
              <Form.Control
                name="Name"
                type="text"
                valid='[{"id": "empty"}]'
                disabled={addIsFetching}
              />
              {validErrors.find((el) => el.name === 'Name') !== undefined && (
                <div className="form_error">
                  {validErrors.find((el) => el.name === 'Name').error}
                </div>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Описание компании</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="Description"
                disabled={addIsFetching}
              />
              {validErrors.find((el) => el.name === 'Description') !==
                undefined &&
                validErrors.find((el) => el.name === 'Description').error}
            </Form.Group>
          </Form>
          {addError == true && (
            <div className="form_error">
              Неизвестная ошибка! Обратитесь в техническую поддержку
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="mr-5 btn-square btn-hover-shine btn btn-secondary"
            onClick={onHide}
          >
            <FontAwesomeIcon icon={faXmark} className="mr-5" />
            Закрыть
          </button>
          <button
            className="mr-5 btn-square btn-hover-shine btn btn-secondary"
            form="my-form"
            type="submit"
            disabled={addIsFetching}
          >
            {addIsFetching ? (
              'Загрузка...'
            ) : (
              <>
                <FontAwesomeIcon icon={faPlus} className="mr-5" /> Добавить
                компанию
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CompanyModalAdd;
