import { set } from 'date-fns';
import { useState } from 'react';
import FormToJson from './FormToJson';

function ValidateForm(target, /*setValidForm, */ setValidErrors) {
  //setValidForm(false);
  var validForm = true;
  const jsonErrors = [];

  for (let i = 0; i < target.length; i++) {
    const nodeName = target[i].nodeName;
    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') {
      const { name, valid, error } = ValidateInput(
        target[i].name,
        target[i].value,
        target[i].getAttribute('valid')
      );

      jsonErrors.push({
        name: name,
        valid: valid,
        error: error,
      });

      if (valid === false) {
        //setValidForm(false);
        validForm = false;
      }
    }
  }

  setValidErrors(jsonErrors);
  return validForm;
}
function ValidateInput(name, value, validOptions) {
  var jsonErrors = {};
  jsonErrors = {
    name: name,
    valid: true,
  };

  if (validOptions !== null) {
    const validOptionsJson = JSON.parse(validOptions);

    for (const opt in validOptionsJson) {
      const { id, val, errorText } = validOptionsJson[opt];

      if (id === 'empty') {
        if (!value || value.trim() === '') {
          var textEr = 'Поле не должно быть пустым';
          if (errorText !== undefined) {
            textEr = errorText;
          }
          jsonErrors = {
            name: name,
            valid: false,
            error: textEr,
          };
        }
      } else if (id === 'min') {
        if (value.length < val) {
          var textEr = 'Минимальная длина ' + val;
          if (errorText !== undefined) {
            textEr = errorText;
          }
          jsonErrors = {
            name: name,
            valid: false,
            error: textEr,
          };
        }
      } else if (id === 'max') {
        if (value.length > val) {
          var textEr = 'Максимальная длина ' + val;

          if (errorText !== undefined) {
            textEr = errorText;
          }
          jsonErrors = {
            name: name,
            valid: false,
            error: textEr,
          };
        }
      }
    }
  }
  return jsonErrors;
}

export { ValidateForm };
