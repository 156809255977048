import { createSlice } from '@reduxjs/toolkit'
import { useListDraftQuery } from '../../api/tracker/task';

//Сформирует список фильтров в нужном формате
function FilterGet(data) {
    var mass_1 = [];

    mass_1.push({ id: 0, name: 'Все проекты', count: data.length });

    data.map(item => {
        if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
            mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: data.filter(a => a.ID_Project === item.ID_Project).length });
        }
    });

    return mass_1;
}

const taskList = createSlice({
    name: 'taskList',
    initialState: {
        listDraftAll: [], listObserverAll: [], listExecutorAll: [], listFutureAll: [], listCompleteAll: [] //Список всех задач без фильтров
        , listDraft: [], listObserver: [], listExecutor: [], listFuture: [], listComplete: []//Список задач для вывода
        , listFilterDraft: [], listFilterObserver: [], listFilterExecutor: [], listFilterFuture: []//Список фильтров для вывода
    },
    reducers: {
        load(state, action) {
            const type = action.payload.type;

            if (type == 'draft') {
                state.listDraft = action.payload.listDraft;
                state.listDraftAll = action.payload.listDraft;
                state.listFilterDraft = FilterGet(action.payload.listDraft);
            }
            if (type == 'observer') {
                state.listObserver = action.payload.listObserver;
                state.listObserverAll = action.payload.listObserver;
                state.listFilterObserver = FilterGet(action.payload.listObserver);
            }
            if (type == 'executor') {
                state.listExecutor = action.payload.listExecutor;
                state.listExecutorAll = action.payload.listExecutor;
                state.listFilterExecutor = FilterGet(action.payload.listExecutor);
            }
            if (type == 'future') {
                state.listFuture = action.payload.listFuture;
                state.listFutureAll = action.payload.listFuture;
                state.listFilterFuture = FilterGet(action.payload.listFuture);
            }
            if (type == 'complete') {
                state.listComplete = action.payload.listComplete;
                state.listCompleteAll = action.payload.listComplete;
            }
        },
        update(state, action) {
            //Удаляем задачу из всех списков

            //Добавляем в нужный список
        },
        remove(state, action) {

        },
        filterProject(state, action) {
            const type = action.payload.type;
            const id_project = action.payload.id_project;

            if (type == 'draft') {
                if (id_project === 0) {
                    state.listDraft = state.listDraftAll;
                }
                else {
                    state.listDraft = state.listDraftAll.filter(a => a.ID_Project == id_project);
                }

            }
            if (type == 'observer') {
                if (id_project === 0) {
                    state.listObserver = state.listObserverAll;
                }
                else {
                    state.listObserver = state.listObserverAll.filter(a => a.ID_Project == id_project);
                }

            }
            if (type == 'executor') {
                if (id_project === 0) {
                    state.listExecutor = state.listExecutorAll;
                }
                else {
                    state.listExecutor = state.listExecutorAll.filter(a => a.ID_Project == id_project);
                }

            }
            if (type == 'future') {
                if (id_project === 0) {
                    state.listFuture = state.listFutureAll;
                }
                else {
                    state.listFuture = state.listFutureAll.filter(a => a.ID_Project == id_project);
                }

            }
        },
    }
})

export const { load, filterProject } = taskList.actions
export default taskList.reducer

