import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useReducer, useState } from "react";
import parse from 'html-react-parser'
import UserList from "../UserList";
import StringToDateTime from "../../../../util/StringToDateTime";
import ModalCardConfirm from "./ModalCardConfirm";
import { useParameterListMutation } from "../../../../api/tracker/taskR";
import Parameters from "../../../General/Parameters";

function ModalComplete(props) {
    const { show, onHide, model } = props;

    const [description, setDescription] = useState('');
    const [modalComplete, setModalComplete] = useState(false);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setDescription('');

            if (model.Description_TaskR !== null) {
                setDescription(model.Description_TaskR);
            }

            listParameter({ ID_TaskR: model.ID_TaskR });
        }
        //Действия при закрытии модального окна
        if (show == false) {

        }
    }, [show]);

    /*------------------------------START ПОДГРУЗКА ПАРАМЕТРОВ--------------------------*/
    const [listParameter, { data: listParameterData, status: listParameterStatus, isLoading: listParameterIsLoading }] = useParameterListMutation();

    const [parameterState, parameterDispatch] = useReducer(referenceReducer, { data: [] });
    function referenceReducer(state, action) {

        switch (action.type) {
            case 'load': {
                //Добавляем необходимые поля
                const newState = action.data.map(obj => {
                    return { ...obj, Error: false, ID_Task: model.ID };
                })
                return { data: newState }
            }
            case 'set': {
                /*
                ID	Name
                1	Логический
                2	Число
                3	Строка
                4	Справочник
                5	Дата
                */
                var val_bool = null;
                var val_float = null;
                var val_string = null;
                var val_date = null;

                if (action.id_type === 1) {
                    val_bool = action.value_bool;
                }
                if (action.id_type === 2) {
                    val_float = action.value_float;
                }
                if (action.id_type === 3) {
                    val_string = action.value_string;
                }
                if (action.id_type === 4) {
                    val_float = action.value_float;
                }
                if (action.id_type === 5) {
                    val_date = action.value_date;
                }

                if (val_string === '') {
                    val_string = null;
                }

                const newState = state.data.map(obj => {
                    if (obj.ID_Parameter === action.id_parameter) {
                        return { ...obj, Value_Bool: val_bool, Value_Float: val_float, Value_String: val_string, Value_Date: val_date };
                    }
                    return obj;
                })
                return { data: newState }

            }
        }
    }

    useEffect(() => {
        if (listParameterStatus === 'fulfilled') {
            //setLoadingParameters(false);

            if (listParameterData.Data != null) {
                parameterDispatch({ type: 'load', data: listParameterData.Data })
            }
            else {

            }
        }
    }, [listParameterStatus]);

    /*-------------------------------END ПОДГРУЗКА ПАРАМЕТРОВ---------------------------*/


    return (
        model !== null && (
            <>
                <ModalCardConfirm show={modalComplete} onHide={() => setModalComplete(false)} onHideCard={onHide} model={model} />

                <Modal show={show} onHide={onHide} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title-main">
                            <>
                                {model.Name_TaskR}
                            </>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i className="mr-5"><FontAwesomeIcon icon={faClock} /></i>{model.Timetable_String}

                        {description !== '' && (
                            <>
                                <div className="card-block-title">Описание</div>
                                <div>{parse(description)}</div>
                            </>
                        )}
                        <div className="card-block-title">Ответственные</div>
                        <b>{model.Name_Type_TaskR}:</b>
                        <div className="mt-15 mb-15">
                            <UserList list={model.Users} showDescription={true} />
                        </div>

                        {listParameterStatus !== 'fulfilled' ? (
                            <div>
                                <div className="loader_01"></div>
                            </div>
                        ) :
                            <Parameters list={parameterState.data.filter(a => a.ID_Display === 9)} dispatch={parameterDispatch} />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex-grow-1">
                            <small className="mt-15">Дата создания:  {StringToDateTime(model.Date_Add)}</small> <br></br>
                            <small className="mt-15">Владелец:  {model.Task_User_Name}</small>
                        </div>
                        <div>
                            <button style={{ width: "100%" }} className="btn btn-secondary" type="button" onClick={() => setModalComplete(true)}>
                                <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" />
                                Завершить задачу
                            </button>
                        </div>

                    </Modal.Footer>
                </Modal>
            </>
        )
    );
}
export default ModalComplete;
