import { faEye, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';


function UserList({ list, showDescription }) {

    //В списке обязательные параметры:
    //- ID_User
    //- Name_User
    //- ID_Action
    //- Completed
    //- Completed_Comment

    return (
        <>
            {list.isLoading === true ? (
                <div>
                    <div className="loader_01"></div>
                </div>
            ) : (
                list.length === 0 ?
                    (<div className='form_error'>Нет пользователей</div>)
                    :
                    (
                        list.map((u) => (
                            <div key={u.ID_User} className={u.Completed === true ? ('text-decoration-line-through') : ''}>
                                {u.ID_Action === 1 && (
                                    <FontAwesomeIcon fontSize={'20px'} icon={faEye} className="mr-10 float-start text-warning" />
                                )}
                                {u.ID_Action === 2 && (
                                    <FontAwesomeIcon fontSize={'20px'} icon={faUserCheck} className="mr-10 float-start text-success" />
                                )}
                                {u.Name_User}
                                {showDescription === true &&
                                    (
                                        u.Completed_Comment !== null && (
                                            <>
                                                <br></br>
                                                <small>
                                                    Открыть приборы учета в настройках личного кабинета. Иначе абоненты не смогут передавать показания.
                                                </small>
                                            </>
                                        )
                                    )
                                }
                            </div>
                        ))
                    )
            )
            }
        </>
    );
}

export default UserList;


