import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFileRemoveMutation as useTaskFileRemoveMutation } from "../../api/tracker/task";
import { useFileRemoveMutation as useTaskRFileRemoveMutation } from "../../api/tracker/taskR";


function FileListModalRemove(props) {
    const { show, onHide, type, model, listDispatch } = props;
    //Type: 1-Задача; 2-Регулярная задача

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setError(null);
            setLoading(false);
        }
    }, [show]);


    const [removeType1Api, { data: removeType1ApiData, status: removeType1ApiStatus }] = useTaskFileRemoveMutation();
    const [removeType2Api, { data: removeType2ApiData, status: removeType2ApiStatus }] = useTaskRFileRemoveMutation();

    useEffect(() => {
        if (removeType1ApiStatus === 'fulfilled') {
            if (removeType1ApiData.Status == 1) {
                listDispatch({ type: 'remove', id: model.ID });
                onHide();
            }
            else {
                setError(removeType1ApiData.Error);
                setLoading(false);
            }
        }
        if (removeType1ApiStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [removeType1ApiStatus]);


    useEffect(() => {
        if (removeType2ApiStatus === 'fulfilled') {
            if (removeType2ApiData.Status == 1) {
                listDispatch({ type: 'remove', id: model.ID });
                onHide();
            }
            else {
                setError(removeType1ApiData.Error);
                setLoading(false);
            }
        }
        if (removeType2ApiStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [removeType2ApiStatus]);


    function removeHandler() {
        setLoading(true);
        if (type === 1) {
            removeType1Api({ ID: model.ID });
        }
        if (type === 2) {
            removeType2Api({ ID: model.ID });
        }
    }

    return (
        <Modal show={show} onHide={onHide} size="md">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <>
                        <FontAwesomeIcon icon={faTrash} /> Удаление файла
                    </>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {model !== null && (
                    <div>Подтвердите удаление файла <b>«{model.Name}»</b> </div>
                )}
                {error != null && (
                    <div className="form_error text-center">
                        {error}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
                    <FontAwesomeIcon icon={faXmark} className="mr-5" />
                    Закрыть
                </button>
                <button
                    className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                    type="submit"
                    disabled={loading}
                    onClick={() => removeHandler()}
                >
                    {loading ? (
                        "Загрузка..."
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faTrash} className="mr-5" /> Удалить файл
                        </>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default FileListModalRemove;