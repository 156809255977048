import { faCheck, faEye, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTrackerListActiveQuery, useTrackerListArchiveQuery } from "../../../../api/tracker/taskR";
import ListActiveExecutor from "./ListActiveExecutor";
import FilterProject from "../../../General/FilterProject";

function Index() {

    //TAB START
    const [tab1Show, setTab1Show] = useState(true);
    const [tab2Show, setTab2Show] = useState(false);
    const [tab3Show, setTab3Show] = useState(false);

    const [tab1Count, setTab1Count] = useState(0);
    const [tab2Count, setTab2Count] = useState(0);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
        }
    }

    const listActiveViewerApi = useTrackerListActiveQuery({ ID_Action_User: 1 });//Наблюдатель
    const listActiveExecutorApi = useTrackerListActiveQuery({ ID_Action_User: 2 });//Исполнитель
    const listArchiveApi = useTrackerListArchiveQuery({ Skip: 0, Take: 30 });//Архив

    const [listActiveViewer, setListActiveViewer] = useState([]);
    const [listActiveExecutor, setListActiveExecutor] = useState([]);

    const [filterlistProjectViewer, setFilterlistProjectViewer] = useState([]);
    const [filterlistProjectExecutor, setFilterlistProjectExecutor] = useState([]);

    useEffect(() => {
        if (listActiveViewerApi.status === 'fulfilled') {
            if (listActiveViewerApi.data.Status == 1) {
                setTab2Count(listActiveViewerApi.data.Data.length);
                setListActiveViewer(listActiveViewerApi.data.Data);

                //ФИЛЬТР
                var mass_1 = [];
                mass_1.push({ id: 0, name: 'Все проекты', count: listActiveViewerApi.data.Data.length });
                listActiveViewerApi.data.Data.map(item => {
                    if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                        mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listActiveViewerApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                    }
                });
                setFilterlistProjectViewer(mass_1);

                //Вызываем событие применения фильтра
                if (selectedFilterViewer !== null) {
                    filterHandlerViewer(selectedFilterViewer)
                }
            }
        }
    }, [listActiveViewerApi.status]);
    useEffect(() => {
        if (listActiveExecutorApi.status === 'fulfilled') {
            if (listActiveExecutorApi.data.Status == 1) {
                setTab1Count(listActiveExecutorApi.data.Data.length);
                setListActiveExecutor(listActiveExecutorApi.data.Data);

                //ФИЛЬТР
                var mass_1 = [];
                mass_1.push({ id: 0, name: 'Все проекты', count: listActiveExecutorApi.data.Data.length });
                listActiveExecutorApi.data.Data.map(item => {
                    if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                        mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listActiveExecutorApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                    }
                });
                setFilterlistProjectExecutor(mass_1);

                //Вызываем событие применения фильтра
                if (selectedFilterExecutor !== null) {
                    filterHandlerExecutor(selectedFilterExecutor)
                }
            }
        }
    }, [listActiveExecutorApi.status]);



    /*********************************START ФИЛЬТР***************************/

    const [selectedFilterViewer, setSelectedFilterViewer] = useState(null);
    const [selectedFilterExecutor, setSelectedFilterExecutor] = useState(null);

    function filterHandlerViewer(id_project) {
        setSelectedFilterViewer(id_project);
        if (id_project === 0) {
            setListActiveViewer(listActiveViewerApi.data.Data)
        }
        else {
            setListActiveViewer(listActiveViewerApi.data.Data.filter(a => a.ID_Project == id_project))
        }
    }
    function filterHandlerExecutor(id_project) {
        setSelectedFilterExecutor(id_project);
        if (id_project === 0) {
            setListActiveExecutor(listActiveExecutorApi.data.Data)
        }
        else {
            setListActiveExecutor(listActiveExecutorApi.data.Data.filter(a => a.ID_Project == id_project))
        }
    }
    /**********************************END ФИЛЬТР****************************/

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-card mb-3 card">
                        <div className="card-header-tab card-header-tab-animation card-header">
                            <div className="card-header-title">

                            </div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a
                                        id="tab_1"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab1Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="mr-5" /> Исполнитель {tab1Count > 0 && <span className="badge badge-pill badge-success">{tab1Count}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_2"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab2Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-5" /> Наблюдатель {tab2Count > 0 && <span className="badge badge-pill badge-success">{tab2Count}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_3"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab3Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" /> Выполненные
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="mt-15 mb-15">
                                        <FilterProject list={filterlistProjectExecutor} clickHandler={filterHandlerExecutor} selected={selectedFilterExecutor} />
                                    </div>
                                    <div className="">
                                        <ListActiveExecutor list={listActiveExecutor} />
                                    </div>
                                </div>
                                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="mt-15 mb-15">
                                        <FilterProject list={filterlistProjectViewer} clickHandler={filterHandlerViewer} selected={selectedFilterViewer} />
                                    </div>
                                    <div className="">
                                        {
                                            /*<ListActiveExecutor list={listActiveViewer} />*/
                                        }

                                    </div>
                                </div>
                                <div className={tab3Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="">  
                                        {
                                            /* <ListActiveExecutor list={listArchiveApi} />*/
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
