import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFloppyDisk, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import SelectSearch from "../../../components/ui/SelectSearch";
import { useDisplayListByParameterMutation, useProjectAddMutation, useProjectDisplaySetMassMutation, useProjectListMutation } from "../../../api/tracker/parameter";



function ModalParameterProject(props) {
    const { show, onHide, id_parameter, model_project, edit, list_pproject_update } = props;

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            listProject({ ID_Parameter: id_parameter });

            setLoadingSave(false);
            setError(null);
            setIdProject(null);

            if (edit === true) {
                const jsonForm = [{
                    value: model_project.ID,
                    label: model_project.Name,
                    checked: true
                }];

                setIdProject(jsonForm);
                listProjectDisplay({ ID_Parameter: id_parameter, ID_Project: model_project.ID });
            }
            else {
                listProjectDisplay({ ID_Parameter: id_parameter });
            }
        }
    }, [show]);

    //ПЕРЕМЕННЫЕ
    const [idProject, setIdProject] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const [error, setError] = useState(null);


    /*************************START ПОДГРУЗКА СПИСКА ПРОЕКТОВ ****************************/
    const [listProjectOptions, setInputProjectOptions] = useState([]);

    const [listProject, { data: listProjectData, status: listProjectStatus }] = useProjectListMutation();
    useEffect(() => {
        if (listProjectStatus === 'fulfilled') {

            const jsonForm = [];

            for (let i = 0; i < listProjectData.Data.length; i++) {
                jsonForm.push(
                    {
                        value: listProjectData.Data[i].ID,
                        label: listProjectData.Data[i].Name,
                        checked: listProjectData.Data[i].Checked
                    })                    
            }


            setInputProjectOptions(jsonForm.filter(a => a.checked === false));
        }
    }, [listProjectStatus]);
    /*************************START ПОДГРУЗКА СПИСКА ПРОЕКТОВ ****************************/

    /*************************START ПОДГРУЗКА ОБЛАСТЕЙ ОТОБРАЖЕНИЯ ****************************/
    const [listDisplay, setListDisplay] = useState([]);
    const [listProjectDisplay, { data: listProjectDisplayData, status: listProjectDisplayStatus }] = useDisplayListByParameterMutation();

    useEffect(() => {
        if (listProjectDisplayStatus === 'fulfilled') {
            var id_pr_ = null;
            if (edit) {
                id_pr_ = model_project.ID;
            }

            const displNew = listProjectDisplayData.Data.map((item, i) => {

                if (item.Checked == true) {
                    return { ...item, ID_Parameter: id_parameter, ID_Project: id_pr_ };
                }
                else {
                    return { ...item, ID_Parameter: id_parameter, ID_Project: id_pr_, Order: 0, Width: 100, Read_Only: false };
                }                
            });

            setListDisplay(displNew);           
        }
    }, [listProjectDisplayStatus]);
    /**************************END ПОДГРУЗКА ОБЛАСТЕЙ ОТОБРАЖЕНИЯ *****************************/

    //Событие изменения Switch
    function displaySwitchChange(e, model) {
        const listDisplayNew = listDisplay.map(item => {
            if (item.ID_Display === model.ID_Display) {
                return {
                    ...item,
                    Checked: e.target.checked
                };
            } else {
                return item;
            }
        });
        setListDisplay(listDisplayNew);
    }
    //Событие изменения ширины
    function displayWidthChange(e, model) {
        const listDisplayNew = listDisplay.map(item => {
            if (item.ID_Display === model.ID_Display) {
                return {
                    ...item,
                    Width: e.target.value
                };
            } else {
                return item;
            }
        });
        setListDisplay(listDisplayNew);
    }
    //Событие выбора проекта
    function projectChange(e) {
        setIdProject(e);

        const displNew = listDisplay.map((item, i) => {
            return { ...item, ID_Project: e.value };
        });
        setListDisplay(displNew);
    }


    /***************************START ОТПРАВКА ФОРМЫ**************************/
    const [projectAdd, { data: projectAddData, status: projectAddStatus }] = useProjectAddMutation();
    const [projectDisplaySetMass, { data: projectDisplaySetMassData, status: projectDisplaySetMassStatus }] = useProjectDisplaySetMassMutation();

    function handlerSave() {
        setLoadingSave(true);

        if (idProject === null) {
            setError('Выберите проект!');
            setLoadingSave(false);
        }
        else {
            if (edit === false) {
                projectAdd({ ID_Parameter: id_parameter, ID_Project: idProject.value })
            }
            else {
                projectDisplaySetMass(listDisplay)
            }
        }
    }
    //Создание проекта
    useEffect(() => {
        if (projectAddStatus === 'fulfilled') {
            if (projectAddData.Status == 1) {
                //////////
                projectDisplaySetMass(listDisplay)
            }
            else {
                setError(projectAddData.Error)
            }
            setLoadingSave(false);
        }
        if (projectAddStatus === 'rejected') {

            setError('Неизвестная ошибка!');
            setLoadingSave(false);
        }
    }, [projectAddStatus]);

    useEffect(() => {
        if (projectDisplaySetMassStatus === 'fulfilled') {
            if (projectDisplaySetMassData.Status == 1) {
                onHide()
                list_pproject_update({ ID_Parameter: id_parameter }) 
            }
            else {
                setError(projectAddData.Error)
            }
            setLoadingSave(false);
        }
        if (projectDisplaySetMassStatus === 'rejected') {

            setError('Неизвестная ошибка!');
            setLoadingSave(false);
        }
    }, [projectDisplaySetMassStatus]);
    /***************************START ОТПРАВКА ФОРМЫ**************************/
    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {edit === true ?
                            (<div><i className="mr-5"><FontAwesomeIcon icon={faEdit} /></i> Редактирование проекта</div>)
                            :
                            (<div><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i> Добавление проекта</div>)}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" >
                        <Form.Label>Проект</Form.Label>
                        <SelectSearch disabled={edit} name="ID_Project" placeholder='Выберите проект' className="z-index-max" options={listProjectOptions} onChange={(e) => projectChange(e)} defaultValue={idProject} />
                    </Form.Group>
                    <div className="mt-30 mb-15">
                        <div className="card-block-title mb-15">Области отображения</div>
                        {listProjectDisplayStatus === 'fulfilled' && (
                            listDisplay.map((model) => (
                                <div key={model.ID_Display} className="d-flex align-items-center row_select">
                                    <div className="p-2 flex-grow-1">
                                        <label>{model.Name}</label>
                                    </div>
                                    <div className="flex-grow-2 mr-30">
                                        <div className="select-v1">
                                            <select-v1-label>Ширина:</select-v1-label>
                                            <select key={Math.random()} defaultValue={model.Width} onChange={(e) => displayWidthChange(e, model)} disabled={model.Checked === false && true} >
                                                <option value="100">100%</option>
                                                <option value="75">75%</option>
                                                <option value="50">50%</option>
                                                <option value="25">25%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="form-check form-switch p-0" >
                                            <div className="d-inline-flex flex-row-reverse gap-1 mt-5">
                                                <input key={Math.random()} id_item={model.ID_Display} defaultChecked={model.Checked} className="form-check-input ms-0" type="checkbox" role="switch" onChange={(e) => displaySwitchChange(e, model)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                        onClick={() => handlerSave()}
                        disabled={loadingSave}
                    >
                        {loadingSave ? (
                            "Загрузка..."
                        ) : (
                            <>
                                {
                                    edit === true ?
                                        (<div><FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить</div>)
                                        :
                                        (<div><FontAwesomeIcon icon={faPlus} className="mr-5" /> Добавить</div>)
                                    }
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalParameterProject;
