import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { ValidateForm } from '../../../util/ValidateForm';
import { useEffect, useState } from 'react';
import FormToJson from '../../../util/FormToJson';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useStatusListQuery } from '../../../api/main/company';

function CompanyModalEdit(props) {
  const { show, onHide, updateCompany, updateStatus } = props;
  const { Description, ID, ID_Status, Name } = props.model;

  useEffect(() => {
    if (show === true) {
      setValidErrors([]);
    }
  }, [show]);

  const [validErrors, setValidErrors] = useState([]);

  const { status: statusStatusList, data: statusData } = useStatusListQuery();

  const submit = (e) => {
    e.preventDefault();

    var validForm = ValidateForm(e.target, setValidErrors);

    if (validForm) {
      const form = FormToJson(e.target);
      form['ID_Company'] = ID;
      updateCompany(form);
    }
  };

  useEffect(() => {
    if (updateStatus === 'fulfilled') {
      onHide();
    }
  }, [updateStatus]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <>
              <FontAwesomeIcon icon={faEdit} /> Редактирование компании
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="my-form" onSubmit={submit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Название компании</Form.Label>
              <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' disabled={updateStatus === 'pending' ? true : false} defaultValue={Name} />
              {validErrors.find((el) => el.name === 'Name') !== undefined && <div className="form_error">{validErrors.find((el) => el.name === 'Name').error}</div>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Описание компании</Form.Label>
              <Form.Control as="textarea" rows={3} name="Description" disabled={updateStatus === 'pending' ? true : false} defaultValue={Description} />
              {validErrors.find((el) => el.name === 'Description') !== undefined && validErrors.find((el) => el.name === 'Description').error}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Статус компании</Form.Label>
              <Form.Select aria-label="Default select example" defaultValue={ID_Status} name="ID_Status">
                {statusStatusList === 'fulfilled' &&
                  statusData.Data.map((model) => (
                    <option
                      key={model.ID}
                      value={model.ID}
                    >
                      {model.Name}
                    </option>
                  ))}
              </Form.Select>
              {validErrors.find((el) => el.name === 'ID_Status') !== undefined && validErrors.find((el) => el.name === 'ID_Status').error}
            </Form.Group>
          </Form>
          {updateStatus == 'rejected' && <div className="form_error">Неизвестная ошибка! Обратитесь в техническую поддержку</div>}
        </Modal.Body>
        <Modal.Footer>
          <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
            <FontAwesomeIcon icon={faXmark} className="mr-5" />
            Закрыть
          </button>
          <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" form="my-form" type="submit" disabled={updateStatus === 'pending' ? true : false}>
            {updateStatus === 'pending' ? (
              <>
                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Загрузка...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить изменения
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CompanyModalEdit;
