import { useGetListAllQuery, useSelectedIdGetQuery, useSelectMutation } from "../../../api/main/company";
import { useEffect, useState } from "react";
import Select from "../../../components/ui/Select";

function HeaderCompany(){
    //API
    const companyAllList = useGetListAllQuery();
    const companySelected = useSelectedIdGetQuery();
    //const [companySelect] = useSelectMutation();
    const [set, { data: setData, status: setStatus }] = useSelectMutation();



    const [compnaySelectedId, setCompnaySelectedId] = useState(0);
    const [compnayList, setCompnayList] = useState({});

    useEffect(() => {   
        if(companySelected.status === 'fulfilled' & companyAllList.status === 'fulfilled'){             
            setCompnaySelectedId(companySelected.data.Data);
            setCompnayList(companyAllList.data.Data);
        }
      }, [companySelected.status, companyAllList.status]);

    useEffect(() => {
        if (setStatus === 'fulfilled') {
            window.location.reload();

        }
    }, [companySelected.status, companyAllList.status]);


    const companyChange = event => {
        set({ ID_Company: Number(event.target.value) })
    };

    return  (
        <>
        {compnayList.length > 0 &&            
                <Select 
                list={compnayList }
                defaultValue={compnaySelectedId}
                change = {companyChange}  
                />                   
    }    
        </>
        )
}

export default HeaderCompany;