import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserListQuery } from "../../../../api/tracker/task";
import { faEye, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import StringToDateTime from "../../../../util/StringToDateTime";

function List({ id_task }) {

    const userListApi = useUserListQuery({ ID_Task: id_task });

    return (
        <>
            <div className="div">
                <div className="scrollbar-container ps ps--active-y">
                    <div className="p-2">
                        {userListApi.status === 'fulfilled' && userListApi.data.Data.map((model) => (
                            <div key={model.ID_User} className="row row_select task_user_row">
                                <div className="col-md-12">
                                    {model.ID_Action === 1 && (
                                        <FontAwesomeIcon fontSize={'20px'} icon={faEye} className="mr-10 float-start text-warning" />
                                    )}
                                    {model.ID_Action === 2 && (
                                        <FontAwesomeIcon fontSize={'20px'} icon={faUserCheck} className="mr-10 float-start text-success" />
                                    )}

                                    {model.Complete === true ? (<del>{model.Name_User}</del>) : (<div>{model.Name_User}</div>)}
                                    {model.Complete === true && (
                                        <>
                                            <div className="task_user_comment">{model.Complete_Comment}</div>
                                            <div className="task_user_date">{StringToDateTime(model.Complete_Date)}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default List;
