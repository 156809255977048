import "bootstrap/dist/css/bootstrap.min.css";
import "./Fonts/Inter/stylesheet.css";
import "./App.css";
import { CompanyHubReceiveAddRequest } from "../../SignalR/CompanyHub";

function App() {
  //SignalR Callback
  CompanyHubReceiveAddRequest();

  return <div className="App">{}</div>;
}

export default App;
