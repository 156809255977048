import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faSave, faSpinner, faUserCheck, faUsers, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useUserAvailableListQuery, useUserSetMassMutation } from "../../../../api/tracker/taskR";
import { useEffect, useState } from "react";
import StringToDateTime from "../../../../util/StringToDateTime";
import { Form } from "react-bootstrap";
import StringToInputDate from "../../../../util/StringToInputDate";
import ParametersSelect from "./ParametersSelect";

function ModalTaskChangeProject(props) {
    const { show, onHide, id_task, id_project, modelProjectSetPrepareUser, modelProjectSetPrepareParameter,
        projectSet, projectSetData, projectSetStatus } = props;



    const [formLog, setFormLog] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);

    const [loadingOpen, setLoadingOpen] = useState(true);

    useEffect(() => {
        //Действия при открытии модального окна
        if (show == true) {
            setFormLog(null);
        }
        //Действия при закрытии модального окна
        if (show == false) {
            setFormLog(null);
        }
    }, [show]);

    //Сохранение изменений
    function confirm(e) {
        setBtnLoading(true);
        projectSet({ ID_Task: id_task, ID_Project: id_project });
        //console.log(id_task)
        //console.log(id_project)
        //setBtnLoading(true);
        //Отправляем на сервер только измененные строки
        //setMass(list.filter(a => a.change == true));
    }

    useEffect(() => {
        if (projectSetStatus === 'fulfilled') {
            if (projectSetData.Status == 1) {
                onHide();
            }
            else {
                setFormLog(projectSetData.Error);
            }
            setBtnLoading(false);
        }
        if (projectSetStatus === 'rejected') {
            setFormLog('Неизвестная ошибка!');
            setBtnLoading(false);
        }
    }, [projectSetStatus]);

    return (
        <>

            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faCheck} /></i> Подтвердите изменение проекта
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mb-15">
                        <div className="badge badge-danger">Внимание!</div>
                        <div className="mt-10"><b>После изменения проекта данные указанные ниже будут удалены из задачи:</b></div>
                    </div>
                    <div><u>Пользователи:</u></div>

                    {modelProjectSetPrepareUser.length > 0 &&
                        modelProjectSetPrepareUser.map((model) => (
                            <div key={model.ID_User} className="row row_select task_user_row">
                                <div className="col-md-12">
                                    {model.ID_Action === 1 && (
                                        <FontAwesomeIcon fontSize={'20px'} icon={faEye} className="mr-10 float-start text-warning" />
                                    )}
                                    {model.ID_Action === 2 && (
                                        <FontAwesomeIcon fontSize={'20px'} icon={faUserCheck} className="mr-10 float-start text-success" />
                                    )}

                                    {model.Complete === true ? (<del>{model.Name_User}</del>) : (<div>{model.Name_User}</div>)}
                                    {model.Complete === true && (
                                        <>
                                            <div className="task_user_comment">{model.Complete_Comment}</div>
                                            <div className="task_user_date">{StringToDateTime(model.Complete_Date)}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    <div className="mt-15"><u>Параметры:</u></div>
                    <div className="row">
                        {modelProjectSetPrepareParameter.length > 0 &&

                            modelProjectSetPrepareParameter.map((model) => (
                                <div key={model.ID_User} className="col-md-4">
                                    {model.ID_Type === 1 && (
                                        <Form.Check type="switch" label={model.Name_Parameter} defaultChecked={model.Value_Bool} />
                                    )}
                                    {model.ID_Type === 2 && (
                                        <Form.Group className="mb-3" key={model.ID}>
                                            <Form.Label>{model.Name_Parameter}</Form.Label>
                                            <Form.Control name="Name" type="number" defaultValue={model.Value_Float} />
                                        </Form.Group>
                                    )}
                                    {model.ID_Type === 3 && (
                                        <Form.Group className="mb-3" key={model.ID}>
                                            <Form.Label>{model.Name_Parameter}</Form.Label>
                                            <Form.Control name="Name" type="text" defaultValue={model.Value_String} />
                                        </Form.Group>
                                    )}
                                    {model.ID_Type === 4 && (
                                        <ParametersSelect model={model} />
                                    )}
                                    {model.ID_Type === 5 && (
                                        <Form.Group className="mb-3" key={model.ID}>
                                            <Form.Label>{model.Name_Parameter}</Form.Label>
                                            <Form.Control name="Name" type="date" defaultValue={StringToInputDate(model.Value_Date)} />
                                        </Form.Group>
                                    )}
                                </div>
                            ))}
                    </div>
                    {formLog !== null && <div className="form_error text-center mt-15">{formLog}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        onClick={onHide}
                    >
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-danger"
                        disabled={btnLoading}
                        onClick={() => confirm()}
                    >
                        {btnLoading ? (
                            "Изменение..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faCheck} className="mr-5" /> Подтвердить
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalTaskChangeProject;
