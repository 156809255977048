import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//https://habr.com/ru/articles/730916/
export const companyApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api_main/main/company/' }),
  tagTypes: ['company', 'companyExternal', 'userRequestOut', 'companyChange', 'userRequest', 'userRequestAdmin', 'userList'],
  reducerPath: 'Company',
  endpoints: (builder) => ({
    //Компании
    getListCompany: builder.query({
      query: (args) => {
        return {
          url: 'getList',
          method: 'GET',
          params: args,
        };
      },
      providesTags: ['company'],
    }),
    getCompany: builder.query({
      query: () => 'get',
    }),
    getListAll: builder.query({
      query: (args) => {
        return {
          url: 'getListAll',
          method: 'GET'
        };
      },
      providesTags: ['company'],

    }),
    addCompany: builder.mutation({
      query: (args) => {
        return {
          url: 'add',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['company'],
    }),
    removeCompany: builder.mutation({
      query: (args) => {
        return {
          url: 'remove',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['company'],
    }),
    updateCompany: builder.mutation({
      query: (args) => {
        return {
          url: 'update',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['company'],
    }),
    statusList: builder.query({
      query: (args) => {
        return {
          url: 'statusList',
          method: 'GET',
          params: args,
        };
      },
    }),
    selectedIdGet: builder.query({
      query: (args) => {
        return {
          url: 'selectedIdGet',
          method: 'GET'
        };
      },
      providesTags: ['company'],
    }),
    select: builder.mutation({
      query: (args) => {
        return {
          url: 'select',
          method: 'POST',
          body: args
        };
      },
      invalidatesTags: ['company', 'companyChange'],
    }),
    //Внешние компании
    externalGetList: builder.query({
      query: (args) => {
        return {
          url: 'externalGetList',
          method: 'GET'
        };
      },
      providesTags: ['companyExternal'],

    }),
    externalRemove: builder.mutation({
      query: (args) => {
        return {
          url: 'externalRemove',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['companyExternal'],
    }),
    //Работа с заявками (Админ)
    userRequestAdd: builder.mutation({
      query: (args) => {
        return {
          url: 'userRequestAdd',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['userRequestAdmin'],
    }),
    userRequestRemove: builder.mutation({
      query: (args) => {
        return {
          url: 'userRequestRemove',
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: ['userRequestAdmin'],
    }),
    userRequestOutList: builder.query({
      query: (args) => {
        return {
          url: 'userRequestOutList',
          method: 'GET',
          params: args
        };
      },
      providesTags: ['companyChange', 'userRequestAdmin'],
    }),
    //Работа с заявками (Пользователь)
    userRequestInList: builder.query({
      query: (args) => {
        return {
          url: 'userRequestInList',
          method: 'GET',
          params: args
        };
      },
      providesTags: ['userRequest'],
    }),
    userRequestAccept: builder.mutation({
      query: (args) => {
        return {
          url: 'userRequestAccept',
          method: 'POST',
          body: args
        };
      },
      invalidatesTags: ['userRequest', 'companyExternal'],
    }),
    userRequestCancel: builder.mutation({
      query: (args) => {
        return {
          url: 'userRequestCancel',
          method: 'POST',
          body: args
        };
      },
      invalidatesTags: ['userRequest'],
    }),
    userRequestViewed: builder.mutation({
      query: (args) => {
        return {
          url: 'userRequestViewed',
          method: 'POST',
          body: args
        };
      },
    }),
    //Пользователи
    userList: builder.query({
      query: (args) => {
        return {
          url: 'userList',
          method: 'GET',
          params: args
        };
      },
      providesTags: ['companyChange', 'userList'],
    }),
    userUpdate: builder.mutation({
      query: (args) => {
        return {
          url: 'userUpdate',
          method: 'POST',
          body: args
        };
      },
      invalidatesTags: ['userList'],
    }),
    companyUserParameterSet: builder.query({
      query: (args) => {
        return {
          url: 'userStatusList',
          method: 'GET'
        };
      },
      providesTags: ['companyChange'],
    }),
    userStatusList: builder.query({
      query: (args) => {
        return {
          url: 'userStatusList',
          method: 'GET'
        };
      },
      //providesTags: ['companyChange'],
    }),

  }),
});

export const { useGetListCompanyQuery, useGetListAllQuery, useAddCompanyMutation, useGetCompanyQuery, useRemoveCompanyMutation, useUpdateCompanyMutation, useStatusListQuery
  , useSelectMutation, useSelectedIdGetQuery
  , useUserRequestAddMutation, useUserRequestInListQuery, useUserRequestOutListQuery, useUserRequestRemoveMutation
  , useUserRequestAcceptMutation, useUserRequestCancelMutation, useUserRequestViewedMutation
  , useExternalGetListQuery, useExternalRemoveMutation
  , useUserListQuery, useUserUpdateMutation, useUserStatusListQuery
} = companyApi;
