import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTradeFederation } from "@fortawesome/free-brands-svg-icons";

function ModalNoProject({ show, onHide }) {
    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-10 text-warning"><FontAwesomeIcon icon={faTriangleExclamation} /></i>Ошибка
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-30 mt-30 fs-5 text-center">
                        Для начала работы выберите проект.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        onClick={onHide}
                    >
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalNoProject;
