import Select from 'react-select'
//Select с поиском
function SelectSearch({ options, defaultValue, name, className, placeholder, disabled, onChange }) {
    /*
    ФОРМАТ options:
    {value: '', label: '', checked: ''}

    ЗНАЧЕНИЕ defaultValue:
    указывается полностью модель: {value: '',    label: '',    checked: ''}
    */
    return (
        <Select key={Math.random()} name={name} options={options} placeholder={placeholder} defaultValue={defaultValue} className={className} isDisabled={disabled} onChange={onChange} />
    )
}
export default SelectSearch;