import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useExternalRemoveMutation } from "../../../api/main/company";

function ExternalCompanyModalRemove(props) {
  const { show, onHide, model } = props;

  const [reqErrors, setReqErrors] = useState('');

  //API
  const [remove, { isLoading: removeIsLoading, data: removeData, status: removeStatus }] = useExternalRemoveMutation();


  //Обработка запроса API
  useEffect(() => {
    if (removeStatus === 'fulfilled') {
      if (removeData.Status == 1) {
        onHide();
      }
      else {
        setReqErrors(removeData.Error)
      }
    }
  }, [removeStatus]);

  //Очистка форм
  useEffect(() => {
    if (show === false) {
      setReqErrors('')
    }
  }, [show]);


  //Отправка формы
  const submit = (e) => {
    e.preventDefault();
    setReqErrors('');
    remove({ ID: model.ID });
  };


  return (
    <>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <i className="mr-10">
              <FontAwesomeIcon icon={faTrash} />
            </i>
            Удаление внешней коммпании
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="my-form" onSubmit={submit}>
            Подтвердите удаление заявки пользователя <b>«{model.Name}»</b>
          </Form>
          {reqErrors !== '' && (
            <div className="form_error text-center">
              {reqErrors}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="mr-5 btn-square btn-hover-shine btn btn-secondary"
            onClick={onHide}
          >
            <FontAwesomeIcon icon={faXmark} className="mr-5" />
            Закрыть
          </button>
          <button
            className="mr-5 btn-square btn-hover-shine btn btn-secondary"
            form="my-form"
            type="submit"
            disabled={removeIsLoading}
          >
            {removeIsLoading ? (
              <>
                <FontAwesomeIcon icon={faTrash} className="mr-5" />Удаление…
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faTrash} className="mr-5" />Удалить компанию
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ExternalCompanyModalRemove;
