import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import RequestModalAdd from "./RequestModalAdd";
import { useUserListQuery, useUserRequestOutListQuery } from '../../../api/main/company';
import RequestList from "./RequestList";
import UserList from "./UserList";

function Index() {
  //API
  const requestOutList = useUserRequestOutListQuery({ id_status: 1 });
  const userListActive = useUserListQuery({ id_status: 1 });
  const userListArchive = useUserListQuery({ id_status: 2 });

  //Модальные окна
  const [modalRequestAddShow, setModalRequestAddShow] = useState(false);

  //Счетчик заявок
  const [tabRequestCount, setTabRequestCount] = useState(0);

  useEffect(() => {
    if (requestOutList.status === 'fulfilled') {
      if (requestOutList.data.Status == 1) {
        setTabRequestCount(requestOutList.data.Data.length)
      }
    }
  }, [requestOutList.status]);


  //TAB
  const [tabActiveShow, setTabActiveShow] = useState(true);
  const [tabRequestShow, setTabRequestShow] = useState(false);
  const [tabArchiveShow, setTabArchiveShow] = useState(false);

  function tabCompanyHandleClick(e) {
    if (e.currentTarget.id === "tab_active") {
      setTabActiveShow(true);
      setTabRequestShow(false);
      setTabArchiveShow(false);
    }
    if (e.currentTarget.id === "tab_request") {
      setTabActiveShow(false);
      setTabRequestShow(true);
      setTabArchiveShow(false);
    }
    if (e.currentTarget.id === "tab_archive") {
      setTabActiveShow(false);
      setTabRequestShow(false);
      setTabArchiveShow(true);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3 card">
            <div className="no-gutters row">
              <div className="col-md-12 col-lg-4">
                <ul className="list-group list-group-flush">
                  <li className="bg-transparent list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">
                              АКТИВНЫЕ ПОЛЬЗОВАТЕЛИ
                            </div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers text-success">12</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-12 col-lg-4">
                <ul className="list-group list-group-flush">
                  <li className="bg-transparent list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">ДОСТУПНО ПО ТАРИФУ</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers text-success">9999</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-12 col-lg-4">
                <ul className="list-group list-group-flush">
                  <li className="bg-transparent list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">ОСТАЛОСЬ</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers text-success">9987</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header-tab card-header-tab-animation card-header">
              <div className="card-header-title">
                <button className="btn-square btn-hover-shine btn btn-secondary" onClick={() => setModalRequestAddShow(true)}>
                  <i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>
                  Добавить пользователя
                </button>
                <RequestModalAdd
                  show={modalRequestAddShow}
                  onHide={() => setModalRequestAddShow(false)}
                />
              </div>
              <ul className="nav">
                <li className="nav-item">
                  <a
                    id="tab_active"
                    onClick={tabCompanyHandleClick}
                    data-toggle="tab"
                    className={tabActiveShow ? "nav-link active show" : "nav-link show"}
                  >
                    <i className="fas fa-star"></i> Активные
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="tab_request"
                    onClick={tabCompanyHandleClick}
                    data-toggle="tab"
                    className={tabRequestShow ? "nav-link active show" : "nav-link show"}
                  >
                    <i className="fas fa-archive"></i> Заявки {tabRequestCount > 0 && <span className="badge badge-success">{tabRequestCount}</span>}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="tab_archive"
                    onClick={tabCompanyHandleClick}
                    data-toggle="tab"
                    className={tabArchiveShow ? "nav-link active show" : "nav-link show"}
                  >
                    <i className="fas fa-archive"></i> Архивные
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div className={tabActiveShow ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    <UserList data={userListActive} />
                  </div>
                </div>
                <div className={tabRequestShow ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    <RequestList data={requestOutList} />
                  </div>
                </div>
                <div className={tabArchiveShow ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    <UserList data={userListArchive} />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
