import { createSlice } from '@reduxjs/toolkit'
import * as signalR from '@microsoft/signalr';

const UserHub = createSlice({
  name: 'UserHub',
  initialState: {
    value: 0
  },
  reducers: {
    connect: (state = signalR.HubConnection) => {

       /* const hubConnection = new signalR.HubConnectionBuilder().withUrl('/api_main/ws/user/').build();
        hubConnection
        .start()
        .then(function () {
            console.log('ws_connect: ok')
            state.value = hubConnection
            
        })
        .catch(function (err) {
            console.log('ws_connect: error')
            return console.error(err.toString());
        });     */   
       
      },
    sentTest: state => {

       /* const message = 'sent'
        state.invoke("SendMessage",  message).catch(function (err) {
            console.log(message);
          
            return console.error(err.toString());
        });*/
        /*const message = 'sent'
        hubConnection.invoke("SendMessage",  message).catch(function (err) {
            console.log(message);
          
            return console.error(err.toString());
        }); */                    
        //state.value = 'message'        
    },
   
    readTest: state => {

        /*const message_ = 'response'
        hubConnection.on("ReceiveMessage", function (message) {    
            console.log(message); 
            message_ =  message;        
            state.value = message_;
        return state;
        });*/

        
    },
  }
})

export const { connect,sentTest,readTest } = UserHub.actions
export default UserHub
